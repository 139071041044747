<script setup>
import { onMounted, ref } from 'vue'
import { NList, NSpin, NEmpty, NPagination } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { useVideosStore } from '../store/videosStore'
import VideosListItem from './VideosListItem'
import VideosViewModal from './VideosViewModal'
import VideosSkeletons from './VideosSkeletons'
import VideosFilters from './VideosFilters'
import ErrorResult from '@/components/common/ErrorResult'

const VideosStore = useVideosStore()
const { isLoading, isFetching, isError, items, pagination } = storeToRefs(VideosStore)

const refModalVideo = ref(null)
const filters = ref({
  q: undefined,
  category_id: undefined
})

const clickVideoCard = (video) => {
  refModalVideo.value?.open(video)
}

const clickTag = (tag) => {
  filters.value.category_id = tag.id
}

const changeFilters = (newFilters) => {
  filters.value = newFilters
  VideosStore.getAll({ page: 1, ...newFilters })
}

const changePagination = (page) => {
  VideosStore.getAll({ page, ...filters.value })
}

onMounted(() => {
  VideosStore.getAll()
})
</script>

<template>
  <VideosFilters v-model="filters" class="mb-3" @change="changeFilters" />
  <VideosSkeletons v-if="isLoading" />
  <NSpin v-else-if="!isError" :show="isFetching">
    <NList v-if="items.length" hoverable clickable>
      <VideosListItem
        v-for="item in items"
        :key="item.id"
        :video="item"
        @click="clickVideoCard(item)"
        @click-tag="clickTag"
      />
    </NList>
    <NEmpty v-else />
  </NSpin>
  <ErrorResult v-else :repeat="VideosStore.getAll" />
  <NPagination
    v-if="items.length"
    class="mt-3"
    :page="pagination.current"
    :page-count="pagination.totalPages"
    @update:page="changePagination"
  />
  <VideosViewModal ref="refModalVideo" />
</template>
