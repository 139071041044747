<template>
  <div class="content-block">
    <div class="content-list">
      <actionsList v-if="list !== false" :list="list" :sort="sort" @changeSort="changeSort" />
    </div>
  </div>
</template>

<script>
import actionsList from '@/components/widgets/custom-components/lists/actionsList'

export default {
  name: 'ActionsTab',
  components: { actionsList },
  props: {
    requirement: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      list: false,
      sort: {
        type: 'status',
        direction: 'desc'
      }
    }
  },
  mounted() {
    this.updateInformation()
  },
  methods: {
    async updateInformation() {
      let result = await this.$api.get(
        '/requirements/get-actions/?organization_requirement_id=' +
          this.requirement.id +
          '&sort_type=' +
          this.sort.type +
          '&sort_direction=' +
          this.sort.direction
      )
      if (result.data.ok === 1) {
        this.list = result.data.data
      }
    },
    changeSort(sort) {
      this.sort = sort
      this.updateInformation()
    }
  }
}
</script>
