<template>
  <div v-if="process" class="page">
    <div class="page-header">
      <div class="page-header__left">
        <div class="page-header__text">
          <div class="page-header__heading">Процесс №{{ process.number }}</div>
        </div>
        <div class="page-header__subheading">{{ process.text }}</div>
      </div>
    </div>
    <div class="process-info d-flex">
      <OrganizationInformationItem :text="process.medtype" header="Направление" icon="white-plus-icon" class="mr-4" />
      <OrganizationInformationItem
        class="w-88"
        :text="responsibleUser?.name || '–'"
        header="Ответственный"
        icon="white-avatar-icon"
      />
    </div>
    <div class="content">
      <div class="tabs">
        <template v-for="{ key, title, hide } in tabs">
          <div v-if="!hide" :key="key" class="tab" :class="{ active: currentTab === key }" @click="currentTab = key">
            {{ title }}
          </div>
        </template>
      </div>
      <div class="tab-content">
        <VkkTab v-if="currentTab === 'vkk'" :process="process" />
        <DocumentsTab v-if="currentTab === 'documents'" :process="process" />
        <SettingsTab v-if="currentTab === 'settings' && currentUser.vkk" :process="process" />
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import VkkTab from '@/components/pages/ProcessPage/processTabs/vkkTab'
import DocumentsTab from '@/components/pages/ProcessPage/processTabs/documentsTab'
import SettingsTab from '@/components/pages/ProcessPage/processTabs/settingsTab'
import api from '@/api'
import { mapGetters } from 'vuex'

export default {
  name: 'ProcessPage',
  components: { OrganizationInformationItem, VkkTab, DocumentsTab, SettingsTab },
  data() {
    return {
      process: false,
      currentTab: 'vkk',
      responsibleUser: null
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    tabs() {
      return [
        { key: 'vkk', title: 'Требования' },
        { key: 'documents', title: 'Документы' },
        { key: 'settings', title: 'Настройки', hide: !this.currentUser?.vkk }
      ]
    }
  },
  mounted() {
    this.fetch()
    this.getResponsibleUsers()
  },
  methods: {
    async fetch() {
      const result = await this.$api.get('/process/get', {
        params: {
          id: this.$router.currentRoute.value.params.id
        }
      })
      if (result.data.ok === 1) {
        this.process = result.data.data
      }
    },
    async getResponsibleUsers() {
      const res = await api.get('responsible/get')

      if (res.data.ok === 1) {
        this.responsibleUsers = res.data.data
        this.responsibleUser = res.data.data.find((item) => item.id === this.process.responsible_id)
      }
    },
    setResponsible(user) {
      this.responsibleUser = user
    }
  }
}
</script>

<style scoped>
.page-header {
  padding-bottom: 15px;
}

.page-subheader {
  padding-bottom: 30px;
}

.process-info {
  padding-bottom: 35px;
}
</style>
