import { h } from 'vue'
import { NIcon, NIconWrapper } from 'naive-ui'

export function renderIcon(icon, props) {
  if (props?.wrapperProps) {
    return h(NIconWrapper, props?.wrapperProps, {
      default: () => h(NIcon, props?.iconProps || null, { default: () => h(icon) })
    })
  }

  return h(NIcon, props?.iconProps || null, { default: () => h(icon) })
}
