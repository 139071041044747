import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useMessage, useNotification } from 'naive-ui'
import api from '@/api'
import { USER_ROLES } from '@/constants'

export const useUsersStore = defineStore('users', () => {
  const endpoint = 'users/'

  const message = useMessage()
  const notify = useNotification()

  const isLoading = ref(false)
  const isDeleting = ref(false)
  const isUploading = ref(false)
  const isError = ref(false)
  const error = ref(null)
  const currentUser = ref(null)
  const items = ref([])

  const isSuperAdmin = computed(() => currentUser?.value?.role_id === USER_ROLES.SUPER_ADMIN)
  const isAdmin = computed(
    () => currentUser?.value?.role_id === USER_ROLES.ADMIN || currentUser?.value?.role_id === USER_ROLES.SUPER_ADMIN
  )
  const currentUserId = computed(() => currentUser?.value?.id)

  const getCurrentUser = async () => {
    try {
      const res = await api.get(endpoint + 'get')

      if (res.data.ok === 1) {
        currentUser.value = res.data.data
        return res
      }

      throw new Error(res.data.message)
    } catch (e) {
      isError.value = true
      error.value = e?.response?.data?.message || e.message
      throw e
    }
  }

  const getResponsibleUsers = async () => {
    try {
      isLoading.value = true
      isError.value = false
      error.value = null

      const res = await api.get('responsible/get')

      if (res.data.ok === 1) {
        items.value = res.data.data
        return res
      }

      throw new Error(res.data.message)
    } catch (e) {
      isError.value = true
      error.value = e?.response?.data?.message || e.message
      throw e
    } finally {
      isLoading.value = false
    }
  }

  const deleteById = async (id) => {
    try {
      isDeleting.value = true

      const res = await api.post(endpoint + 'delete-user', { id })

      items.value = items.value.filter((item) => item.id !== id)

      message.success('Пользователь удален')

      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось удалить пользователя',
        duration: 25000
      })
    } finally {
      isDeleting.value = false
    }
  }

  return {
    isLoading,
    isDeleting,
    isUploading,
    isError,
    error,
    items,
    isSuperAdmin,
    isAdmin,
    currentUserId,
    currentUser,
    getCurrentUser,
    getResponsibleUsers,
    deleteById
  }
})
