import { h } from 'vue'
import icons from './icons'

export default {
  name: 'NextIcon',
  props: {
    size: {
      type: [Number, String],
      default: '1.25em'
    },
    icon: String
  },
  methods: {
    getSize() {
      if (!isNaN(Number(this.size))) return this.size + 'px'

      const size = {
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '20px',
        '2xl': '22px',
        '3xl': '24px'
      }[this.size]

      return size || this.size
    }
  },
  render() {
    const iconContent = icons[this.icon]

    if (!iconContent) return ''

    const svgData = {
      class: 'next-icon',
      xmlns: 'http://www.w3.org/2000/svg',
      viewBox: '0 0 24 24',
      fill: 'none',
      style: {
        width: this.getSize(),
        height: this.getSize()
      }
    }

    const pathData = {
      fill: 'currentColor',
      d: iconContent
    }

    return h('svg', svgData, [h('path', pathData)])
  }
}
