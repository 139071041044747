import api from '@/api'

const endpoint = 'requirements/'

const requirement = {
  state: {
    requirements: [],
    requirementDocuments: [],
    allStatuses: [
      { text: 'Не проверено', id: 1 },
      { text: 'Соответствует', id: 2 },
      { text: 'Не соответствует', id: 3 },
      { text: 'Неприменимо', id: 4 }
    ]
  },
  getters: {
    allStatuses(state) {
      return state.allStatuses
    },
    requirements(state) {
      return state.requirements
    },
    requirementDocuments(state) {
      return state.requirementDocuments
    }
  },
  mutations: {
    requirements_update_requirements(state, payload) {
      state.requirements = payload
    },
    requirements_set_status(state, payload) {
      payload.item.status_id = payload.status_id
      payload.item.status = state.allStatuses[payload.status_id - 1].text
    },
    requirement_set_commentary(state, payload) {
      payload.item.commentary = payload.commentary
    },
    dontmatch_change(state, payload) {
      state.requirements = payload
    },
    requirement_refresh_documents(state, payload) {
      state.requirementDocuments = payload
    }
  },
  actions: {
    REQUIREMENT_DOCUMENTS_GET_ALL(context, payload) {
      return api.get(endpoint + 'get-documents', {
        params: {
          id: payload.id,
          limit: payload.limit,
          offset: payload.offset,
          category_id: payload.category_id,
          sort_type: payload.sort.type,
          sort_direction: payload.sort.direction
        }
      })
    },
    REQUIREMENT_REFRESH_DOCUMENTS(context, payload) {
      api.get(endpoint + 'get-documents?id=' + payload).then((res) => {
        if (res.data.ok === 1) {
          context.commit('requirement_refresh_documents', res.data.data)
        }
      })
    },
    REQUIREMENT_SET_COMMENTARY(context, payload) {
      api.post(endpoint + 'set-commentary', { id: payload.item.id, commentary: payload.commentary }).then((res) => {
        if (res.data.ok === 1) {
          context.commit('requirement_set_commentary', payload)
        }
      })
    },
    REQUIREMENT_SET_STATUS(context, payload) {
      context.commit('requirements_set_status', payload)
    },
    REQUIREMENT_TAKE_ALL(context) {
      return api.get(endpoint + 'take-all').then((res) => {
        context.commit('requirements_update_requirements', res.data.data)
      })
    },
    REQUIREMENT_REFRESH_DONT_MATCHES(context, medId) {
      api.get(endpoint + 'take-dont-match?medId=' + medId).then((res) => {
        if (res.data.ok === 1) {
          context.commit('dontmatch_change', res.data.data)
        }
      })
    },
    REQUIREMENT_TAKE_BY_MEDTYPE(context, payload) {
      return api.get(endpoint + 'take-by-medtype?medtype=' + payload).then((res) => {
        console.log(res.data.data)
        context.commit('requirements_update_requirements', res.data.data)
      })
    },
    REQUIREMENT_TAKE_WITH_FILTERS(context, payload) {
      let filters = {}
      filters.processes = payload.processes
      return api.post(endpoint + 'take-with-filters', filters).then((res) => {
        context.commit('requirements_update_requirements', res.data.data)
      })
    }
  }
}
export default requirement
