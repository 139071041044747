<template>
  <div class="download-link" @click="download">{{ name }}</div>
</template>

<script>
export default {
  props: {
    link: {
      type: [String, Object],
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    async download() {
      try {
        const res = await this.$api.get(this.link, { responseType: 'blob' })

        if (res?.data?.type === 'application/pdf') {
          const blob = new Blob([res.data], { type: 'application/pdf' })
          const tab = window.open(URL.createObjectURL(blob))

          if (!tab || tab.closed || typeof tab.closed === 'undefined') {
            return Promise.reject(
              'Всплывающее окно заблокировано! Разрешите показ всплывающих окон для этого сайта и повторите попытку.'
            )
          }

          return
        }

        const a = document.createElement('a')

        a.href = URL.createObjectURL(new Blob([res.data]))
        a.download = this.name
        document.body.appendChild(a)
        a.click()
        a.remove()
      } catch (e) {
        this.$store.dispatch('ALERT_CREATE', { text: e?.response?.data?.message || e.message, type: 'error' })
      }
    }
  }
}
</script>
