<script setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { NButton, NH1 } from 'naive-ui'
import { useChecklistsStore } from '@/store/checklists'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import ActionForm from '@/components/pages/Actions/actionComponents/ActionForm'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import { useEventsStore } from '@/store/events'
import { storeToRefs } from 'pinia'

const route = useRoute()
const router = useRouter()

const eventsStore = useEventsStore()
const { isSaving } = storeToRefs(eventsStore)
const checklistsStore = useChecklistsStore()
const { isLoading, isError, error } = storeToRefs(checklistsStore)

const formRef = ref(null)
const action = ref({})
const isCreatedFromChecklist = ref(!!action.value?.checklist || !!route?.query?.checklist)

onMounted(() => {
  if (route?.query?.requirement && route?.query?.checklist) {
    fetchChecklist()
  }
})

async function fetchChecklist() {
  const res = await checklistsStore.fetchOne(route.query.checklist)
  const checklist = res.data.data
  const commentary = checklist.requirements?.find(
    (item) => item?.organization_requirement?.id === parseInt(route.query?.requirement)
  )?.commentary

  action.value = {
    checklist,
    commentary,
    medtype: checklist.medtype,
    action: {
      assurance: checklist.assurance,
      assurance_id: checklist.assurance_id,
      auditor: checklist.assurance_user,
      auditor_id: checklist.assurance_user_id
    }
  }
}

async function save() {
  formRef.value?.validate((errors, values) => {
    if (errors) return

    eventsStore.create(values).then((res) => {
      router.push('/actions/' + res.data.data.id)
    })
  })
}

function cancel() {
  router.push(route.query?.checklist ? `/checklist/${route.query.checklist}` : '/actions/')
}
</script>

<template>
  <div class="max-w-6xl mx-auto">
    <div class="page-header">
      <div class="page-header__left">
        <NH1 class="mb-0">Создание мероприятия</NH1>
      </div>
      <div class="page-header__right">
        <div class="page-header__controls">
          <NButton type="primary" :loading="isSaving" @click="save">Сохранить</NButton>
          <NButton :disabled="isSaving" strong secondary @click="cancel">Отмена</NButton>
        </div>
      </div>
    </div>
    <div class="content">
      <NextAlert v-if="isError && !isLoading" class="mb-4" type="error" :text="error" />
      <SpinLoader v-else-if="isLoading" class="py-5" size="2xl" />
      <div v-else class="max-w-200">
        <div v-if="isCreatedFromChecklist" class="mb-4">
          <div class="mb-3">
            <div class="font-weight-bold">Выявленное несоответствие</div>
            <p class="text-secondary">{{ action?.commentary || '—' }}</p>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div class="font-weight-bold mr-2">Отчёт:</div>
            <RouterLink v-if="action?.checklist?.number" :to="`/checklist/${action?.checklist?.number}`">
              {{ action?.checklist?.number }}
            </RouterLink>
            <div v-else class="text-secondary">—</div>
          </div>
        </div>
        <ActionForm ref="formRef" :data="action" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  padding: 40px;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
}
</style>
