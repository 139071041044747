<template>
  <div class="content">
    <div class="row">
      <div class="col mb-4 general-col">
        <GeneralInfoWidget />
      </div>
      <div class="col mb-4 actions-col">
        <ActionsWidget />
      </div>
      <div class="col mb-4 calendar-col">
        <CalendarWidget />
      </div>
    </div>
    <ProcessesGraphicWidget class="mb-4" />
    <TableWidget />
  </div>
</template>

<script>
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import GeneralInfoWidget from '@/components/pages/MainPage/widgets/GeneralInfoWidget'
import CalendarWidget from '@/components/pages/MainPage/widgets/CalendarWidget'
import StatisticWidget from '@/components/pages/MainPage/widgets/StatisticWidget'
import ProcessesGraphicWidget from '@/components/pages/MainPage/widgets/ProcessesGraphicWidget'
import TableWidget from '@/components/pages/MainPage/widgets/TableWidget'
import ActionsWidget from '@/components/pages/MainPage/widgets/ActionsWidget'

export default {
  name: 'MainPage',
  components: {
    ActionsWidget,
    TableWidget,
    ProcessesGraphicWidget,
    StatisticWidget,
    CalendarWidget,
    GeneralInfoWidget,
    NextButton
  }
}
</script>

<style lang="scss">
.general-col {
  min-width: 420px;
}

.actions-col {
  min-width: 420px;
}

.calendar-col {
}

.statistic-col {
  min-width: 420px;
  display: none;
  @media (max-width: 1752px) {
    display: block;
  }
}
</style>
