const organization = {
    state: {
       header: {
           opened: false,
       }
    },
    getters: {
        headerOpened(state) {
           return state.header.opened;
        }
    },
    mutations: {
        COMMON_STATE_HEADER_TOGGLE(state) {
            state.header.opened = !state.header.opened;
        }
    },
    actions: {
      COMMON_STATE_HEADER_TOGGLE(context) {
          context.commit('COMMON_STATE_HEADER_TOGGLE')
      }
    },
}
export default organization;