import api from '@/api'

const notifications = {
  state: {
    notifications: []
  },
  getters: {
    hasNew(state) {
      return state.notifications.some((item) => {
        if (item.read_at != null) {
          return false
        } else {
          return true
        }
      })
    }
  },
  mutations: {
    notifications_update(state, payload) {
      state.notifications = payload
    },
    notifications_read(state, payload) {
      payload.read_at = JSON.parse(localStorage.getItem('user')).id
    },
    notification_delete(state, id) {
      state.notifications.splice(
        state.notifications.findIndex((item) => item.id == id),
        1
      )
    }
  },
  actions: {
    NOTIFICATIONS_DELETE(context, id) {
      return api.post('users/delete-notification', { id: id }).then((res) => {
        if (res.data.ok === 1) {
          context.commit('notification_delete', id)
        }
      })
    },
    NOTIFICATIONS_UPDATE: function (context, payload) {
      return api.get('users/get-notifications?page=' + payload).then((res) => {
        if (res.data.ok === 1) {
          context.commit('notifications_update', res.data.data)
        }
      })
    },
    NOTIFICATIONS_READ: function (context, payload) {
      return api.post('users/mark-notification-as-read?notification_id=' + payload.id).then((res) => {
        if (res.data.ok === 1) {
          context.commit('notifications_read', payload)
        }
      })
    }
  }
}
export default notifications
