<template>
  <div class="user-layout">
    <Header />
    <div class="user-layout__container">
      <Sidebar />
      <div class="content" :class="{ headerOpened: $store.getters['headerOpened'] }">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/layout/Header'
import Sidebar from '@/components/layout/Sidebar'

export default {
  name: 'UserLayout',
  components: {
    Header,
    Sidebar
  },
  computed: {}
}
</script>

<style scoped>
.content {
  padding: 100px 60px 60px 120px;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  transition: all 0.3s;
}
.user-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.user-layout__container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
@media (min-width: 1920px) {
  .headerOpened.content {
    width: calc(100% - 200px);
  }
  .headerOpened {
    margin-left: 200px;
  }
}
@media (min-width: 2300px) {
  .headerOpened {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
