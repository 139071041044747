export default {
  Card: {},
  common: {
    primaryColor: '#37c4a3',
    primaryColorHover: '#3eceac',
    primaryColorPressed: '#31AF92',
    primaryColorSuppl: '#3eceac',
    borderRadius: '4px'
  },
  Button: {
    borderRadiusTiny: '2px',
    // paddingTiny: '10px',
    paddingSmall: '14px',
    paddingMedium: '18px',
    paddingLarge: '22px',

    // heightTiny: '28px',
    heightSmall: '32px',
    heightMedium: '40px',
    heightLarge: '44px',

    // fontSizeTiny: '12px',
    fontSizeSmall: '14px',
    fontSizeMedium: '15px',
    fontSizeLarge: '16px'
  },
  Form: {
    feedbackFontSizeTiny: '10px',
    feedbackFontSizeSmall: '11px',
    feedbackFontSizeMedium: '12px',
    feedbackFontSizeLarge: '14px'
  }
}
