<script setup>
import { ref, computed } from 'vue'
import { NModal } from 'naive-ui'
import { getCoverPath } from '../utils'

const showModal = ref(false)
const video = ref({})

defineExpose({
  open(currentVideo) {
    showModal.value = true
    video.value = currentVideo
  }
})

const coverUrl = computed(() => getCoverPath(video.value.category.name, 'md'))
const link = computed(() => process.env.VUE_APP_BASE_URL + video.value.file)
</script>

<template>
  <NModal v-model:show="showModal" preset="card" :style="{ width: '720px' }" :title="video.name" :bordered="false">
    <video
      class="video-player"
      :src="link"
      :poster="coverUrl"
      preload="metadata"
      controlsList="nodownload noplaybackrate"
      playsinline
      controls
      disablepictureinpicture
      @click.right.prevent
    />
  </NModal>
</template>

<style lang="scss">
.video-player {
  max-width: 100%;
  border-radius: $borderRadius;
  overflow: hidden;
}
</style>
