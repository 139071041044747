import commonThemeOverrides from '@/theme/commonThemeOverrides'

/**
 * @type import('naive-ui').GlobalThemeOverrides
 */
export default {
  ...commonThemeOverrides,
  DataTable: {
    thColor: '#37c4a3',
    thColorHover: '#49ccad',
    thTextColor: '#fff',
    thIconColorActive: '#fff'
  }
}
