<template>
  <table class="table next-table">
    <thead v-if="columns" class="next-table__thead">
      <tr>
        <NextTableTh
          v-for="col in columns"
          :key="col.key"
          :col="col"
          @change:sort="(sort) => $emit('change:sort', sort)"
        />
      </tr>
    </thead>
    <tbody class="next-table__body">
      <tr v-if="loading">
        <td :colspan="columns.length">
          <SpinLoader class="py-4" size="xl" />
        </td>
      </tr>
      <tr v-else-if="!tableData || tableData.length === 0">
        <td class="text-center py-4 text-secondary" :colspan="columns.length">Нет данных</td>
      </tr>
      <template v-else>
        <tr v-for="(row, i) in tableData" :key="`row_${i}`" :class="row.rowClass">
          <td
            v-for="{ key, center, render = (row, v) => v } in columns"
            :key="`row_${i}_${key}`"
            :class="{ 'bg-gray-100': striped && i % 2 === 1 }"
          >
            <slot :key="key" :name="key" :row="row" :value="render(row, row[key])">
              <div :class="{ 'text-center': center }" v-html="render(row, row[key]) ?? placeholder" />
            </slot>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextTableTh from './NextTableTh'

export default {
  name: 'NextTable',
  components: { SpinLoader, NextTableTh },
  props: {
    columns: Object,
    tableData: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: '—'
    },
    emptyText: {
      type: String,
      default: 'Нет данных'
    },
    loading: Boolean,
    striped: Boolean
  },
  emits: ['change:sort']
}
</script>
