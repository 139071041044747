<template>
  <div class="table">
    <div class="table-head">
      <div class="table-content">
        <div class="table-item table-row">
          <div class="functional-row table-row">Функциональные требования</div>
          <div class="criteria-list">
            <div class="criteria-item table-row criteria-table">
              <div class="row-text criteria-row">Критерии оценки</div>
              <div class="requirement-list table-row">
                <div class="requirement-item table-requirement">
                  <div class="row-text requirement-row">Наименование требования</div>
                  <div class="requirement-id">Номер</div>
                  <div class="requirement-status">Статус</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-content">
      <template v-if="functionalRequirements.length > 0">
        <processListItem v-for="(item, key) in functionalRequirements" :key="key" :item="item" />
        <div class="pagination-container">
          <Paginate
            v-if="count / perPage > 1"
            :key="perPage"
            :page-count="Math.ceil(count / perPage)"
            :page-range="5"
            :margin-pages="2"
            :next-text="''"
            :prev-text="''"
            :click-handler="changePage"
          />
        </div>
      </template>
      <template v-else>
        <div class="no-items">
          <div class="functional-row table-row list-item">Здесь пока нет функциональных требований</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import processListItem from '@/components/pages/ProcessPage/processItems/processListItem'
import paginationMixin from '@/components/mixins/paginationMixin'
import Paginate from 'vuejs-paginate-next'
export default {
  name: 'VkkTab',
  components: { processListItem, Paginate },
  mixins: [paginationMixin],
  props: {
    process: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      uploadOpened: false,
      functionalRequirements: [],
      perPage: 1000
    }
  },
  mounted() {
    this.changePage(1)
  },
  methods: {
    async changePage(val) {
      this.page = val
      let result = await this.$api.get(
        'process/get-functional-requirements?id=' +
          this.$router.currentRoute.value.params.id +
          '&offset=' +
          this.perPage * (this.page - 1) +
          '&limit=' +
          this.perPage
      )
      if (result.data.ok === 1) {
        this.functionalRequirements = result.data.data
        this.count = result.data.count
      }
    }
  }
}
</script>

<style scoped>
.table-content .no-items .functional-row {
  width: 100%;
}
.table-requirement {
  height: 100%;
}
.functional-row {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 180px;
  flex: none;
  padding-left: 10px;
  border: 1px solid #cccccc;
}

.criteria-list {
  width: 100%;
  display: flex;
}
.list-item {
  padding-top: 10px;
  padding-bottom: 10px;
}
.requirement-item .row-text {
}
.requirement-status {
  width: 95px;
  flex: none;
  padding-left: 10px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  justify-content: center;
  border: 1px solid #cccccc;
}
.table-head .requirement-id {
  font-size: 16px;
}
.requirement-id {
  font-size: 14px;
  width: 75px;
  flex: none;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #cccccc;
}
.criteria-row {
  width: 180px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  flex: none;
  border: 1px solid #cccccc;
}
.requirement-row {
  width: 100%;
  min-width: 220px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border: 1px solid #cccccc;
}
.requirement-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.functional-item {
  display: flex;
}
.requirement-item {
  display: flex;
  flex: auto;
}
.criteria-table {
  height: 100%;
}
.criteria-item {
  display: flex;
  width: 100%;
}
.table-item {
  display: flex;
}
.status-item {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.criteria-text {
  width: 100%;
}
.status-2 {
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
}

.status-3 {
  background: #d65959;
}

.status-4 {
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5001 11.0001L0.500075 21.0001L-0.207031 20.293L9.79297 10.293L0.500075 1.00008L1.20718 0.292969L10.5001 9.58586L19.793 0.292969L20.5001 1.00008L11.2072 10.293L21.2072 20.293L20.5001 21.0001L10.5001 11.0001Z' fill='%23666666'/%3E%3C/svg%3E%0A");
}

.status-1 {
  background-color: #666666;
}
.table-head {
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  color: white;
  width: 100%;
}
.table-item {
  display: flex;
}
.table-content .list-item {
  font-size: 14px;
}
.table {
  min-width: 830px;
}
.header {
  padding-bottom: 20px;
  font-size: 23px;
}
</style>
