<script setup>
import { h, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { NDataTable, NButton, NAlert } from 'naive-ui'
import ModalWindow from '@/components/widgets/custom-components/modalWindow/modalWindow'
import UploadDocuments from '@/components/widgets/custom-components/modalWindow/modalComponents/uploadDocuments'
import EditDocument from '@/components/widgets/custom-components/modalWindow/modalComponents/editDocument'
import FileLink from '@/components/common/FileLink'
import TableActions from '@/components/common/TableActions'
import NextSelect from '@/components/widgets/simple-components/select/NextSelect'
import { makeDate } from '@/helper'
import useDocuments from '@/composables/useDocuments'
import { useDocumentsStore } from '@/store/documents'

const store = useStore()
const documentsStore = useDocumentsStore()
const { deleteDocument, getOrganizationsFiles, isLoading } = useDocuments()

onMounted(() => {
  fetch()
})

const tableData = ref([])
const uploadOpened = ref(false)
const openEditFile = ref(false)
const editingFile = ref(null)
const categoryId = ref(0)
const pagination = reactive({
  page: 1,
  pageSize: 16,
  onChange: (page) => {
    pagination.page = page
    fetch(page)
  }
})
const sort = reactive({
  type: 'date',
  direction: 'desc'
})

const type = 'organization'
const organizationId = store.getters.currentOrganization?.id

const columns = [
  { title: 'Название документа', key: 'name' },
  {
    title: 'Файл',
    key: 'old_name',
    render: (row) =>
      h(FileLink, {
        link: 'documents/download/?id=' + (row.document_id || row.id),
        name: row.old_name || row.file_name
      })
  },
  { title: 'Категория', key: 'text' },
  {
    title: 'Дата загрузки',
    key: 'upload_date',
    width: 160,
    defaultSortOrder: false,
    sorter: true,
    render: (row) => makeDate(row.upload_date)
  },
  {
    title: '',
    key: 'actions',
    width: 80,
    align: 'center',
    render: (row) =>
      !row.protected
        ? h(TableActions, {
            row,
            options: {
              edit: {
                selectHandler: () => {
                  editingFile.value = row
                  openEditFile.value = true
                }
              },
              delete: {
                selectHandler: async () => {
                  await deleteDocument({ type, file: row, organizationId })
                  await fetch()
                }
              }
            }
          })
        : ''
  }
]

const changeSort = (sort) => {
  sort.direction = sort.order === 'descend' ? 'desc' : 'asc'
  fetch(pagination.page)
}

const uploadHandler = async (formData) => {
  formData.append('type', type)

  const res = await documentsStore.upload(formData)

  if (res.data.ok === 1) {
    uploadOpened.value = false
    await fetch(pagination.page)
  }
}

async function fetch(page = 1) {
  const params = {
    id: organizationId,
    limit: pagination.pageSize,
    offset: pagination.pageSize * (pagination.page - 1),
    category_id: categoryId.value,
    sort_type: sort.type,
    sort_direction: sort.direction
  }

  tableData.value = await getOrganizationsFiles(params, page)
  pagination.page = page
}
</script>

<template>
  <div class="content-block">
    <div class="d-flex justify-content-between mb-4">
      <NextSelect
        v-model="categoryId"
        :default-value="0"
        label="Категория документов"
        class="w-84"
        request="documents/get-types"
        :include-start="{ text: 'Все', id: 0 }"
        clearable
        @change="fetch(1)"
      />
      <NButton type="primary" @click="uploadOpened = true">Добавить документ для организации</NButton>
    </div>
    <div class="content-info">
      <NDataTable
        v-if="!documentsStore.isError"
        :columns="columns"
        :data="tableData"
        :loading="isLoading"
        :pagination="pagination"
        remote
        @update:sorter="changeSort"
      />
      <NAlert v-else title="Произошла ошибка!" type="error">
        {{ documentsStore.error }}
      </NAlert>
    </div>
    <ModalWindow v-if="uploadOpened" header="Загрузка документа" @closeHandler="uploadOpened = false">
      <UploadDocuments
        :id="organizationId"
        :type="type"
        @uploadHandler="uploadHandler"
        @closeHandler="uploadOpened = false"
      />
    </ModalWindow>
    <ModalWindow v-if="openEditFile" header="Редактировать документ" @closeHandler="openEditFile = false">
      <EditDocument :file="editingFile" />
    </ModalWindow>
  </div>
</template>
