<template>
  <div class="file-manager">
    <div class="file-manager-head">
      <div class="breadcrumbs">
        <div
          v-for="(item, key) in breadcrumbs"
          :key="item.id"
          class="crumb"
          :class="{ last: key + 1 === breadcrumbs.length, inactive: pause }"
          @click="openCrumb(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <nextButton
        v-if="currentFolder.id !== 'public' && type === 'admin'"
        type="success"
        color="blue"
        text="Загрузить файл"
        @click="openUploadFile"
      />
    </div>
    <modalWindow v-if="openUpload" @closeHandler="openUpload = false">
      <uploadAdminDocuments
        v-if="type === 'admin'"
        :type="createType"
        :public-upload="true"
        @closeHandler="openUpload = false"
      />
      <uploadDocuments
        v-if="type !== 'admin'"
        :type="createType"
        :public-upload="true"
        @closeHandler="openUpload = false"
      />
    </modalWindow>
    <div class="files">
      <div class="file-item file-header">
        <div class="name-column column">Название</div>
        <div class="file-column column">Файл</div>
        <div class="category-column column">Категория</div>
        <div class="date-column column">Дата</div>
        <div class="events-column column">Действия</div>
      </div>
      <div class="files-list">
        <template v-if="!pause">
          <template v-if="files.length > 0">
            <div v-for="item in files" :key="item.id" class="item" style="padding-bottom: 10px">
              <fileManagerItem
                :key="item.id"
                :actions="type"
                :folder_type="currentFolder.folder_type"
                :item="item"
                :type="currentFolder.id"
                @deleteDocument="refresh"
                @clickHandler="defaultAction(item)"
              />
            </div>
          </template>
          <div v-else class="item">Здесь нет документов</div>
        </template>
        <div v-else class="loader">
          <SpinLoader v-if="pause" size="50px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import fileManagerItem from '@/components/widgets/custom-components/modalWindow/modalComponents/fileManager/fileManagerItem'
import uploadDocuments from '@/components/widgets/custom-components/modalWindow/modalComponents/uploadDocuments'
import uploadAdminDocuments from '@/components/widgets/custom-components/modalWindow/modalComponents/adminUploadDocuments/uploadAdminDocuments'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import modalWindow from '@/components/widgets/custom-components/modalWindow/modalWindow'

export default {
  name: 'FileManager',
  components: { SpinLoader, fileManagerItem, uploadDocuments, nextButton, modalWindow, uploadAdminDocuments },
  props: {
    type: {
      default() {
        return 'main'
      }
    },
    currentType: {
      default() {
        return 'all'
      }
    }
  },
  data() {
    return {
      breadcrumbs: [],
      page: 1,
      openUpload: false,
      perPage: 20,
      currentFolder: { id: 'main', name: 'Файлы' },
      sort: {
        sort_type: 'date',
        sort_direction: 'desc'
      },
      pause: false,
      files: [],
      structure: []
    }
  },
  computed: {
    createType() {
      switch (this.currentFolder.id) {
        case 'organization':
          return 'organization'
        case 'process_files':
          return 'process'
        case 'requirements_files':
          return 'requirement'
      }
      return 'process'
    }
  },
  beforeMount() {
    if (this.type === 'main') {
      this.breadcrumbs.length === 0 ? this.breadcrumbs.push({ id: 'main', name: 'Файлы' }) : ''
      this.structure = [
        {
          id: 'my',
          name: 'Загруженные файлы',
          extension: 'folder',
          folder_type: 'directory',
          text: 'Папка',
          subfolders: [
            {
              id: 'organization',
              name: 'Файлы организации',
              extension: 'folder',
              folder_type: 'personal',
              text: 'Папка'
            },
            { id: 'process', name: 'Файлы процессов', extension: 'folder', folder_type: 'personal', text: 'Папка' },
            {
              id: 'requirements',
              name: 'Файлы требований',
              extension: 'folder',
              folder_type: 'personal',
              text: 'Папка'
            },
            { id: 'action', name: 'Файлы мероприятий', extension: 'folder', folder_type: 'personal', text: 'Папка' },
            {
              id: 'control',
              name: 'Файлы планов проверки',
              extension: 'folder',
              folder_type: 'personal',
              text: 'Папка'
            }
          ]
        },
        {
          id: 'public',
          name: 'Общие файлы',
          extension: 'folder',
          folder_type: 'directory',
          text: 'Папка',
          subfolders: [
            {
              id: 'organization',
              name: 'Файлы организации',
              extension: 'folder',
              folder_type: 'public',
              text: 'Папка'
            },
            { id: 'process_files', name: 'Файлы процессов', extension: 'folder', folder_type: 'public', text: 'Папка' },
            {
              id: 'requirements_files',
              name: 'Файлы требований',
              extension: 'folder',
              folder_type: 'public',
              text: 'Папка'
            }
          ]
        }
      ]
      this.files = this.structure
      return
    } else if (this.type === 'public' || this.type === 'admin') {
      this.structure = [
        {
          id: 'public',
          name: 'Общие файлы',
          extension: 'folder',
          folder_type: 'directory',
          text: 'Папка',
          subfolders: [
            {
              id: 'organization',
              name: 'Файлы организации',
              extension: 'folder',
              folder_type: 'public',
              text: 'Папка'
            },
            { id: 'process_files', name: 'Файлы процессов', extension: 'folder', folder_type: 'public', text: 'Папка' },
            {
              id: 'requirements_files',
              name: 'Файлы требований',
              extension: 'folder',
              folder_type: 'public',
              text: 'Папка'
            }
          ]
        }
      ]
    } else {
      this.structure = [
        {
          id: 'my',
          name: 'Загруженные файлы',
          extension: 'folder',
          folder_type: 'directory',
          text: 'Папка',
          subfolders: [
            {
              id: 'organization',
              name: 'Файлы организации',
              extension: 'folder',
              folder_type: 'personal',
              text: 'Папка'
            },
            { id: 'process', name: 'Файлы процессов', extension: 'folder', folder_type: 'personal', text: 'Папка' },
            {
              id: 'requirements',
              name: 'Файлы требований',
              extension: 'folder',
              folder_type: 'personal',
              text: 'Папка'
            },
            { id: 'action', name: 'Файлы мероприятий', extension: 'folder', folder_type: 'personal', text: 'Папка' },
            {
              id: 'control',
              name: 'Файлы планов проверки',
              extension: 'folder',
              folder_type: 'personal',
              text: 'Папка'
            }
          ]
        }
      ]
    }
    this.currentFolder = this.structure[0]
    if (this.currentType === 'all') {
      this.breadcrumbs.length === 0 ? this.breadcrumbs.push(this.structure[0]) : ''
      this.files = this.currentFolder.subfolders
    } else {
      this.breadcrumbs.length === 0 ? this.breadcrumbs.push(this.structure[0]) : ''
      let currentFolder = this.currentFolder.subfolders.find((res) => {
        if (res.id === this.currentType) {
          return res
        }
      })
      this.breadcrumbs.push(currentFolder)
      this.currentFolder = currentFolder
      this.refresh()
    }
  },
  methods: {
    openUploadFile() {
      console.log(this.currentFolder)
      this.openUpload = true
    },
    openCrumb(file) {
      if (this.pause) {
        return
      }
      this.pause = false
      this.currentFolder = file
      let index = this.breadcrumbs.indexOf(file)
      if (index !== -1) {
        if (index + 1 === this.breadcrumbs.length) {
          return
        }
        if (file.id === 'main') {
          this.breadcrumbs.splice(index + 1, this.breadcrumbs.length - (index + 1))
          this.files = this.structure
        } else {
          this.breadcrumbs.splice(index + 1, this.breadcrumbs.length - (index + 1))
          this.files = this.structure[this.structure.indexOf(file)].subfolders
        }
      }
    },
    refresh() {
      if (this.currentFolder.folder_type === 'public') {
        this.$api
          .get(
            'documents/get-files/?sort_type=' +
              this.sort.sort_type +
              '&type=' +
              this.currentFolder.id +
              '&sort_direction=' +
              this.sort.sort_direction +
              '&offset=' +
              this.perPage * (this.page - 1) +
              '&limit=' +
              this.perPage
          )
          .then((res) => {
            this.files = res.data.data
            this.pause = false
          })
      } else {
        this.$api
          .get(
            this.currentFolder.id +
              '/get-documents/?sort_type=' +
              this.sort.sort_type +
              '&sort_direction=' +
              this.sort.sort_direction +
              '&offset=' +
              this.perPage * (this.page - 1) +
              '&limit=' +
              this.perPage
          )
          .then((res) => {
            this.files = res.data.data
            this.pause = false
          })
      }
    },
    openFolder(file) {
      if (this.pause) {
        return false
      }
      this.currentFolder = file
      if (file.folder_type === 'directory') {
        this.files = file.subfolders
        this.breadcrumbs.push(file)
      } else {
        this.pause = true
        this.breadcrumbs.push(file)
        this.refresh()
      }
    },
    defaultAction(file) {
      if (file.folder_type) {
        this.openFolder(file)
      } else {
        this.$emit('clickHandler', file)
      }
    }
  }
}
</script>

<style scoped>
.file-manager-head {
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
}

.crumb.inactive {
  opacity: 0.6;
  cursor: default;
}

.column {
  border-right: 1px solid white;
  border-left: 1px solid white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.crumb:after {
  content: ' > ';
}

.crumb.last:after {
  content: '';
}

.crumb.last {
  cursor: default;
  color: #333333;
}

.crumb {
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 18px;
  color: #00cccc;
}

.breadcrumbs {
  padding-top: 10px;
  padding-bottom: 15px;
  display: flex;
}

.category-column {
  width: 250px;
  flex: none;
}

.date-column {
  width: 200px;
  flex: none;
}

.files-list {
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.file-item.file-header {
  border: none;
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  color: white;
}

.events-column {
  width: 160px;
  flex: none;
}

.file-item {
  display: flex;
  border-radius: 3px;
  background-color: rgba(243, 243, 243, 0.85);
  margin-bottom: 10px;
}

.file-column {
  width: 250px;
  flex: none;
}

.name-column {
  width: 100%;
  display: flex;
}
</style>
