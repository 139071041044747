<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { NButton } from 'naive-ui'
import MainTab from '@/components/pages/organization/MainTab'
import MainTabEdit from '@/components/pages/organization/MainTabEdit'
import DocumentsTabs from '@/components/pages/organization/DocumentsTab'
import ProcessesTab from '@/components/pages/organization/ProcessesTab'
import VkkTab from '@/components/pages/organization/VkkTab'
import UsersTab from '@/components/pages/organization/UsersTab'
import { useOrganizationStore } from '@/store/organization'

const organizationStore = useOrganizationStore()
const store = useStore()
const route = useRoute()
const router = useRouter()

const editMode = ref(false)
const activeTab = ref('main')
const tabs = ref([
  { key: 'main', title: 'Общая информация' },
  { key: 'process', title: 'Направления' },
  { key: 'vkk', title: 'Показатели' },
  { key: 'documents', title: 'Документы' },
  { key: 'users', title: 'Ответственные' }
])
const organizationId = computed(() => organizationStore.organization?.id)
const hasOrganization = computed(() => !!organizationId.value)

onMounted(() => {
  setTab(route.params.tab)
})

function setTab(tabKey) {
  if (tabKey === 'main') {
    store.dispatch('ORGANIZATION_GET_ALL_MEDHELPS')
    store.dispatch('ORGANIZATION_UPDATE_' + activeTab.value.toUpperCase(), organizationId)
  }
  if (hasOrganization.value) {
    activeTab.value = tabKey
    editMode.value = false
    router.push('/organization/' + tabKey)
  }
}
</script>

<template>
  <div>
    <h1 class="page-header">Организация</h1>
    <div class="tabs">
      <div
        v-for="tab in tabs"
        :key="tab.key"
        class="tab"
        :class="{ active: activeTab === tab.key, disabled: activeTab !== tab.key && !hasOrganization }"
        @click="setTab(tab.key)"
      >
        {{ tab.title }}
      </div>
    </div>
    <div class="tab-content">
      <template v-if="activeTab === 'main'">
        <MainTabEdit v-if="editMode" @cancel-handle="editMode = false" />
        <template v-else>
          <MainTab v-if="hasOrganization" @open-edit="editMode = true" />
          <NButton v-else class="mt-5" type="primary" @click="editMode = true"> Создать организацию </NButton>
        </template>
      </template>
      <ProcessesTab v-else-if="activeTab === 'process'" />
      <VkkTab v-else-if="activeTab === 'vkk'" />
      <DocumentsTabs v-else-if="activeTab === 'documents'" />
      <UsersTab v-else-if="activeTab === 'users'" />
    </div>
  </div>
</template>
