<template>
  <div class="profile">
    <div class="profile-block">
      <div class="profile-header">Личные данные:</div>
      <div class="profile-list">
        <div class="profile-list__item">
          <organizationInformationItem
            v-if="!editMode"
            header="Ваши ФИО"
            icon="white-avatar-icon"
            :text="currentUser.name"
          />
          <inputWithHeader v-else header="Ваши ФИО" :start-text="currentUser.name" @inputHandler="changeNameInput" />
        </div>
        <!--          <div class="profile-list__item">
                    <organizationInformationItem header="Ваше подразделение" icon="white-plus-icon" :text="'Введите название подразделения'" v-if="!editMode"></organizationInformationItem>
                    <inputWithHeader header="Ваше подразделение" :start-text="'В разработке'" v-else></inputWithHeader>
                  </div>-->
        <div class="profile-list__item">
          <organizationInformationItem
            v-if="!editMode"
            header="Ваша должность"
            icon="white-shield-icon"
            :text="currentUser.post || 'Нет'"
          />
          <inputWithHeader
            v-else
            header="Ваша должность"
            :start-text="currentUser.post || ''"
            :placeholder="'Введите должность'"
            @inputHandler="changePostInput"
          />
        </div>
      </div>
    </div>
    <div class="profile-block">
      <div class="profile-header">Контактная информация:</div>
      <div class="profile-list">
        <div class="profile-list__item">
          <organizationInformationItem
            v-if="!editMode"
            header="Ваш телефон"
            icon="white-phone-icon"
            :text="currentUser.phone || 'Нет'"
          />
          <inputWithHeader
            v-else
            header="Ваш телефон"
            type="tel"
            placeholder="+7(###)###-##-##"
            :start-text="currentUser.phone"
            @inputHandler="changePhoneInput"
          />
        </div>
        <div class="profile-list__item">
          <organizationInformationItem
            v-if="!editMode"
            header="Ваша электронная почта"
            icon="white-email-icon"
            :text="currentUser.email"
          />
          <inputWithHeader
            v-else
            header="Ваша электронная почта"
            :start-text="currentUser.email"
            @inputHandler="changeEmailInput"
          />
        </div>
        <div class="profile-list__item">
          <organizationInformationItem v-if="!editMode" header="Ваш пароль" icon="white-lock-icon">
            <div class="information-text pointer" @click="openEditMode">Сменить пароль</div>
          </organizationInformationItem>
          <div v-else class="" style="width: 226px" />
        </div>
      </div>
    </div>
    <div class="profile-block">
      <div class="profile-header">Дополнительная информация:</div>
      <div class="profile-list">
        <div class="profile-doc">
          <checkbox v-if="!editMode" :reverse="true" :disabled="true" label="Электронная подпись:" />
          <checkbox
            v-else
            :reverse="true"
            label="Электронная подпись:"
            :start-active="currentUser.signature"
            @changeHandler="changeSignature"
          />
        </div>
        <div class="profile-doc">
          <checkbox v-if="!editMode" :reverse="true" :disabled="true" label="Комиссия ВРМ:" />
          <checkbox
            v-else
            :reverse="true"
            label="Комиссия ВРМ:"
            :start-active="currentUser.vkk"
            @changeHandler="changeVkk"
          />
        </div>
        <div class="profile-doc">
          <div class="profile-doc__text">Должностная инструкция:</div>
          <div v-if="!editMode && instruction !== ''" class="profile-doc__link">
            <div class="green-document-icon icon" />
            Скачать
          </div>
          <div v-else class="profile-doc__link pointer" @click="openInstruction">
            <div class="green-document-icon icon" />
            <template v-if="!hasInstruction"> Загрузить </template>
            <template v-else> Заменить </template>
          </div>
          <input ref="instruction" type="file" hidden @change="changeInstruction" />
        </div>
      </div>
      <div v-if="!editMode" class="buttons">
        <nextButton text="РЕДАКТИРОВАТЬ ПРОФИЛЬ" type="edit" color="white" @clickHandler="openEditMode" />
      </div>
    </div>
    <div v-if="editMode" class="profile-block">
      <div class="profile-header">Изменение пароля:</div>
      <div class="profile-list">
        <div class="profile-list__item">
          <inputWithHeader
            header="Новый пароль"
            :autocomplete="false"
            type="password"
            :class="{ warning: checkNewPassword }"
            @inputHandler="changeNewPassword"
          />
        </div>
        <div class="profile-list__item">
          <inputWithHeader
            header="Подтвердить пароль"
            :autocomplete="false"
            type="password"
            :class="{ warning: checkRePassword }"
            @inputHandler="changeRepassword"
          />
        </div>
      </div>
      <div class="buttons">
        <nextButton v-if="editMode" color="green" type="next" text="СОХРАНИТЬ" @click="save" />
        <nextButton v-if="editMode" color="white" type="close" text="ОТМЕНИТЬ" @clickHandler="closeEditMode" />
      </div>
    </div>
  </div>
</template>

<script>
import organizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import checkbox from '@/components/widgets/simple-components/inputs/checkbox'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
export default {
  name: 'ProfileUser',
  components: { organizationInformationItem, checkbox, nextButton, inputWithHeader },
  data() {
    return {
      instruction: '',
      oldPassword: '',
      newPassword: '',
      rePassword: '',
      edit: false,
      editMode: false,
      currentUser: {}
    }
  },
  computed: {
    hasInstruction() {
      if (this.currentUser.instruction_id) {
        return true
      } else {
        return false
      }
    },
    checkRePassword() {
      if (this.newPassword !== this.rePassword) {
        return true
      } else {
        return false
      }
    },
    checkNewPassword() {
      if (this.newPassword === '' && this.oldPassword !== '') {
        return true
      } else {
        return false
      }
    }
  },
  beforeMount() {
    this.currentUser = JSON.parse(localStorage.getItem('user'))
  },
  mounted() {
    this.editMode = this.edit
  },
  methods: {
    changeInstruction(e) {
      this.instruction = e.target.files[0]
    },
    openInstruction() {
      this.$refs.instruction.click()
    },
    save() {
      let user = {
        id: this.currentUser.id,
        name: this.currentUser.name,
        phone: this.currentUser.phone,
        vkk: this.currentUser.vkk,
        med_commission: this.currentUser.med_commission,
        signature: this.currentUser.signature,
        post: this.currentUser.post,
        old_password: this.oldPassword,
        password: this.newPassword
      }
      if (this.instruction !== '') {
        let data = new FormData()
        data.append('file', this.instruction)
        data.append('user_id', this.currentUser.id)
        this.$api.post('documents/upload-instruction', data).then((res) => {
          if (res.data.ok === 1) {
            this.instruction = res.data.data.document
            this.currentUser.instruction_id = this.instruction.id
            this.currentUser.instruction = this.instruction
            localStorage.setItem('user', JSON.stringify(this.currentUser))
            this.editMode = false
          }
        })
      }
      this.$api.post('responsible/set?id=' + user.id, user).then((res) => {
        if (res.data.ok === 1) {
          this.currentUser = res.data.data
          localStorage.setItem('user', JSON.stringify(this.currentUser))
          this.editMode = false
        }
      })
      /* if(result.data.ok === 1) {
         this.changePage(1);
         this.uploadOpened = false;
       }*/
    },
    changeCommission(val) {
      if (val) {
        this.currentUser.med_commission = 1
      } else {
        this.currentUser.med_commission = 0
      }
    },
    changeVkk(val) {
      if (val) {
        this.currentUser.vkk = 1
      } else {
        this.currentUser.vkk = 0
      }
    },
    changeSignature(val) {
      if (val) {
        this.currentUser.signature = 1
      } else {
        this.currentUser.signature = 0
      }
    },
    changeNewPassword(val) {
      this.newPassword = val
    },
    changeOldPassword(val) {
      this.oldPassword = val
    },
    changeRepassword(val) {
      this.rePassword = val
    },
    closeEditMode() {
      this.editMode = false
    },
    changePostInput(val) {
      this.currentUser.post = val
    },
    changeEmailInput(val) {
      this.currentUser.email = val
    },
    changePhoneInput(val) {
      this.currentUser.phone = val
    },
    changeNameInput(val) {
      this.currentUser.name = val
    },
    openEditMode() {
      this.editMode = true
    }
  }
}
</script>

<style scoped>
.profile-list__item {
  padding-right: 40px;
}
.buttons {
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
}

.green-document-icon {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.profile-doc {
  width: 308px;
  font-size: 12px;
  display: flex;
}
.profile-doc__link {
  color: #1cc8ab;
  margin-left: 16px;
}
.profile-header {
  font-weight: 400;
  font-size: 23px;
  line-height: 31px;
  color: #333333;
  padding-bottom: 20px;
}
.information-text:hover {
  text-decoration: underline;
}
.information-text {
  color: #1cc8ab;
}
.profile-block {
  border-bottom: 1px solid #cccccc;
  padding: 20px 40px;
}
.profile-list {
  display: flex;
  justify-content: flex-start;
}
.profile {
  width: calc(90vw - 100px);
  max-width: 1320px;
}
</style>
