<template>
  <div class="notify-settings">
    <div class="notify-header">Окно настройки уведомлений пользователя:</div>
    <div v-if="!loading" class="content">
      <div class="settings-block">
        <div class="settings-item">
          <div class="settings-text" />
          <div class="settings-message subheader">Уведомления в ЛК</div>
          <div class="settings-email subheader">На email</div>
          <div class="settings-filter subheader">Принимать уведомления от</div>
        </div>
      </div>
      <template v-for="filteredItem in items" :key="filteredItem.group">
        <div v-if="filteredItem.group !== 'Документы'" class="settings-block">
          <div class="settings-header">
            {{ filteredItem.group }}
          </div>
          <template v-for="item in filteredItem.items" :key="item.id">
            <div class="settings-item">
              <div class="settings-text">{{ item.name }}</div>
              <div class="settings-message">
                <checkbox
                  :start-active="item.notify_in_dashboard == 0 ? false : true"
                  @changeHandler="changeSettings(item, 'dashboard')"
                />
              </div>
              <div class="settings-email">
                <checkbox
                  :start-active="item.notify_by_email == 0 ? false : true"
                  @changeHandler="changeSettings(item, 'email')"
                />
              </div>
              <div class="settings-filter">
                <dropdownListWithHeader
                  :key="item.filter"
                  :header="''"
                  :list="filters"
                  :start-item="filters[item.filter]"
                  :search="false"
                  @selectHandler="
                    (val) => {
                      changeFilter(item, val)
                    }
                  "
                />
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
    <SpinLoader v-if="loading" class="py-5" size="2xl" />
  </div>
</template>

<script>
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import checkbox from '@/components/widgets/simple-components/inputs/checkbox'
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
export default {
  name: 'NotifySettings',
  components: { SpinLoader, checkbox, dropdownListWithHeader },
  data() {
    return {
      timeoutRequest: '',
      filters: [
        { id: 0, text: 'Все' },
        { id: 1, text: 'Организация' },
        { id: 2, text: 'Личные' }
      ],
      currentFilter: { id: 0, text: 'Все' },
      loading: true,
      settings: [],
      items: []
    }
  },
  computed: {},
  watch: {
    settings() {
      let iter = 0
      this.settings.sort((a, b) => {
        if (a.group > b.group) {
          return 1
        } else if (a.group < b.group) {
          return -1
        }
        return 0
      })
      this.settings.map((item) => {
        if (!this.items.find((index) => index.group === item.group)) {
          this.items.push({ group: item.group, items: [] })
          iter++
        }
        this.items[iter - 1].items.push(item)
      })
    }
  },
  beforeMount() {
    this.getNotifySettings()
  },
  methods: {
    changeFilter(item, val) {
      item.filter = val.id
      clearTimeout(this.timeoutRequest)
      this.timeoutRequest = setTimeout(() => {
        this.setSettings()
      }, 1000)
    },
    changeSettings(item, type) {
      if (type === 'dashboard') {
        item.notify_in_dashboard = item.notify_in_dashboard == 1 ? 0 : 1
      }
      if (type === 'email') {
        item.notify_by_email = item.notify_by_email == 1 ? 0 : 1
      }
      clearTimeout(this.timeoutRequest)
      this.timeoutRequest = setTimeout(() => {
        this.setSettings()
      }, 1000)
    },
    setSettings() {
      this.$api.post('users/set-notifications-config', this.settings)
    },
    getNotifySettings() {
      this.$api.get('users/get-notifications-config').then((res) => {
        this.settings = res.data.data
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>
.settings-filter {
  width: 240px;
  flex: none;
}
.settings-block {
  border-bottom: 1px solid #cccccc;
  padding: 20px 40px;
}
.notify-header {
  font-weight: 400;
  font-size: 23px;
  padding-left: 40px;
  padding-top: 20px;
  line-height: 31px;
  color: #333333;
}
.subheader {
  font-size: 16px;
  font-weight: 600;
}
.settings-item {
  width: 100%;
  align-items: center;
  padding-bottom: 5px;
  display: flex;
}
.settings-header {
  font-size: 18px;
  padding-bottom: 20px;
  font-weight: 600;
}
.settings-text {
  width: 100%;
}
.settings-email {
  width: 200px;
  flex: none;
  align-items: center;
  justify-content: center;
  display: flex;
}
.settings-message {
  width: 200px;
  flex: none;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
