<template>
  <div class="dropdown-item" @click="clickHandler">{{text}}</div>
</template>

<script>
export default {
  name: "dropdownItem",
  props: {
    text: {
      required: true,
    },
    id: {
      default() {
        return false;
      }
    }
  },
  computed: {
    getId() {
      if(this.id) {
        return this.id;
      } else {
        return this.text;
      }
    }
  },
  methods: {
    clickHandler() {
      this.$emit('clickHandler', this.getId);
    }
  }
}
</script>

<style scoped>
.dropdown-item {
  width: auto;
  font-size: 14px;
  white-space: normal;
  cursor: pointer;
}
  .dropdown-item {
    padding-left: 10px;
    padding-right: 10px;
  }
</style>