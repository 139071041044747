<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header__heading">Процессы</div>
    </div>
    <div class="tabs">
      <div class="tab" :class="{ active: activeTab === 'processes' }" @click="activeTab = 'processes'">Процессы</div>
      <div class="tab" :class="{ active: activeTab === 'documents' }" @click="activeTab = 'documents'">Документы</div>
    </div>
    <div class="content">
      <template v-if="activeTab === 'processes'">
        <NextSelect
          class="w-80 mb-4"
          request="organization/get-organization-medtypes"
          placeholder="Направления"
          clearable
          @change="changeMedtype"
        />
        <template v-if="!error">
          <NextTable
            class="table-responsive-xl"
            :columns="columns"
            :table-data="tableData"
            :loading="isLoading"
            striped
            @change:sort="changeSort"
          >
            <template #text="{ row, value }">
              <RouterLink :to="`/process/${row.id}`" class="text-gray-800">
                {{ value }}
              </RouterLink>
            </template>
            <template #checklist="{ row }">
              <RouterLink v-if="row.checklist" :to="`/checklist/${row.checklist.number}`">
                {{ row.checklist.number }}
              </RouterLink>
            </template>
          </NextTable>
          <div class="pagination-container">
            <Paginate
              v-if="count / perPage > 1"
              :key="perPage"
              :page-count="Math.ceil(count / perPage)"
              :page-range="5"
              :margin-pages="2"
              next-text=""
              prev-text=""
              :click-handler="fetch"
            />
          </div>
        </template>
        <NextAlert v-else type="error" title="Произошла ошибка" :text="error" />
      </template>
      <template v-if="activeTab === 'documents'">
        <Documents :document-id="''" type="process" :items="documents" />
      </template>
    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate-next'
import paginationMixin from '@/components/mixins/paginationMixin'
import NextSelect from '@/components/widgets/simple-components/select/NextSelect'
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import Documents from '@/components/widgets/custom-components/tabs/organization-tabs/Documents'
import NextTable from '@/components/widgets/simple-components/table/NextTable'
import TABLE_COLUMNS from './processesTableColumns'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'

export default {
  name: 'ProcessesPage',
  components: { NextAlert, NextTable, Paginate, dropdownListWithHeader, nextButton, Documents, NextSelect },
  mixins: [paginationMixin],
  data() {
    return {
      isLoading: false,
      error: false,
      activeTab: 'processes',
      tableData: [],
      columns: TABLE_COLUMNS,
      medtype: '',
      page: 1,
      perPage: 20,
      count: 0,
      documents: [],
      uploadOpened: false
    }
  },
  mounted() {
    this.fetch(1, { field: 'number', order: 'DESC' })
  },
  methods: {
    async uploadHandler(val) {
      val.append('type', 'process')
      let result = await this.$store.dispatch('DOCUMENTS_UPLOAD_FILE', val)
      if (result.data.ok === 1) {
        this.uploadOpened = false
      }
    },
    changeSort(sort) {
      this.fetch(this.page, sort)
    },
    changeMedtype(val) {
      this.medtype = val || ''
      this.fetch(1)
    },
    async fetch(page = this.page, sort) {
      try {
        this.isLoading = true
        this.error = null
        this.page = page

        const result = await this.$api.get('process/get-signed-processes', {
          params: {
            medtype: this.medtype,
            offset: this.perPage * (this.page - 1),
            limit: this.perPage,
            sort_type: sort?.field || 'number',
            sort_direction: sort?.order?.toLowerCase() || 'desc'
          }
        })

        if (result.data.ok === 1) {
          this.tableData = result.data.data
          this.count = result.data.count
        } else {
          this.error = result.data.message
        }
      } catch (e) {
        this.error = e.message
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
.content {
  padding: 40px;
  background-color: white;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
  border-radius: 3px;
}
</style>
