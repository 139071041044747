<template>
  <div class="document-info">
    <div class="document-header">Данные о файле:</div>
    <inputWithHeader :start-text="file.name" style="padding-bottom: 25px" @inputHandler="setName" header="Название документа" :required="true" type="text" placeholder="Введите название документа"></inputWithHeader>
    <dropdownListWithHeader :startItem="{id: file.category_id, text: file.text}"
                            :search="false"
                            :required="true"
                            placeholder="Выберите категорию" header="Категория документа"
                            :autoclose="true" :list="$store.getters.documentsCategory"
                            @selectHandler="selectCategory" style="padding-bottom: 12px"></dropdownListWithHeader>
    <div class="document-text"  style="padding-bottom: 15px"><span>*</span> Поля отмеченные звёздочкой являются обязательными</div>
    <div class="content-buttons" v-if="file !== ''">
      <nextButton type="next" color="green" text="СОХРАНИТЬ" @clickHandler="changeDocument" v-if="name !== '' && category !== ''"></nextButton>
      <nextButton type="next" color="green" class="inactive" text="СОХРАНИТЬ" v-else></nextButton>
      <nextButton type="close" color="white" text="ОТМЕНИТЬ" @clickHandler="close"></nextButton>
    </div>
  </div>
</template>

<script>
import inputWithHeader from "@/components/widgets/simple-components/inputs/inputWithHeader";
import dropdownListWithHeader from "@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader";
import nextButton from "@/components/widgets/simple-components/buttons/nextButton";
export default {
  name: "editDocument",
  components: {inputWithHeader, dropdownListWithHeader, nextButton},
  data() {
    return {
      name: '',
      category: '',
    }
  },
  props: {
    file: {
      type: Object,
      required: true,
    }
  },
  mounted() {
    this.name = this.file.name;
    this.category = {id: this.file.category_id, text: this.file.text};
    this.$store.dispatch('DOCUMENTS_REFRESH_ALL_CATEGORIES')
  },
  methods: {
    close() {
      this.$parent.closeHandler();
    },
    async changeDocument() {
     let result = await this.$store.dispatch('DOCUMENTS_CHANGE_INFO', {document: this.file, name: this.name, category_id: this.category.id})
     if(result.data.ok === 1) {
       this.$parent.closeHandler();
     }
    },
    setName(val) {
      this.name = val;
    },
    selectCategory(val){
      this.category = val;
    }
  }
}
</script>

<style scoped>
.document-header {
  font-weight: 400;
  font-size: 23px;
  line-height: 31px;
  padding-bottom: 20px;
  color: #333333;
}
.document-info {
  padding: 20px;
}
.content-buttons {
  display: flex;
  justify-content: space-between;
}
</style>