<template>
  <div class="card card-body h-100">
    <h4 class="card-title mb-4">Статистика, %</h4>
    <div class="h-100">
      <NextAlert
        v-if="isError && !isLoading"
        type="error"
        title="Произошла ошибка."
        :text="isError"
      />
      <div class="row">
        <div v-for="item in widgetData" :key="item.title" class="col d-flex flex-column align-items-center mb-4">
          <h5 class="text-center font-weight-normal mb-3">{{ item.title }}</h5>
          <SpinLoader v-if="isLoading" size="xl" style="height: 200px" />
          <DonutChart v-else :values="item.values" size="200" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import DonutChart from '@/components/widgets/custom-components/DonutChart/DonutChart'

export default {
  name: 'StatisticWidget',
  components: { DonutChart, NextAlert, SpinLoader },
  data() {
    return {
      isLoading: true,
      isError: false,
      widgetData: [
        {
          title: 'Требования',
          values: [
            {
              value: 0,
              color: '#37c4a3',
              bolder: true
            },
            {
              value: 0,
              color: '#d65959'
            },
            {
              value: 0,
              color: '#cbcbcb'
            }
          ]
        }
      ]
    }
  },
  mounted() {
    this.fetchAll()
  },
  methods: {
    fetchAll() {
      this.isLoading = true
      this.isError = false

      return Promise
        .all([this.fetchProcesses()])
        .catch((e) => {
          this.isError = e.message
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async fetchProcesses() {
      try {
        const res = await this.$api.get(`/process/formatted`, {
          params: {
            medtype_id: ''
          }
        })

        if (res.data.ok === 1) {
          const processes = res.data.data

          if (Array.isArray(processes)) {
            const { all, checked, passed, unpassed } = processes.reduce((p, c) => {
              p.all += c?.requirements_count?.all || 0
              p.checked += c?.requirements_count?.checked || 0
              p.passed += c?.requirements_count?.passed || 0
              p.unpassed += c?.requirements_count?.unpassed || 0

              return p
            }, { all: 0, checked: 0, passed: 0, unpassed: 0 })

            this.widgetData[0].values = [
              {
                value: this.$helper.toPercent(all, passed),
                color: '#37c4a3',
                bolder: true
              },
              {
                value: this.$helper.toPercent(all, unpassed),
                color: '#d65959'
              },
              {
                value: 100 - this.$helper.toPercent(all, checked),
                color: '#cbcbcb'
              }
            ]
          }
        } else {
          throw new Error(res.data.message)
        }
      } catch (e) {
        console.error(e)
        throw new Error(e.message)
      }
    }
  }
}
</script>
