<template>
  <span class="status-icon" :class="statusClass" :style="styles" />
</template>

<script>
export default {
  name: 'StatusIcon',
  props: {
    status: {
      type: [String, Number],
      default: 2
    },
    size: {
      type: [Number, String],
      default: '1.25rem'
    }
  },
  computed: {
    statusClass() {
      let status = this.status

      if (typeof this.status === 'number') {
        switch (this.status) {
          case 1:
            status = 'neutral'
            break
          case 2:
            status = 'success'
            break
          case 3:
            status = 'error'
            break
          default:
            status = 'failed'
        }
      }

      return `status-icon_${status}`
    },
    iconSize() {
      return (
        {
          sm: '16px',
          md: '20px',
          lg: '24px',
          xl: '32px',
          '2xl': '40px',
          '3xl': '48px'
        }[this.size] || (isNaN(Number(this.size)) ? this.size : this.size + 'px')
      )
    },
    styles() {
      return {
        width: this.iconSize,
        height: this.iconSize,
        flexBasis: this.iconSize,
        minWidth: this.iconSize
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.status-icon {
  display: inline-block;
  border-radius: 50%;

  &_success {
    background-color: #37c4a3;
  }

  &_error {
    background-color: #d65959;
  }

  &_neutral {
    background-color: #666666;
  }

  &_failed {
    background: url('data:image/svg+xml,%3Csvg width=%2721%27 height=%2720%27 viewBox=%270 0 21 20%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cg clip-path=%27url%28%23clip0_1239_9110%29%27%3E%3Cpath fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M10.5001 11.0001L0.500075 21.0001L-0.207031 20.293L9.79297 10.293L0.500075 1.00008L1.20718 0.292969L10.5001 9.58586L19.793 0.292969L20.5001 1.00008L11.2072 10.293L21.2072 20.293L20.5001 21.0001L10.5001 11.0001Z%27 fill=%27%23666666%27/%3E%3C/g%3E%3Cpath d=%27M10.5 19C5.52944 19 1.5 14.9706 1.5 10H-0.5C-0.5 16.0751 4.42487 21 10.5 21V19ZM19.5 10C19.5 14.9706 15.4706 19 10.5 19V21C16.5751 21 21.5 16.0751 21.5 10H19.5ZM10.5 1C15.4706 1 19.5 5.02944 19.5 10H21.5C21.5 3.92487 16.5751 -1 10.5 -1V1ZM10.5 -1C4.42487 -1 -0.5 3.92487 -0.5 10H1.5C1.5 5.02944 5.52944 1 10.5 1V-1Z%27 fill=%27%23666666%27/%3E%3Cdefs%3E%3CclipPath id=%27clip0_1239_9110%27%3E%3Cpath d=%27M0.5 10C0.5 4.47715 4.97715 0 10.5 0C16.0228 0 20.5 4.47715 20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.5 15.5228 0.5 10Z%27 fill=%27white%27/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A')
      no-repeat center;
  }
}
</style>
