<template>
  <div class="d-flex align-items-start justify-content-around">
    <EventsCalendarMonth
      v-for="({ month, year }, key) in position"
      :key="month + year"
      :class="{ 'mr-3': key !== 2 }"
      :month="month"
      :year="year"
    />
  </div>
</template>

<script>
import EventsCalendarMonth from '@/components/widgets/custom-components/EventsCalendar/EventsCalendarMonth'

export default {
  components: { EventsCalendarMonth },
  data() {
    return {
      centerPosition: [new Date().getMonth(), new Date().getFullYear()],
      position: []
    }
  },
  watch: {
    centerPosition: {
      deep: true,
      immediate: true,
      handler(v) {
        this.setPosition(...v)
      }
    }
  },
  methods: {
    setPosition(month, year) {
      this.position = ['prev', 'current', 'next'].map(i => {
        if (i === 'prev') {
          const prevMonth = month === 0 ? 11 : month - 1
          return {
            month: prevMonth,
            year: prevMonth > month ? year - 1 : year
          }
        } else if (i === 'current') {
          return { month, year }
        }

        const nextMonth = month === 11 ? 0 : month + 1
        return {
          month: nextMonth,
          year: nextMonth < month ? year + 1 : year
        }
      })
    },
    prev() {
      if (this.centerPosition[0] - 1 < 0) {
        this.centerPosition = [11, this.centerPosition[1] - 1]
      } else {
        this.centerPosition[0]--
      }
    },
    next() {
      if (this.centerPosition[0] + 1 > 11) {
        this.centerPosition = [0, this.centerPosition[1] + 1]
      } else {
        this.centerPosition[0]++
      }
    },
    setToday() {
      this.centerPosition = [new Date().getMonth(), new Date().getFullYear()]
    }
  }
}
</script>