<template>
  <table v-if="!isEmpty(data)" class="pdf-table">
    <thead class="pdf-table__thead">
      <tr>
        <th v-for="{ key, title, width } in columns" :key="key" :width="width">{{ title }}</th>
      </tr>
    </thead>
    <tbody class="pdf-table__tbody">
      <tr v-for="(row, rowIdx) in data" :key="`row_${rowIdx}`">
        <td v-for="{ key } in columns" :key="`col_${rowIdx}_${key}`">{{ row[key] }}</td>
      </tr>
    </tbody>
    <slot name="tfoot" />
  </table>
</template>

<script>
import { isEmpty } from '@/helper'

export default {
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    isEmpty: isEmpty
  }
}
</script>

<style lang="scss">
$theadBg: #ececec;
$borderColor: #e0e0e0;
$evenRowBg: #f2f2f2;

.pdf-table {
  width: 100%;
  font-size: 1rem;
  //border: 1px solid $borderColor;

  td,
  th {
    border-left: 1px solid $borderColor;
    vertical-align: top;

    &:last-child {
      border-right: 1px solid $borderColor;
    }
  }

  &__thead {
    background-color: $theadBg;

    th {
      padding: 0.75rem;
      text-align: center;
      font-weight: bold;
      border-top: 1px solid $borderColor;
    }
  }

  &__tbody {
    tr {
      &:last-child {
        td {
          border-bottom: 1px solid $borderColor;
        }
      }

      &:nth-child(even) {
        td {
          background-color: $evenRowBg;
        }
      }
    }

    td {
      padding: 0.75rem;
    }
  }
}
</style>
