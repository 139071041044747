import api from '@/api'

const vkk = {
  state: {
    indicators: []
  },
  getters: {
    indicators(state) {
      return state.indicators
    }
  },
  mutations: {
    vkk_update_indicators(state, payload) {
      state.indicators = payload
    }
  },
  actions: {
    VKK_CREATE_INDICATOR(context, payload) {
      return api
        .post('vkk/create-organization-indicator', {
          organization_id: payload.organization_id,
          indicator_id: payload.indicator_id
        })
        .then((res) => {
          context.commit('organization_create_organization_indicator', res.data.data)
        })
    },
    VKK_REMOVE_INDICATOR(context, payload) {
      return api
        .post('vkk/remove-organization-indicator', {
          indicator_id: payload.indicator_id,
          organization_id: payload.organization_id
        })
        .then(() => {
          context.commit('organization_remove_organization_indicator', payload)
        })
    },
    VKK_UPDATE_INDICATORS(context) {
      api.get('vkk/get-all-indicators').then((res) => {
        context.commit('vkk_update_indicators', res.data.data)
      })
    }
  }
}
export default vkk
