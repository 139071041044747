<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useDialog } from 'naive-ui'
import { isEmpty } from 'lodash'
import MainTab from '@/components/pages/Actions/actionTabs/mainTab'
import Documents from '@/components/widgets/custom-components/tabs/organization-tabs/Documents'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import SelectProcessModal from '@/components/pages/Actions/actionComponents/selectProcessModal'
import { useEventsStore } from '@/store/events'

const route = useRoute()
const dialog = useDialog()
const eventsStore = useEventsStore()
const { isLoading, isError, item: event } = storeToRefs(eventsStore)

const selectProcessModalRef = ref(null)

const currentTab = ref('main')

const eventId = computed(() => route.params.id)
const allowEdit = computed(() => ![5, 6].includes(event.value?.action?.status_id))
const statusBtns = computed(() => event.value?.status?.buttons)
const topStatusBtns = computed(() =>
  !isEmpty(statusBtns.value)
    ? Object.keys(statusBtns.value)
        .filter((key) => key !== 'cancel_btn')
        .map((key) => statusBtns.value[key])
    : []
)

onMounted(() => {
  eventsStore.fetchOne(eventId.value)
})

function changeTab(tab = 'main') {
  currentTab.value = tab
}

function deleteHandler() {
  const d = dialog.error({
    title: 'Подтвердите действие',
    content: 'Вы действительно хотите удалить этот объект?',
    positiveText: 'Удалить',
    negativeText: 'Отмена',
    onPositiveClick: () => {
      d.loading = true
      return eventsStore.deleteById(eventId.value)
    }
  })
}

function changeStatusHandler(btn) {
  if (
    !event.value.checklist &&
    (!event.value?.action?.process_id || !event.value?.action?.action_category_id) &&
    event.value.action.status_id === 4 &&
    btn.name !== 'Доработать' &&
    btn.name !== 'Отмена'
  ) {
    selectProcessModalRef.value?.openModal(event.value.action, ({ med_type_id, process_id, action_category_id }) =>
      eventsStore
        .update(
          {
            ...event.value.action,
            med_type_id,
            process_id,
            action_category_id
          },
          false
        )
        .then(() => {
          eventsStore.updateStatus(eventId.value, btn.status_id)
        })
    )
  } else {
    const d = dialog.warning({
      title: 'Подтвердите действие',
      content: 'Вы действительно хотите изменить статус мероприятия?',
      positiveText: 'Изменить статус',
      negativeText: 'Отмена',
      onPositiveClick: async () => {
        d.loading = true
        try {
          await eventsStore.updateStatus(eventId.value, btn.status_id)
        } catch (e) {
          console.log(e)
        }
      }
    })
  }
}
</script>

<template>
  <SpinLoader v-if="isLoading && !isError" class="py-5" size="2xl" />
  <template v-else-if="!isLoading && !isError && event">
    <div class="page-header">
      <div class="page-header__left">
        <div class="page-header__text">
          <div class="page-header__heading">Мероприятие #{{ eventId }}</div>
          <div class="page-header__second-heading">{{ event?.medtype?.text }}</div>
        </div>
      </div>
      <div class="page-header__right">
        <div class="page-header__controls">
          <template v-if="statusBtns">
            <NextButton
              v-for="(btn, key) in topStatusBtns"
              :key="key"
              :text="btn.name"
              type=""
              @click="changeStatusHandler(btn)"
            />
          </template>
          <NextButton
            v-if="allowEdit"
            :to="`/actions/${eventId}/edit`"
            type="edit"
            text="Редактировать"
            color="white"
          />
          <NextButton
            v-if="event?.action?.status_id === 1"
            type="close"
            text="Удалить"
            color="red"
            @click="deleteHandler"
          />
        </div>
      </div>
    </div>
    <div class="tabs">
      <div class="tab pointer" :class="{ active: currentTab === 'main' }" @click="changeTab('main')">
        Общая информация
      </div>
      <div class="tab pointer" :class="{ active: currentTab === 'documents' }" @click="changeTab('documents')">
        Документы
      </div>
    </div>
    <div class="tab-content">
      <MainTab v-if="currentTab === 'main'" :data="event" />
      <Documents v-else-if="currentTab === 'documents'" type="action" :document-id="event?.action?.id" />
    </div>
    <NextButton
      v-if="statusBtns?.cancel_btn"
      class="mt-4"
      color="red"
      type=""
      :text="statusBtns?.cancel_btn?.name"
      @click="changeStatusHandler(statusBtns?.cancel_btn)"
    />
  </template>
  <NextAlert v-if="isError && !isLoading" type="error" :text="isError" />
  <SelectProcessModal ref="selectProcessModalRef" />
</template>
