import api from '@/api'

const endpoint = 'control/'

const process = {
  state: {
    currentControl: {},
    controlDocuments: []
  },
  getters: {
    controlDocuments(state) {
      return state.controlDocuments
    }
  },
  mutations: {
    control_create(state, payload) {
      state.currentControl = payload
    }
  },
  actions: {
    CONTROL_GET_DOCUMENTS(context, payload) {
      return api.get(endpoint + 'get-documents', {
        params: {
          id: payload.id,
          limit: payload.limit,
          offset: payload.offset,
          category_id: payload.category_id,
          sort_type: payload.sort.type,
          sort_direction: payload.sort.direction
        }
      })
    },
    CONTROL_CREATE(context, payload) {
      context.commit('control_create', payload)
    }
  }
}
export default process
