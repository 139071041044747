import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useMessage, useNotification } from 'naive-ui'
import api from '@/api'

export const useOrganizationStore = defineStore('organization', () => {
  const endpoint = 'organization/'

  const notify = useNotification()
  const message = useMessage()

  const isLoading = ref(false)
  const isDeletingDocument = ref(false)
  const isError = ref(false)
  const error = ref(null)
  const organization = ref(JSON.parse(localStorage.getItem('user'))?.organization || null)
  const documents = ref([])

  const getDocuments = async (params) => {
    try {
      isLoading.value = true
      isError.value = false
      error.value = null

      const res = await api.get(endpoint + 'get-documents', { params })

      if (res.data.ok === 1) {
        documents.value = res.data.data
        return res
      }

      throw new Error(res.data.message)
    } catch (e) {
      isError.value = true
      error.value = e?.response?.data?.message || e.message
      throw e
    } finally {
      isLoading.value = false
    }
  }

  const deleteDocument = async (payload) => {
    try {
      isDeletingDocument.value = true

      const res = await api.post(endpoint + 'delete-document-by-id', payload)

      if (res.data.ok === 1) {
        documents.value = documents.value.filter((item) => item.id !== payload.document_id)
      }

      message.success('Документ удален')

      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось удалить документ',
        duration: 2500
      })
    } finally {
      isDeletingDocument.value = false
    }
  }

  return {
    isLoading,
    isError,
    error,
    isDeletingDocument,
    organization,
    documents,
    // actions
    getDocuments,
    deleteDocument
  }
})
