import api from '@/api'

const endpoint = 'process/'

const process = {
  state: {
    medTypes: [],
    medOrgs: [],
    processes: [],
    processDocuments: []
  },
  getters: {
    allMedTYpes(state) {
      return state.medTypes
    },
    processDocuments(state) {
      return state.processDocuments
    }
  },
  mutations: {
    process_update_all_processes(state, payload) {
      state.processes = payload
    },
    process_update_all_medtypes(state, payload) {
      state.medTypes = payload
    },
    process_refresh_documents(state, payload) {
      state.processDocuments = payload
    }
  },
  actions: {
    PROCESS_DOCUMENTS_GET_ALL(context, payload) {
      return api.get(endpoint + 'get-documents', {
        params: {
          id: payload.id,
          offset: payload.offset,
          limit: payload.limit,
          category_id: payload.category_id,
          sort_type: payload.sort.type,
          sort_direction: payload.sort.direction
        }
      })
    },
    async PROCESS_REMOVE_ORGANIZATION_PROCESS(context, payload) {
      return await api
        .put(endpoint + 'remove-organization-process', {
          process_id: payload.process_id,
          organization_id: payload.organization_id
        })
        .then((res) => {
          if (res.data.ok === 1) {
            context.commit('organization_remove_organization_process', payload.process_id)
          }
        })
    },
    async PROCESS_CREATE_ORGANIZATION_PROCESS(context, payload) {
      return await api
        .put(endpoint + 'create-organization-process', {
          process_id: payload.process_id,
          organization_id: payload.organization_id
        })
        .then((res) => {
          if (res.data.ok === 1) {
            context.commit('organization_add_organization_process', res.data.data)
          }
        })
    },
    PROCESS_UPDATE_ALL_PROCESSES(context) {
      api.get(endpoint + 'get-all-processes').then((res) => {
        if (res.data.ok == 1) {
          context.commit('process_update_all_processes', res.data.data)
        }
      })
    },
    PROCESS_UPDATE_ALL_MEDTYPES(context) {
      api.get(endpoint + 'get-all-med-types').then((res) => {
        if (res.data.ok == 1) {
          context.commit('process_update_all_medtypes', res.data.data)
        }
      })
    }
  }
}
export default process
