<template>
  <div class="card card-body">
    <div class="d-flex align-items-center mb-4">
      <h4 class="card-title mr-5 mb-0">Результативность мероприятий</h4>
      <NextSelect
        v-model="medtype"
        class="w-72"
        request="/organization/get-organization-medtypes"
        placeholder="Направления"
        clearable
        @change="fetchProcesses"
      />
    </div>
    <NextAlert v-if="isError" type="error" title="Произошла ошибка." :text="isError" />
    <NextTable :columns="columns" :table-data="widgetData" :loading="isLoading" striped>
      <template #text="{ row, value }">
        <RouterLink :to="`/process/${row.id}`" class="text-gray-800">{{ value }}</RouterLink>
      </template>
    </NextTable>
  </div>
</template>

<script>
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import DonutChart from '@/components/widgets/custom-components/DonutChart/DonutChart'
import NextSelect from '@/components/widgets/simple-components/select/NextSelect'
import NextIcon from '@/components/widgets/simple-components/icon'
import NextTable from '@/components/widgets/simple-components/table/NextTable'

export default {
  name: 'TableWidget',
  components: { NextTable, NextIcon, NextSelect, DonutChart, NextAlert, SpinLoader },
  data() {
    return {
      isLoading: true,
      isError: false,
      medtype: null,
      widgetData: [],
      columns: [
        {
          key: 'text',
          title: 'Процесс',
          width: '30%'
        },
        {
          key: 'requirements',
          title: 'Применимых пунктов требований',
          center: true
        },
        {
          key: 'passed',
          title: 'Соответствий пунктам',
          center: true
        },
        {
          key: 'passedPart',
          title: 'Доля соответствий',
          center: true
        },
        {
          key: 'unpassed',
          title: 'Несоответствий',
          center: true
        },
        {
          key: 'allEvents',
          title: 'Запланированных мероприятий',
          center: true
        },
        {
          key: 'completedEvents',
          title: 'Выполненных мероприятий',
          center: true
        },
        {
          key: 'resultEvents',
          title: 'Результативность мероприятий',
          center: true
        }
      ]
    }
  },
  mounted() {
    this.fetchProcesses()
  },
  methods: {
    async fetchProcesses() {
      try {
        this.isLoading = true
        this.isError = false

        const res = await this.$api.get(`/process/formatted`, {
          params: {
            medtype_id: this.medtype
          }
        })

        if (res.data.ok === 1) {
          this.widgetData =
            res.data?.data?.map((item) => ({
              id: item.id,
              text: item.text,
              requirements: item.requirements_count.all,
              passed: item.requirements_count.passed,
              passedPart:
                (this.$helper.toPercent(item.requirements_count.all, item.requirements_count.passed) || 0) + '%',
              unpassed: item.requirements_count.unpassed,
              allEvents: item.events_count.all,
              completedEvents: item.events_count.completed,
              resultEvents: (this.$helper.toPercent(item.events_count.all, item.events_count.completed) || 0) + '%'
            })) || []
        } else {
          this.isError = res.data.message
        }
      } catch (e) {
        console.error(e)
        this.isError = e.message
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
