<template>
  <div class="content-block">
    <div class="content-header">
      Создание проверки
      <div class="content-manage">
        <div class="checkbox-container">
          <checkbox
            label="Все со статусом «Не соответствует»"
            :start-active="showNotApproved"
            @changeHandler="showNotApprovedToggle"
          />
        </div>
        <div class="select-header">Выбрано: {{ requirements.length }}</div>
        <div class="next-button">
          <nextButton v-if="checkSave" text="Создать проверку" @clickHandler="createCheckList" />
          <nextButton v-else text="СОЗДАТЬ ЧЕК-ЛИСТ" class="inactive" />
        </div>
        <nextButton color="white" text="Отменить" @clickHandler="goBack" />
      </div>
    </div>
    <div class="main-block">
      <div class="content-block">
        <div class="content-header">Процесс:</div>
        <div class="content-info">
          <dropdownListWithHeader
            placeholder="Выберите чек-лист"
            :list="organizationProcess"
            @inputHandler="searchOrganizationProcess"
            @enterHandler="searchOrganizationProcessEnter"
            @selectHandler="organizationProcessSelect"
          />
        </div>
        <div class="content-list">
          <div v-for="(item, key) in selectedProcesses" :key="key" class="med-item">
            <div class="med-item__text" :title="item.text">
              {{ item.text }}
            </div>
            <span class="icon red-christ-icon pointer" @click="removeOrganizationProcess(item)" />
          </div>
        </div>
      </div>
      <div class="extra-settings">
        <div class="settings-item">
          <dropdownListWithHeader
            v-if="auditors.length > 0"
            :start-item="auditor"
            header="Аудитор"
            :required="true"
            :search="false"
            :list="auditors"
            @selectHandler="changeAuditior"
          />
          <!--        <inputWithHeader header="Аудитор" :required="true" @inputHandler="inputAuditor"></inputWithHeader>-->
        </div>
        <div class="settings-item">
          <dropdownListWithHeader
            v-if="auditionType !== ''"
            :start-item="auditionType"
            header="Вид проверки"
            :required="true"
            :search="false"
            :list="auditionTypes"
            @selectHandler="changeAuditionType"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkbox from '@/components/widgets/simple-components/inputs/checkbox'
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
export default {
  name: 'CreateCheckListPage',
  components: {
    checkbox,
    dropdownListWithHeader,
    nextButton,
    inputWithHeader
  },
  data() {
    return {
      processText: '',
      auditors: [],
      auditionTypes: [],
      auditionType: '',
      auditor: '',
      selectedProcesses: [],
      showNotApproved: false,
      medType: Number.parseInt(this.$router.currentRoute.value.query?.medtype) || 0
    }
  },
  computed: {
    requirements() {
      return this.$store.getters['requirements']
    },
    selectedProcessesId() {
      return this.selectedProcesses.map((item) => item.id)
    },
    organizationProcessesId() {
      let result = []
      for (let i = 0; i < this.$store.getters['organizationProcesses'].length; i++) {
        let flag = false
        for (let n = 0; n < this.selectedProcesses.length; n++) {
          if (this.selectedProcesses[n].id === this.$store.getters['organizationProcesses'][i].process.id) {
            flag = true
          }
        }
        if (!flag) {
          result.push(this.$store.getters['organizationProcesses'][i].process.id)
        }
      }
      return result
    },
    organizationProcess() {
      let result = []
      for (let i = 0; i < this.$store.getters['organizationProcesses'].length; i++) {
        let flag = false
        for (let n = 0; n < this.selectedProcesses.length; n++) {
          if (this.selectedProcesses[n].id === this.$store.getters['organizationProcesses'][i].process.id) {
            flag = true
          }
        }
        if (!flag) {
          result.push(this.$store.getters['organizationProcesses'][i].process)
        }
      }
      return result
    },
    checkSave() {
      if (this.requirements.length === 0) {
        return false
      }
      if (this.auditionType === '') {
        return false
      }
      return true
    }
  },
  async mounted() {
    await this.$store.dispatch('ORGANIZATION_UPDATE_ORGANIZATION_PROCESSES_BY_MED_ID', this.medType)
    this.$api.get('responsible/get').then((res) => {
      if (res.data.ok === 1) {
        if (res.data.data.length > 0) {
          this.auditor = { text: res.data.data[0].name, id: res.data.data[0].id }
        }
        for (let i = 0; i < res.data.data.length; i++) {
          this.auditors.push({ text: res.data.data[i].name, id: res.data.data[i].id })
        }
      }
    })
    this.$store.dispatch('REQUIREMENT_TAKE_BY_MEDTYPE', this.medType)
    this.$store.dispatch('CHECKLIST_GET_AUDITION_TYPES').then((res) => {
      if (res.data.ok === 1) {
        this.auditionTypes = res.data.data
        this.auditionType = res.data.data[0]
      }
    })
  },
  methods: {
    changeAuditior(val) {
      this.auditor = val
    },
    changeAuditionType(val) {
      this.auditionType = val
    },
    goBack() {
      this.$router.back()
    },
    async createCheckList() {
      let res = await this.$store.dispatch('CHECKLIST_CREATE', {
        assurance_user_id: this.auditor.id,
        assurance_id: this.auditionType.id,
        requirements: this.$store.getters['requirements'].map((item) => item.id)
      })
      if (res.data.ok === 1) {
        this.$router.push('/checklist/' + res.data.data.number)
      }
    },
    removeOrganizationProcess(item) {
      this.selectedProcesses = this.selectedProcesses.filter((process) => process !== item)
      if (this.selectedProcesses.length === 0) {
        this.$store.dispatch('REQUIREMENT_TAKE_WITH_FILTERS', { processes: this.organizationProcessesId })
      } else {
        this.$store.dispatch('REQUIREMENT_TAKE_WITH_FILTERS', { processes: this.selectedProcessesId })
      }
    },
    searchOrganizationProcessEnter(val) {
      this.processText = val
    },
    searchOrganizationProcess(val) {
      this.processText = val
    },
    organizationProcessSelect(val) {
      this.selectedProcesses.push(val)
      this.$store.dispatch('REQUIREMENT_TAKE_WITH_FILTERS', { processes: this.selectedProcessesId })
    },
    async getMedTypes() {
      this.$store.dispatch('ORGANIZATION_UPDATE_ORGANIZATION_MEDTYPES')
    },
    showNotApprovedToggle(val) {
      this.showNotApproved = val
      if (val) {
        this.$store.dispatch('REQUIREMENT_REFRESH_DONT_MATCHES', this.medType)
      } else {
        this.$store.dispatch('REQUIREMENT_TAKE_WITH_FILTERS', { processes: this.selectedProcessesId })
      }
    }
  }
}
</script>

<style scoped>
.settings-item {
  min-width: 200px;
  padding-right: 20px;
}
.extra-settings {
  display: flex;
}
.input-block {
  width: 100%;
}
.content-list {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.content-info {
  position: relative;
  z-index: 2;
}
.content-manage {
  display: flex;
}
.red-christ-icon {
  position: absolute;
  right: 4px;
  top: 7px;
}
.med-item__text {
  white-space: nowrap; /* Текст не переносится */
  overflow: hidden; /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis;
  max-width: 300px;
  padding-right: 10px;
}
.med-item {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f2f2f2;
  max-width: 300px;
  position: relative;
  box-shadow: 1px 1px 0px #cccccc;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
.select-header {
  padding-left: 30px;
  padding-right: 60px;
}
.next-button {
  margin-right: 30px;
}
.main-block {
  background-color: white;
  padding: 23px 40px;
}
</style>
