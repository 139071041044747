<template>
  <div class="page-content">
    <nextButton
      class="special-button"
      type="plus"
      color="green"
      text="Добавить документ для требований"
      @clickHandler="openDocumentsUpload"
    />
    <modalWindow v-if="uploadOpened" header="Загрузка нового документа" @closeHandler="closeHandler">
      <uploadDocuments
        :process="process"
        :type="'requirement'"
        @closeHandler="uploadOpened = false"
        @uploadHandler="uploadHandler"
      />
    </modalWindow>
    <Documents v-if="!loading" :items="items" type="process" :document-id="process.id" />
  </div>
</template>

<script>
import Documents from '@/components/widgets/custom-components/tabs/organization-tabs/Documents'
import modalWindow from '@/components/widgets/custom-components/modalWindow/modalWindow'
import uploadDocuments from '@/components/widgets/custom-components/modalWindow/modalComponents/uploadDocuments'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'

export default {
  name: 'DocumentsTab',
  components: { Documents, modalWindow, uploadDocuments, nextButton },
  props: {
    process: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      items: [],
      loading: false,
      uploadOpened: false
    }
  },
  mounted() {
    this.loading = true
    this.$api
      .get('processes/get-files?id=' + this.process.id)
      .then((res) => {
        this.items = res.data.data
        this.loading = false
      })
      .catch((error) => {
        console.error(error)
        this.loading = false
      })
  },
  methods: {
    closeHandler() {
      this.uploadOpened = false
    },
    async uploadHandler(val) {
      val.append('type', 'requirement')
      let result = await this.$store.dispatch('DOCUMENTS_UPLOAD_FILE', val)
      if (result.data.ok === 1) {
        this.uploadOpened = false
      }
    },
    openDocumentsUpload() {
      this.uploadOpened = !this.uploadOpened
    }
  }
}
</script>
<style scoped>
.special-button {
  position: absolute;
  right: 420px;
  top: 0;
  height: 68px;
}
.page-content {
  min-width: 830px;
  position: relative;
}
</style>
