import { createRouter, createWebHistory } from 'vue-router'

import userRoutes from '@/router/userRoutes'
import guestRoutes from '@/router/guestRoutes'

const routes = [...guestRoutes, ...userRoutes]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

router.goBack = function () {
  window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
}

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/registration', '/confirm', '/registered', '/invite', '/restore']
  const adminPages = ['/admin']
  let authFlag = true

  for (let i = 0; i < publicPages.length; i++) {
    if (to.path.indexOf(publicPages[i]) === 0) {
      authFlag = false
    }
  }

  const authRequired = authFlag
  const user = JSON.parse(localStorage.getItem('user'))
  let adminFlag = false

  for (let i = 0; i < adminPages.length; i++) {
    if (to.path.indexOf(adminPages[i]) !== -1) {
      adminFlag = true
    }
  }

  if (adminFlag) {
    if (user.id === 1) {
      next()
      return
    } else {
      next('/main')
      return
    }
  }
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (user) {
    for (let i = 0; i < publicPages.length; i++) {
      if (to.path.toLowerCase() === publicPages[i]) {
        next('/statistic')
        return
      }
    }
  }

  if (authRequired && !user) {
    next('/login')
  } else {
    next()
  }
})
export default router
