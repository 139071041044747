<template>
  <div class="information-item">
    <div class="information-icon">
      <div class="icon" :class="icon" />
    </div>
    <div class="information-description">
      <div class="information-header text-secondary">{{ header }}:</div>
      <div class="information-text" :class="{ 'font-weight-bold': bold }">{{ text }}</div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrganizationInformationItem',
  props: {
    icon: String,
    header: String,
    text: String,
    bold: Boolean
  }
}
</script>

<style scoped>
.information-item {
  display: flex;
  width: 308px;
  max-width: 408px;
  margin-right: 30px;
}

.information-icon {
  width: 40px;
  height: 40px;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-image: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  background-position: center;
}

.information-description {
  padding-left: 20px;
}

.information-text {
  font-size: 16px;
  line-height: 1.2;
  color: #333333;
}

.information-header {
  font-size: 14px;
  padding-bottom: 4px;
  line-height: 12px;
  color: #666666;
}
</style>
