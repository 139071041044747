import { ref } from 'vue'
import { defineStore } from 'pinia'
import api from '@/api'
import _ from 'lodash'

export const useVideosStore = defineStore('videos', () => {
  const endpoint = 'videos/'
  const isLoading = ref(true)
  const isFetching = ref(false)
  const isError = ref(false)
  const error = ref(null)
  const items = ref([])
  const pagination = ref({
    total: 0,
    totalPages: 0,
    pageSize: 20,
    current: 1
  })

  const getAll = async (params) => {
    try {
      if (_.isEmpty(items.value)) {
        isLoading.value = true
      }

      isFetching.value = true
      isError.value = false
      error.value = null

      const res = await api.get(endpoint + 'get', {
        params: {
          page: pagination.value.current,
          page_size: pagination.value.pageSize,
          ...params
        }
      })

      items.value = res.data.data
      pagination.value.total = res.data.total
      pagination.value.totalPages = Math.ceil(res.data.total / pagination.value.pageSize)

      if (params?.page) {
        pagination.value.current = params.page
      }

      return res
    } catch (e) {
      console.log(e)
      isError.value = true
      error.value = e?.response?.data?.message || e.message
      throw e
    } finally {
      isLoading.value = false
      isFetching.value = false
    }
  }

  return {
    isLoading,
    isFetching,
    isError,
    error,
    items,
    pagination,
    getAll
  }
})
