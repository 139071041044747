<template>
  <OrgAccordion
    :horizontal="true"
    :selected-items="selectedItems"
    :can-select-all="true"
    :forever-opened="true"
    :items="medTypes"
    :header="'Типы медицинских организаций'"
    @itemClick="selectMedtype"
  />
</template>

<script>
import OrgAccordion from '@/components/widgets/custom-components/accordion/OrgAccordion'
export default {
  name: 'OrganizationTab',
  components: { OrgAccordion },
  data() {
    return {
      selectedItems: [],
      medTypes: []
    }
  },
  mounted() {
    this.getMedtypes()
  },
  methods: {
    selectMedtype(item) {
      item.pause = false
      let index = this.selectedItems.indexOf(item)
      if (index === -1) {
        if (item.active) {
          this.selectedItems.push(item)
        }
      } else {
        if (!item.active) {
          this.selectedItems.splice(index, 1)
        }
      }
      this.$emit('updateSelected', this.selectedItems)
    },
    getMedtypes() {
      this.$api.get('organization/' + 'get-organization-medtypes').then((res) => {
        if (res.data.ok === 1) {
          console.log(res.data.data)
          this.medTypes = res.data.data
        }
      })
    }
  }
}
</script>

<style scoped></style>
