<template>
  <div class="next-select" :class="classes">
    <div v-if="isOpen" class="next-select__backdrop" @click="close" />
    <div v-if="label" class="next-select__label">
      {{ label }}&nbsp;
      <span v-if="required" class="next-select__required-mark">*</span>
    </div>
    <div class="next-select__select--container">
      <div class="next-select__select" :class="{ clearable }" @click="open">
        <div class="next-select__content">
          {{ selectedContent }}
          <div v-if="placeholder && !selectedContent" class="next-select__placeholder">{{ placeholder }}</div>
        </div>
        <SpinLoader v-if="isLoading" class="next-select__spin" size="18" stroke="2" />
        <div v-else class="next-select__arrow" />
        <div v-if="clearable && !isLoading && localValue" class="next-select__clear" @click="clear">
          <NextIcon icon="x" size="6" />
        </div>
      </div>
      <div v-if="isOpen" class="next-select__dropdown">
        <div v-if="isEmpty" class="next-select__dropdown-empty">Нет данных</div>
        <template v-else>
          <div
            v-for="option in options || fetchedOptions"
            :key="get(option, valueProp)"
            class="next-select__dropdown-item"
            :class="{ selected: get(option, valueProp) === localValue }"
            @click="change(get(option, valueProp), option)"
          >
            {{ get(option, viewProp) }}
          </div>
        </template>
      </div>
    </div>
    <div v-if="isError" class="next-select__helper error">{{ isError }}</div>
  </div>
</template>

<script>
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import DropdownItem from '@/components/widgets/simple-components/inputs/dropdown/dropdownItem'
import NextIcon from '@/components/widgets/simple-components/icon'
import { get } from 'lodash'

export default {
  name: 'NextSelect',
  components: { NextIcon, DropdownItem, SpinLoader },
  props: {
    label: String,
    required: Boolean,
    disabled: Boolean,
    clearable: Boolean,
    request: [String, Object],
    modelValue: [String, Number],
    defaultValue: [String, Number, Object],
    placeholder: String,
    viewProp: {
      type: String,
      default: 'text'
    },
    valueProp: {
      type: String,
      default: 'id'
    },
    options: Array,
    exclude: Array,
    includeStart: Object,
    includeEnd: Object
  },
  emits: ['fetched', 'change', 'update:modelValue'],
  data() {
    return {
      localValue: null,
      isLoading: false,
      isError: '',
      isOpen: false,
      isDirty: false,
      fetchedOptions: null
    }
  },
  computed: {
    classes() {
      return {
        loading: this.isLoading,
        disabled: this.disabled || this.isLoading,
        open: this.isOpen
      }
    },
    selectedContent() {
      if (this.isDirty || !this.$helper.isObject(this.defaultValue)) {
        const selectedItem = (this.options || this.fetchedOptions)?.find(
          (item) => get(item, this.valueProp) === this.localValue
        )
        return selectedItem ? get(selectedItem, this.viewProp) : this.isLoading ? '' : undefined
      }

      return get(this.defaultValue, this.viewProp)
    },
    isEmpty() {
      return (!this.options && !this.fetchedOptions) || (!this.options?.length && !this.fetchedOptions?.length)
    }
  },
  watch: {
    request(v) {
      if (v) this.fetch()
    }
  },
  updated() {
    if (this.localValue !== this.modelValue) {
      this.setLocalValue()
    }
  },
  created() {
    this.setLocalValue()
  },
  mounted() {
    if (this.request && !this.options) this.fetch()
  },
  methods: {
    get: get,
    setLocalValue() {
      if (this.isDirty) {
        this.localValue = this.modelValue
      } else if (this.$helper.isObject(this.defaultValue)) {
        this.localValue = this.defaultValue?.[this.valueProp]
      } else {
        this.localValue = this.defaultValue || this.modelValue
      }
    },
    async fetch(request) {
      try {
        this.isLoading = true
        this.isError = ''

        const res = await this.$api.get(request || this.request)

        if (res.data.ok) {
          this.fetchedOptions = res.data.data.filter((item) => !this.exclude?.includes(item[this.valueProp]))

          if (this.includeStart) this.fetchedOptions.unshift(this.includeStart)
          if (this.includeEnd) this.fetchedOptions.push(this.includeEnd)

          this.$emit('fetched', this.fetchedOptions)

          if (!this.placeholder && !this.localValue) {
            this.localValue = this.fetchedOptions[0]?.[this.valueProp]
          }
        } else {
          this.isError = res.data.message
        }
      } catch (e) {
        this.isError = 'Не удалось загрузить данные'
        console.error(e.message)
      } finally {
        this.isLoading = false
      }
    },
    change(v, option) {
      this.isDirty = true
      this.localValue = v
      this.$emit('update:modelValue', v)
      this.$emit('change', v, option)
      this.close()
    },
    clear(e) {
      e.stopPropagation()
      this.change(this.defaultValue)
    },
    open() {
      if (this.isLoading || this.disabled) return
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    }
  }
}
</script>
