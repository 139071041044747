<template>
  <div class="alert" :class="`alert-${typeClass}`">
    <div v-if="title" class="h5">{{ title }}</div>
    <slot>{{ text }}</slot>
  </div>
</template>

<script>
export default {
  props: {
    text: [String, Boolean],
    title: String,
    type: {
      type: String,
      default: 'success'
    }
  },
  computed: {
    typeClass: (vm) => vm.type === 'error' ? 'danger' : vm.type
  }
}
</script>