import { ref } from 'vue'
import { useStore } from 'vuex'
import { useDocumentsStore } from '@/store/documents'
import { useOrganizationStore } from '@/store/organization'

const useDocuments = () => {
  const store = useStore()
  const documentsStore = useDocumentsStore()
  const organizationStore = useOrganizationStore()

  const isLoading = ref(false)

  const deleteDocument = async ({ type, file, organizationId }) => {
    if (file.protected && store.getters.isAdmin) {
      await documentsStore.deletePublic({
        process_id: organizationId || file.process_id,
        document_id: file.document_id || file.id,
        document_type: type
      })
    } else {
      await documentsStore.deleteByType({
        write_id: organizationId,
        document_id: file.document_id || file.id,
        type
      })
    }
  }

  const getOrganizationsFiles = async (params, page) => {
    try {
      isLoading.value = true
      await organizationStore.getDocuments(params)

      if (page === 1) {
        await documentsStore.getAllFiles({ ...params, type: 'organization' })
        return [...documentsStore.files, ...organizationStore.documents]
      }

      return organizationStore.documents
    } finally {
      isLoading.value = false
    }
  }

  return {
    isLoading,
    deleteDocument,
    getOrganizationsFiles
  }
}

export default useDocuments
