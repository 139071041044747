import RestorePage from '@/components/pages/RestorePassword/RestorePage'
import RegistrationPage from '@/components/pages/RegistrationPage'
import RegisterApply from '@/components/pages/RegisterApply'
import LoginPage from '@/components/pages/LoginPage'
import ConfirmPage from '@/components/pages/ConfirmPage'

export default [
  {
    path: '/restore',
    name: 'RestorePage',
    component: RestorePage,
    meta: { layout: 'GuestLayout' }
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationPage,
    meta: { layout: 'GuestLayout' }
  },
  {
    path: '/invite/:link',
    name: 'invite',
    component: RegistrationPage,
    meta: { layout: 'GuestLayout' }
  },
  {
    path: '/registered',
    name: 'registered',
    component: RegisterApply,
    meta: { layout: 'GuestLayout' }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: { layout: 'GuestLayout' }
  },
  {
    path: '/confirm',
    name: 'registrationConfirm',
    component: ConfirmPage,
    meta: { layout: 'GuestLayout' }
  }
]
