import { ref } from 'vue'
import { useNotification } from 'naive-ui'
import api from '@/api'

const useInvitation = () => {
  const notify = useNotification()
  const isLoading = ref(false)

  const sendInvitation = async (email) => {
    try {
      isLoading.value = true
      const res = await api.post('organization/send-invite', { email })

      if (res.data.ok === 1) {
        notify.success({
          title: 'Приглашение отправлено',
          content: 'Ссылка-приглашение отправлена на адрес ' + email,
          duration: 2500
        })
      } else {
        throw false
      }
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка',
        content: `Ссылка-приглашение не отправлена`,
        duration: 2500
      })
    } finally {
      isLoading.value = false
    }
  }

  return {
    isLoading,
    sendInvitation
  }
}

export default useInvitation
