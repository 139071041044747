<template>
  <div class="guest-layout">
    <div class="background__gradient">
      <div class="background__lines" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GuestLayout'
}
</script>

<style scoped>
.background__gradient {
  width: 100%;
  height: 100%;
  background-blend-mode: multiply, normal, normal;
  background: linear-gradient(180deg, #37c389 0%, #00cccc 100%), #f2f2f2;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

.background__lines {
  background: url('@/assets/images/background_lines.png');
  width: 100%;
  background-size: cover;
  opacity: 0.127;
  height: 100%;
}
.guest-layout {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
</style>
