<template>
  <div class="list-item">
    <div class="date-column list-column">{{ makeDate(new Date(item.time)) }}</div>
    <div class="assurance-column list-column">{{ item.assurance.text }}</div>
    <div class="status-column list-column">
      <div class="status-item icon" :class="'status-' + item.status_id +'-icon'"></div>
    </div>
    <div class="commentary-column list-column">{{ item.commentary }}</div>
    <div class="checklist-column list-column">
      <router-link :to="'/checklist/report/' + item.checklist_id">{{ item.checklist.number }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'historyListItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    makeDate(date) {
      if (date) {
        date = new Date(date)
        return this.$helper.makeDate(date)
      }
      return date
    }
  }
}
</script>

<style scoped>

.list-item {
  display: flex;
  font-size: 14px;
}

.status-item {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.list-column {
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  border-right: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.status-4-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1239_9110)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5001 11.0001L0.500075 21.0001L-0.207031 20.293L9.79297 10.293L0.500075 1.00008L1.20718 0.292969L10.5001 9.58586L19.793 0.292969L20.5001 1.00008L11.2072 10.293L21.2072 20.293L20.5001 21.0001L10.5001 11.0001Z' fill='%23666666'/%3E%3C/g%3E%3Cpath d='M10.5 19C5.52944 19 1.5 14.9706 1.5 10H-0.5C-0.5 16.0751 4.42487 21 10.5 21V19ZM19.5 10C19.5 14.9706 15.4706 19 10.5 19V21C16.5751 21 21.5 16.0751 21.5 10H19.5ZM10.5 1C15.4706 1 19.5 5.02944 19.5 10H21.5C21.5 3.92487 16.5751 -1 10.5 -1V1ZM10.5 -1C4.42487 -1 -0.5 3.92487 -0.5 10H1.5C1.5 5.02944 5.52944 1 10.5 1V-1Z' fill='%23666666'/%3E%3Cdefs%3E%3CclipPath id='clip0_1239_9110'%3E%3Cpath d='M0.5 10C0.5 4.47715 4.97715 0 10.5 0C16.0228 0 20.5 4.47715 20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.5 15.5228 0.5 10Z' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.status-3-icon {
  background-color: #D65959;
}

.status-2-icon {
  background: linear-gradient(180deg, #37C489 0%, #00CCCC 100%);
}

.status-1-icon {
  background-color: #666666;
}

.assurance-column {
  width: 160px;
  flex: none;
}

.date-column {
  width: 165px;
  flex: none;
  border-left: 1px solid #CCCCCC;
}

.checklist-column {
  white-space: nowrap;
  width: 160px;
  flex: none;
}

.commentary-column {
  width: 100%;
}

.status-column {
  width: 115px;
  flex: none;
}

.status-column {
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>