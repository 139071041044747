<template>
  <div class="page">
    <div class="page-header">Документация</div>
    <AllDocuments :startTab="tab"></AllDocuments>
  </div>
</template>

<script>
import AllDocuments from "./AllDocuments";
export default {
  name: "DocumentsPage",
  components: {AllDocuments},
  data() {
    return {

    }
  },
  computed: {
    tab() {
      return this.$route.params.tab || false;
    }
  },
  async mounted() {

  },
  methods: {
  }
}
</script>

<style scoped>
.tabs {
  display: flex;
}
.tab.active {
  border-color: #37C489;
}
.tab {
  cursor: pointer;
  padding-bottom: 15px;
  border-bottom: 3px solid transparent;
  margin-right: 30px;
  font-size: 18px;
  color: #333333;
}
.page-header {
  padding-bottom: 15px;
}
.page-subheader {
  padding-bottom: 30px;
}
.process-info {
  padding-bottom: 35px;
}
</style>