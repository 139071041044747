<template>
  <div class="notice">
    <div class="notice__icon">
      <SpinLoader v-if="status === 'loading'" size="sm" stroke="2" />
      <StatusIcon v-else :status="status" size="sm" />
    </div>
    <div class="notice__text">{{ text }}</div>
  </div>
</template>

<script>
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import StatusIcon from '@/components/widgets/simple-components/status-icon/StatusIcon'

export default {
  name: 'NextNotice',
  components: { StatusIcon, SpinLoader },
  props: {
    status: {
      type: String,
      default: 'success'
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.notice {
  position: fixed;
  top: 1rem;
  left: 50%;
  display: flex;
  align-items: center;
  max-width: 480px;
  padding: 1rem 1.25rem;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 6px 24px rgba(0, 0, 0, .15);
  transform: translateX(-50%);
  z-index: 100;

  &__icon {
    width: 1rem;
    height: 1rem;
    margin-right: .5rem;
  }

  &__text {
    font-size: 14px;
    line-height: 1.2;
  }
}
</style>