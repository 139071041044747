import api from '@/api'

const endpoint = 'documents/'

const documents = {
  state: {
    documentsCategory: [],
    allDocuments: []
  },
  getters: {
    allDocuments(state) {
      return state.allDocuments
    },
    documentsCategory(state) {
      return state.documentsCategory
    }
  },
  mutations: {
    documents_refresh_all_categories(state, payload) {
      state.documentsCategory = payload
    },
    documents_change_info(state, payload) {
      payload.document.name = payload.new_item.name
      payload.document.text = payload.new_item.text
      payload.document.category_id = payload.new_item.category_id
    },
    documents_set_list(state, payload) {
      state.allDocuments = payload
    }
  },
  actions: {
    DOCUMENTS_GET_ALL(context, payload) {
      return api.get(endpoint + 'get-all', {
        params: {
          offset: payload.offset,
          limit: payload.limit,
          category_id: payload.category_id,
          sort_type: payload.sort.type,
          sort_direction: payload.sort.direction,
          type: payload.type
        }
      })
    },
    DOCUMENTS_SET_LIST(context, payload) {
      context.commit('documents_set_list', payload)
    },
    async DOCUMENTS_DELETE_PUBLIC(context, payload) {
      return await api.post(endpoint + 'delete-public-document', payload)
    },
    async DOCUMENTS_DELETE_BY_TYPE(context, payload) {
      console.log(payload)
      if (payload.id === undefined) {
        return await api.post(endpoint + 'delete-document-by-type', {
          write_id: payload.write_id,
          document_id: payload.document_id,
          type: payload.type
        })
      } else {
        return await api.post(endpoint + 'delete-document-by-type', {
          document_id: payload.document_id,
          type: payload.type
        })
      }
    },
    async DOCUMENTS_DELETE(context, payload) {
      return await api.post(endpoint + 'delete-document-by-id', { id: payload.document_id })
    },
    async DOCUMENTS_UPLOAD_FILE(context, payload) {
      let result = await api.post(endpoint + 'upload-file', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return result
    },
    async DOCUMENTS_CHANGE_INFO(context, payload) {
      let result = await api.post(endpoint + 'change-organization-document-info', payload)
      if (result.data.ok === 1) {
        payload.new_item = result.data.data
        context.commit('documents_change_info', payload)
      }
      return result
    },
    async DOCUMENTS_REFRESH_ALL_CATEGORIES(context) {
      let result = await api.get(endpoint + 'get-all-categories')
      if (result.data.ok === 1) {
        context.commit('documents_refresh_all_categories', result.data.data)
      }
      return result
    }
  }
}
export default documents
