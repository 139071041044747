<script setup>
import { watch, ref, onMounted, h } from 'vue'
import { NSelect } from 'naive-ui'
import { get } from 'lodash'
import api from '@/api'
import { useFormItem } from 'naive-ui/es/_mixins'

const emit = defineEmits(['update:value', 'change', 'fetched'])
const props = defineProps({
  value: [String, Number],
  defaultValue: [String, Number],
  placeholder: String,
  disabled: Boolean,
  request: String,
  labelField: {
    type: String,
    default: 'text'
  },
  valueField: {
    type: String,
    default: 'id'
  },
  options: Array,
  exclude: Array,
  includeStart: Object,
  includeEnd: Object,
  renderLabel: Function,
  renderTag: Function,
  multirow: Boolean
})

const { nTriggerFormChange, nTriggerFormInput } = useFormItem(props)

defineExpose({ fetch })

const localValue = ref(props.defaultValue || props.value)
const isLoading = ref(false)
const error = ref(null)
const fetchedOptions = ref([])

watch(
  () => props.value,
  (v) => {
    localValue.value = v
  }
)
watch(
  () => props.request,
  (v) => {
    if (v) fetch()
    localValue.value = null
  }
)
watch(localValue, (value) => {
  emit('update:value', value)
  emit('change', value)
  nTriggerFormChange()
  nTriggerFormInput()
})

onMounted(() => {
  if (props.request && !props.options) {
    fetch()
  }
})

async function fetch(request) {
  try {
    isLoading.value = true
    error.value = null

    const { data } = await api.get(request || props.request)
    // Из-за разной структуры данных
    const items = (data?.data || data).filter((item) => !props.exclude?.includes(get(item, props.valueField)))

    if (props.includeStart) items.unshift(props.includeStart)
    if (props.includeEnd) items.push(props.includeEnd)

    fetchedOptions.value = items.map((item) => ({
      label: get(item, props.labelField),
      value: get(item, props.valueField)
    }))

    emit('fetched', fetchedOptions.value)

    if (!props.placeholder && !localValue.value) {
      localValue.value = get(fetchedOptions.value[0], props.valueField)
    }
  } catch (e) {
    error.value = 'Не удалось загрузить данные'
    console.error(e.message)
  } finally {
    isLoading.value = false
  }
}

function multiRowLabel(option) {
  return h('div', { class: 'whitespace-normal py-2' }, option.label)
}

function multiRowTag({ option }) {
  return h('div', { class: 'truncate' }, option.label)
}
</script>

<template>
  <NSelect
    v-model:value="localValue"
    :options="options || fetchedOptions"
    :loading="isLoading"
    :disabled="disabled || isLoading"
    :placeholder="placeholder"
    :render-label="multirow ? multiRowLabel : renderLabel"
    :render-tag="multirow ? multiRowTag : renderTag"
    :status="error ? 'error' : undefined"
  />
</template>
