<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header__left">
        <div class="page-header__heading">Мероприятия</div>
      </div>
    </div>
    <div class="content">
      <div class="d-flex justify-content-between mb-4">
        <div class="d-flex flex-wrap gap-3">
          <NextSelect
            v-model="status"
            class="w-80"
            request="action-statuses/get-all"
            placeholder="Статус мероприятия"
            :exclude="[1]"
            clearable
            @change="fetch()"
          />
          <NextSelect
            v-model="type"
            class="w-80"
            request="action-types/get-all"
            placeholder="Тип мероприятия"
            clearable
            @change="fetch()"
          />
          <NextSelect
            v-model="objectId"
            class="w-80"
            request="action/get-objects"
            placeholder="Объект учета"
            clearable
            @change="fetch()"
          />
        </div>
        <NButton type="primary" @click="$router.push('/actions/create')"> Создать мероприятие </NButton>
      </div>
      <template v-if="!error">
        <NextTable
          class="table-responsive-xl"
          :columns="columns"
          :table-data="tableData"
          :loading="isLoading"
          striped
          @change:sort="changeSort"
        >
          <template #id="{ value }">
            <RouterLink :to="`/actions/${value}`">{{ value }}</RouterLink>
          </template>
          <template #text="{ value, row }">
            <RouterLink :class="isDanger(row) ? 'text-danger' : '!text-gray-800'" :to="`/actions/${row.id}`">
              {{ value }}
            </RouterLink>
          </template>
          <template #plan_date="{ row, value }">
            <span :class="{ 'text-danger font-weight-bold': isExpired(row) }">{{ value }}</span>
          </template>
        </NextTable>
        <div v-if="count / perPage > 1" class="pagination-container">
          <Paginate
            :key="perPage"
            :page-count="Math.ceil(count / perPage)"
            :page-range="5"
            :margin-pages="2"
            :next-text="''"
            :prev-text="''"
            :click-handler="fetch"
          />
        </div>
      </template>
      <NextAlert v-else type="error" title="Произошла ошибка" :text="error" />
    </div>
  </div>
</template>

<script>
import ActionsList from '@/components/widgets/custom-components/lists/actionsList'
import Paginate from 'vuejs-paginate-next'
import paginationMixin from '@/components/mixins/paginationMixin'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextTable from '@/components/widgets/simple-components/table/NextTable'
import NextSelect from '@/components/widgets/simple-components/select/NextSelect'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import { NButton } from 'naive-ui'

export default {
  name: 'ActionsPage',
  components: { NextAlert, NextButton, NextSelect, NextTable, SpinLoader, ActionsList, Paginate, NButton },
  mixins: [paginationMixin],
  data() {
    return {
      page: 1,
      sort: { field: 'id', order: 'DESC' },
      isLoading: false,
      error: null,
      columns: [
        { key: 'id', title: 'ID', width: '80px', sortable: true, sortOrder: 'DESC' },
        { key: 'text', title: 'Мероприятие', width: '40%' },
        { key: 'type', title: 'Тип мероприятия', width: '148px', render: (row, item) => item.text },
        { key: 'object', title: 'Объект учёта', render: (row, item) => item.text },
        { key: 'responsible', title: 'Ответственный', render: (row, item) => item?.name },
        {
          key: 'plan_date',
          title: 'Плановая дата',
          width: '160px',
          render: (row, item) => this.$helper.makeDate(item)
        },
        {
          key: 'finish_date',
          title: 'Фактическая дата',
          width: '178px',
          render: (row, item) => this.$helper.makeDate(item)
        },
        { key: 'status', title: 'Статус', width: '160px', render: (row, item) => item.text }
      ],
      tableData: [],
      status: null,
      type: null,
      objectId: null
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    changeSort(sort) {
      this.sort = sort
      this.fetch(this.page)
    },
    async fetch(page = 1) {
      try {
        this.isLoading = true
        this.error = null
        this.page = page

        const params = {
          status: this.status,
          type: this.type,
          object_id: this.objectId,
          offset: (this.page - 1) * this.perPage,
          limit: this.perPage
        }

        if (this.sort?.field && this.sort?.order) {
          params.sort_type = this.sort.field
          params.sort_direction = this.sort.order.toLowerCase()
        }

        const res = await this.$api.get('/action/get-page', { params })

        if (res.data.ok === 1) {
          this.count = res.data.count
          this.tableData = res.data.data.map((item) => ({
            ...item,
            rowClass: this.isDanger(item) ? 'text-danger' : null
          }))
        } else {
          this.error = res.data.message
        }
      } catch (e) {
        this.error = e.message
      } finally {
        this.isLoading = false
      }
    },
    isExpired(row) {
      return ![5, 6].includes(row.status.id) && Date.now() - 86400000 > row.plan_date
    },
    isDanger(row) {
      return row.object_id === 4 && row.status_id !== 5 && row.status_id !== 6
    }
  }
}
</script>

<style scoped>
.content {
  padding: 40px;
  background-color: white;
  box-shadow: 0px 3px 9px rgb(0 0 0 / 13%);
  border-radius: 3px;
}
</style>
