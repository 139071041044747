<template>
  <div class="table-item table-row">
    <div class="functional-row table-row list-item">
      <div class="box-text pointer" @click="fullToggle">{{ item.text }}</div>
    </div>
    <div class="criteria-list" v-if="item.criteriaAssurances.length > 0">
      <div class="criteria-item table-row" :key="key" v-for="(item, key) in item.criteriaAssurances">
        <div class="row-text criteria-row list-item">
          <div class="box-text pointer" @click="fullToggle">{{ item.text }}</div>
        </div>
        <div class="requirement-list table-row" v-if="item.requirements.length > 0">
          <div class="requirement-item" :key="key" v-for="(item, key) in item.requirements">
            <div class="row-text requirement-row list-item">
              <div
                class="requirement-text req-text pointer"
                :class="{ 'font-weight-bold': item?.component_criteria_id === 1 }"
                @click="openFull"
              >
                <span
                  v-if="item?.component_criteria_id === 1"
                  class="text-danger font-weight-bold"
                  title="Обязательное требование"
                >
                  *
                </span>
                <router-link class="text-gray-800" :to="'/requirement/' + item.id">{{ item.text }}</router-link>
              </div>
            </div>
            <div class="requirement-id">
              <router-link :to="'/requirement/' + item.id">{{ item.base_id }}</router-link>
            </div>
            <div class="requirement-status">
              <div class="status-item" :class="'status-' + item.status_id"></div>
            </div>
          </div>
        </div>
        <div class="requirement-list table-row" v-else>
          <div class="requirement-item" v-if="item.requirements.length === 0">
            <div class="row-text requirement-row list-item">
              <div class="requirement-text">Нет требований</div>
            </div>
            <div class="requirement-id">-</div>
            <div class="requirement-status">-</div>
          </div>
        </div>
      </div>
    </div>
    <div class="criteria-list" v-else>
      <div class="criteria-item table-row">
        <div class="row-text criteria-row list-item">-</div>
        <div class="requirement-list table-row">
          <div class="requirement-item">
            <div class="row-text requirement-row list-item">Нет требований</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'processListItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    openFull(e) {
      if (e.target.classList.contains('requirement-text')) {
        e.target.classList.remove('requirement-text')
      } else {
        e.target.classList.add('requirement-text')
      }
    },
    fullToggle(e) {
      if (e.target.classList.contains('box-text')) {
        e.target.classList.remove('box-text')
      } else {
        e.target.classList.add('box-text')
      }
    }
  },
  mounted() {}
}
</script>

<style scoped>
.req-text {
  min-height: 40px;
}

.gray.requirement-item {
  background-color: #f2f2f2;
}

.requirement-text {
  height: 40px;
  overflow: hidden;
  margin: 0;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.box-text {
  max-height: 40px;
  overflow: hidden;
  margin: 0;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.no-items .functional-row {
  width: 100%;
}

.functional-row {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 180px;
  flex: none;
  padding-left: 10px;
  border: 1px solid #cccccc;
}

.criteria-list {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.list-item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.requirement-status {
  width: 95px;
  flex: none;
  padding-left: 10px;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  justify-content: center;
  border: 1px solid #cccccc;
}

.table-head .requirement-id {
  font-size: 16px;
}

.requirement-id {
  font-size: 14px;
  width: 75px;
  flex: none;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #cccccc;
}

.criteria-row {
  width: 180px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  flex: none;
  border: 1px solid #cccccc;
}

.requirement-row {
  width: 100%;
  min-width: 220px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  border: 1px solid #cccccc;
}

.requirement-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.requirement-item {
  display: flex;
  flex: auto;
}

.criteria-item {
  display: flex;
  flex: auto;
  width: 100%;
}

.table-item {
  display: flex;
}

.status-item {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.criteria-text {
  width: 100%;
}

.status-2 {
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
}

.status-3 {
  background: #d65959;
}

.status-4 {
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5001 11.0001L0.500075 21.0001L-0.207031 20.293L9.79297 10.293L0.500075 1.00008L1.20718 0.292969L10.5001 9.58586L19.793 0.292969L20.5001 1.00008L11.2072 10.293L21.2072 20.293L20.5001 21.0001L10.5001 11.0001Z' fill='%23666666'/%3E%3C/svg%3E%0A");
}

.status-1 {
  background-color: #666666;
}

.table-head {
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  color: white;
  width: 100%;
}

.table-item {
  display: flex;
}

.table-content .list-item {
  font-size: 14px;
}

.table {
  padding: 20px 40px;
  min-width: 830px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
}

.header {
  padding-bottom: 20px;
  font-size: 23px;
}
</style>
