import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useMessage, useNotification } from 'naive-ui'
import api from '@/api'

export const useEventsStore = defineStore('events', () => {
  const endpoint = 'action/'

  const message = useMessage()
  const notify = useNotification()

  const isLoading = ref(true)
  const isSaving = ref(false)
  const isFetching = ref(false)
  const isDeleting = ref(false)
  const isError = ref(false)
  const error = ref(null)
  const items = ref([])
  const item = ref(null)

  const fetchOne = async (id, showLoading = true) => {
    try {
      isLoading.value = showLoading
      isFetching.value = true
      isError.value = false
      error.value = null

      const res = await api.get(endpoint + 'get', { params: { id } })
      const statusRes = await getStatus(id)

      if (res.data.ok === 1) {
        item.value = { ...res.data.data, status: statusRes.data }
        return res
      }

      throw new Error(res.data.message)
    } catch (e) {
      isError.value = true
      error.value = e?.response?.data?.message || e.message
    } finally {
      isLoading.value = false
      isFetching.value = false
    }
  }

  const create = async (payload, showMessage = true) => {
    try {
      isSaving.value = true

      const res = await api.post(endpoint + 'save', payload)

      if (res.data.ok === 1) {
        if (showMessage) {
          message.success('Мероприятие создано')
        }
        return res
      }

      throw new Error(res.data.message)
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось создать мероприятие',
        duration: 2500
      })
      console.log(e)
    } finally {
      isSaving.value = false
    }
  }

  const update = async (payload, showMessage = true) => {
    try {
      isSaving.value = true

      const res = await api.post(endpoint + 'save', payload)

      if (res.data.ok === 1) {
        item.value = {
          ...item.value,
          action: payload,
          status: item.value.status
        }
        if (showMessage) {
          message.success('Мероприятие обновлено')
        }
        return res
      }
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось обновить мероприятие',
        duration: 2500
      })
    } finally {
      isSaving.value = false
    }
  }

  const deleteById = async (id) => {
    try {
      isDeleting.value = true

      const res = await api.post(endpoint + 'delete', { id })

      message.success('Мероприятие удалено')
      items.value = items.value.filter((item) => item.id !== id)
      item.value = null

      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось удалить мероприятие',
        duration: 2500
      })
    } finally {
      isDeleting.value = false
    }
  }

  const getStatus = async (id) => {
    try {
      const res = await api.get('/event-state-machine/status', { params: { id } })
      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось получить статус мероприятия',
        duration: 2500
      })
    }
  }

  const updateStatus = async (id, status_id) => {
    try {
      const res = await api.post(`event-state-machine/put?id=${id}`, { status_id })
      await fetchOne(id, false)
      message.success('Статус обновлен')

      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось изменить статус',
        duration: 2500
      })
    }
  }

  return {
    isLoading,
    isFetching,
    isDeleting,
    isError,
    error,
    items,
    item,
    fetchOne,
    create,
    update,
    deleteById,
    getStatus,
    updateStatus
  }
})
