import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})

instance.defaults.headers.common['Authorization'] = JSON.parse(localStorage.getItem('user'))?.auth_key || null
instance.defaults.headers.common['User'] = JSON.parse(localStorage.getItem('user'))?.id || null

export default instance
