<template>
  <div class="modal-window">
    <div class="content">
      <div class="header">
        <div class="left">
          <slot name="preheader" />
          <div class="header-text">{{ header }}</div>
        </div>
        <div class="header-close green-close-icon icon" @click="closeHandler" />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalWindow',
  props: {
    header: {
      type: String,
      default() {
        return ''
      }
    }
  },
  emits: ['closeHandler'],
  methods: {
    closeHandler() {
      this.$emit('closeHandler')
    }
  }
}
</script>

<style scoped>
.header-close {
  cursor: pointer;
}
.left {
  display: flex;
  align-items: center;
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  line-height: 31px;

  /* Black */

  color: #333333;
}
.content {
  max-height: calc(100vh - 20px);
  overflow-y: auto;
  min-width: 400px;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(119, 92, 92, 0.12);
  border-radius: 3px;
}
.header {
  background: url('@/assets/images/background_line.png'), #d5d5d547;
  background-size: cover;
  background-blend-mode: multiply, normal;
  display: flex;
  align-items: center;
  padding: 10px 20px 10px;
}
.modal-window {
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(97 97 97 / 58%);
  z-index: 9999999;
}
</style>
