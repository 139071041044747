<template>
  <div class="page-header">
    <div class="page-header__left">
      <div class="page-header__text">
        <div class="page-header__heading">Панель администратора</div>
      </div>
    </div>
  </div>
  <div class="tabs">
    <div class="tab" v-for="tab in tabs" :key="tab.id">{{tab.text}}</div>
  </div>
  <div class="content">
    <div class="content-block" v-if="activeTab.id === 'documents'">
      <fileManager :type="'admin'"/>
    </div>
  </div>
</template>

<script>
import fileManager from "@/components/widgets/custom-components/modalWindow/modalComponents/fileManager/fileManager";
export default {
  name: "AdminPage",
  components: {fileManager},
  data() {
    return {
      activeTab:  {text: 'Документы', id: 'documents'},
      tabs: [
        {text: 'Документы', id: 'documents'},
      ]
    }
  }
}
</script>

<style scoped>
.content {
  padding: 40px;
  background-color: white;
  box-shadow: 0px 3px 9px rgb(0 0 0 / 13%);
  border-radius: 3px;
}
</style>