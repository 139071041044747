<template>
  <div v-if="requirement.requirement" class="page">
    <div class="header">
      <div class="header__top">
        <div class="header__text">Требование №{{ requirement.requirement.base_id }}</div>
        <div class="header__medtype">{{ requirement.medtype.text }}</div>
      </div>
    </div>
    <div class="content">
      <div class="tabs" :class="{ 'active-first': currentTab === 'main' }">
        <div class="tab" :class="{ active: currentTab === 'main' }" @click="changeTab('main')">Общая информация</div>
        <div class="tab" :class="{ active: currentTab === 'history' }" @click="changeTab('history')">
          История проверок
        </div>
        <div class="tab" :class="{ active: currentTab === 'actions' }" @click="changeTab('actions')">Мероприятия</div>
        <div class="tab" :class="{ active: currentTab === 'documents' }" @click="changeTab('documents')">Документы</div>
      </div>
      <div class="tab-content">
        <SpinLoader v-if="isLoading" class="py-5" size="2xl" />
        <template v-else>
          <MainTab v-if="currentTab === 'main'" :requirement="requirement" />
          <HistoryTab v-if="currentTab === 'history'" />
          <DocumentsTab v-if="currentTab === 'documents'" :requirement="requirement" />
          <ActionsTab v-if="currentTab === 'actions'" :requirement="requirement" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MainTab from '@/components/pages/RequirementPage/requirementTabs/mainTab'
import HistoryTab from '@/components/pages/RequirementPage/requirementTabs/historyTab'
import DocumentsTab from '@/components/pages/RequirementPage/requirementTabs/documentsTab'
import ActionsTab from '@/components/pages/RequirementPage/requirementTabs/actionsTab'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'

export default {
  name: 'RequirementPage',
  components: { SpinLoader, HistoryTab, MainTab, DocumentsTab, ActionsTab },
  data() {
    return {
      isLoading: true,
      requirement: {},
      currentTab: 'main'
    }
  },
  mounted() {
    this.updateInformation()
  },
  methods: {
    async updateInformation() {
      this.isLoading = true

      const res = await this.$api.get(`/requirements/get?id=${this.$route.params.id}`)

      if (res.data.ok === 1) {
        this.requirement = res.data.data
      }

      this.isLoading = false
    },
    changeTab(name) {
      this.currentTab = name
      if (name !== 'documents') {
        this.updateInformation()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 2.5rem;

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  &__text {
    margin-right: 2rem;
    font-weight: 400;
    font-size: 36px;
    line-height: 49px;
    color: #333333;
  }

  &__medtype {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__subheader {
    font-weight: 400;
    font-size: 20px;
    color: #666666;
  }
}
</style>
