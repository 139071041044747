<template>
  <div>
    <div class="max-w-84 mb-4">
      <NextSelect
        v-model="responsibleId"
        request="responsible/get"
        view-prop="name"
        label="Ответственный"
        placeholder="Ответственный"
        @change="(id, user) => (responsibleUser = user)"
      />
    </div>
    <NextButton text="Сохранить" :loading="isLoading" :disabled="!responsibleId" @click="save" />
  </div>
</template>

<script>
import NextSelect from '@/components/widgets/simple-components/select/NextSelect'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'

export default {
  components: { NextButton, NextSelect },
  props: {
    process: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      responsibleId: this.process?.responsible_id,
      responsibleUser: null
    }
  },
  methods: {
    async save() {
      try {
        this.isLoading = true

        await this.$api.post('/process/set-responsible', {
          process_id: this.process.id,
          responsible_id: this.responsibleId
        })

        this.$parent.setResponsible(this.responsibleUser)

        this.$store.dispatch('ALERT_CREATE', { text: 'Ответственный добавлен', type: 'success' })
      } catch (e) {
        console.log(e)
        this.$store.dispatch('ALERT_CREATE', { text: e?.response?.data?.message || e.message, type: 'error' })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
