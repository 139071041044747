import AuthService from './auth.service'

export const auth = {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null,
    status: {
      loggedIn: false
    }
  },
  getters: {
    organization_id(state) {
      return state.user.organization_id
    },
    user(state) {
      return state.user
    },
    loggedIn(state) {
      return state.user !== null
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
      window.location = '/login'
    },
    registerSuccess(state) {
      state.status.loggedIn = true
    },
    registerFailure(state) {
      state.status.loggedIn = false
    }
  },
  actions: {
    login({ commit }, payload) {
      return AuthService.login(payload)
        .then((data) => {
          commit('loginSuccess', data.user)
          return Promise.resolve(data)
        })
        .catch((error) => {
          commit('loginFailure')
          return Promise.reject(error)
        })
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout')
    },
    registerConfirm({ commit }, token) {
      return AuthService.registerConfirm(token).then(
        (response) => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        (error) => {
          commit('registerFailure')
          return Promise.reject(error)
        }
      )
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        (error) => {
          commit('registerFailure')
          return Promise.reject(error)
        }
      )
    },
    invite({ commit }, user) {
      return AuthService.invite(user).then(
        (response) => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        (error) => {
          commit('registerFailure')
          return Promise.reject(error)
        }
      )
    }
  }
}
