import api from '@/api'

const endpoint = 'action/'

const event = {
  state: {
    list: [],
    currentAction: {},
    actionObjects: [],
    actionTypes: [],
    actionStatuses: [],
    actionDocuments: [],
    assurances: []
  },
  getters: {
    currentAction(state) {
      return state.currentAction
    },
    actionObjects(state) {
      return state.actionObjects
    },
    actionTypes(state) {
      return state.actionTypes
    },
    actionDocuments(state) {
      return state.actionDocuments
    },
    actionStatuses(state) {
      return state.actionStatuses
    },
    actionAssurances(state) {
      return state.assurances
    },
    allActions(state) {
      return state.list
    }
  },
  mutations: {
    action_add(state, payload) {
      state.list.push(payload)
    },
    action_objects_refresh(state, payload) {
      state.actionObjects = payload
    },
    action_statuses_refresh(state, payload) {
      state.actionStatuses = payload
    },
    action_types_refresh(state, payload) {
      state.actionTypes = payload
    },
    action_refresh_documents(state, payload) {
      state.actionDocuments = payload
    },
    action_change_current(state, payload) {
      state.currentAction = payload
    },
    action_refresh_action_list(state, payload) {
      state.list = payload
    },
    action_assurances_refresh(state, payload) {
      state.assurances = payload
    },
    action_change_current_text(state, payload) {
      state.currentAction.text = payload
    }
  },
  actions: {
    ACTION_DOCUMENTS_GET_ALL(context, payload) {
      return api.get(
        endpoint +
          'get-documents?id=' +
          payload.id +
          '&limit=' +
          payload.limit +
          '&offset=' +
          payload.offset +
          '&category_id=' +
          payload.category_id +
          '&sort_type=' +
          payload.sort.type +
          '&sort_direction=' +
          payload.sort.direction
      )
    },
    async ACTION_CHANGE_CURRENT_TEXT(context, payload) {
      context.commit('action_change_current_text', payload)
    },
    ACTION_SET_ACTION_LIST(context, payload) {
      context.commit('action_refresh_action_list', payload)
    },
    ACTION_GET_ACTION_LIST(context, payload) {
      return api.get(endpoint + 'get-page?offset=' + payload.offset + '&limit=' + payload.limit)
    },
    async ACTION_REFRESH_ACTION_LIST(context) {
      let result = await api.get(endpoint + 'get-all')
      if (result.data.ok === 1) {
        context.commit('action_refresh_action_list', result.data.data)
      }
    },
    async ACTION_REFRESH_DOCUMENTS(context, payload) {
      let result = await api.get(endpoint + 'get-documents/?id=' + payload)
      if (result.data.ok === 1) {
        context.commit('action_refresh_documents', result.data.data)
      }
    },
    async ACTION_SAVE(context, payload) {
      let result = await api.post(endpoint + 'save', payload)
      return result
    },
    async ACTION_CHANGE_CURRENT(context, payload) {
      context.commit('action_change_current', payload)
    },
    async ACTION_REFRESH_ALL(context) {
      let res = await api.get(endpoint + 'get')
      context.commit('action_refresh_all')
      return res
    },
    async ACTION_APPEND(context, payload) {
      return await api.post(endpoint + 'append', payload)
    },
    async ACTION_CREATE(context, payload) {
      let res = await api.post(endpoint + 'create', payload)
      if (res.data.ok === 1) {
        context.commit('action_add', res.data.data)
      }
      return res
    },
    async ACTION_OBJECTS_REFRESH(context) {
      return api.get(endpoint + 'get-objects').then((res) => {
        if (res.data.ok === 1) {
          context.commit('action_objects_refresh', res.data.data)
        }
      })
    },
    ACTION_TYPES_REFRESH(context) {
      return api.get(endpoint + 'get-types').then((res) => {
        if (res.data.ok === 1) {
          context.commit('action_types_refresh', res.data.data)
        }
      })
    },
    ACTION_STATUSES_REFRESH(context) {
      return api.get(endpoint + 'get-statuses').then((res) => {
        if (res.data.ok === 1) {
          context.commit('action_statuses_refresh', res.data.data)
        }
      })
    },
    ACTION_ASSURANCES_REFRESH(context) {
      return api.get(endpoint + 'get-assurances').then((res) => {
        if (res.data.ok === 1) {
          context.commit('action_assurances_refresh', res.data.data)
        }
      })
    },
    ACTION_GET(context, state_object) {
      return api.get(endpoint + 'get-' + state_object)
    },
    ACTION_SET_STATE(context, payload) {
      context.commit('action_' + payload.state + '_refresh', payload.data)
    }
  }
}
export default event
