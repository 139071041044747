<template>
  <div class="document">
    <div class="description">
      Вы уверены, что хотите безвовзвратно удалить этот документ? Восстановить его после удаления будет невозможно.
    </div>
    <div class="buttons">
      <NextButton text="УДАЛИТЬ ДОКУМЕНТ" type="next" color="green" @click-handler="deleteHandler" />
      <NextButton text="ОТМЕНИТЬ" type="close" color="white" @click-handler="close" />
    </div>
  </div>
</template>

<script>
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'

export default {
  name: 'DeleteDocument',
  components: { NextButton },
  props: {
    documentId: {
      default() {
        return false
      }
    },
    type: {
      type: Object,
      required: true
    },
    file: {
      type: Object,
      required: true
    }
  },
  mounted() {},
  methods: {
    close() {
      this.$parent.closeHandler()
    },
    async deletePublicDocument() {
      let id = this.file.document_id ? this.file.document_id : this.file.id
      let data = { document_id: id, document_type: this.type }
      if (this.type === 'process') {
        data.process_id = this.documentId ? this.documentId : this.file.process_id
      }
      let result = await this.$store.dispatch('DOCUMENTS_DELETE_PUBLIC', data)
      if (result.data.ok === 1) {
        this.$emit('deleteDocument')
        this.$parent.closeHandler()
      }
    },
    deleteHandler() {
      if (this.file.protected) {
        if (this.$store.getters.isSuperAdmin) {
          this.deletePublicDocument()
          return false
        } else {
          return false
        }
      }
      this.deleteDocument()
    },
    async deleteDocument() {
      /*  let id = this.file.document_id?this.file.document_id:this.file.id;*/
      /*     let data = {document_id: id, document_type: this.type};*/
      let payload = {}
      payload.type = this.type
      if (this.documentId) {
        payload.write_id = this.documentId
      }
      payload.document_id = this.file.document_id ? this.file.document_id : this.file.id
      let result = await this.$store.dispatch('DOCUMENTS_DELETE_BY_TYPE', payload)
      if (result.data.ok === 1) {
        this.$emit('deleteDocument')
        this.$parent.closeHandler()
      }
      return
      /* let result = await this.$store.dispatch('DOCUMENTS_DELETE', data);
      if (result.data.ok === 1) {
        this.$emit('deleteDocument');
        this.$parent.closeHandler();
      }*/
    }
  }
}
</script>

<style scoped>
.description {
  padding-bottom: 30px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.document {
  padding: 20px;
}
</style>
