<template>
  <div class="dropdown">
    <slot name="header"></slot>
    <div class="dropdown-container">
      <div class="dropdown-searchblock">
        <div class="input-container">
          <div class="input__header" v-if="header">
            {{ header }}&nbsp;
            <span v-if="required">*</span>
          </div>
          <div class="input-block">
            <input :autocomplete="false" v-if="search" type="text" class="input-field" v-model="text"
                   @keypress.enter="enterHandler" :placeholder="placeholder"
                   @input="inputHandler">
            <div class="input-field pointer" v-else @click="openDropdownHandler">
              <span v-if="selectedItem.text">{{ selectedItem.text }}</span>
              <span class="placeholder" v-else>{{ placeholder }}</span></div>
            <div class="down-icon icon pointer" :class="{reverse: openDropdown}" @click="openDropdownHandler"></div>
          </div>
        </div>
      </div>
      <div v-if="openDropdown" class="dropdown-backdrop" @click="openDropdown = false"></div>
      <div class="dropdown-list" v-if="openDropdown">
        <div class="no-item" v-if="list.length === 0">В данном списке пусто</div>
        <dropdownItem @clickHandler="selectHandler(item)" @click="selectedItem = item" :text="item.text"
                      :id="item.id || item.text" v-for="(item, key) in list" :key="key"></dropdownItem>
      </div>
    </div>
  </div>
</template>

<script>
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
import dropdownItem from '@/components/widgets/simple-components/inputs/dropdown/dropdownItem'

export default {
  name: 'dropdownWithHeader',
  components: { dropdownItem, inputWithHeader },
  props: {
    required: Boolean,
    search: {
      type: Boolean,
      default: true
    },
    autoclose: Boolean,
    placeholder: {
      type: String,
      default: ''
    },
    startItem: {
      default() {
        return false
      }
    },
    list: {
      type: Array,
      required: true
    },
    header: String
  },
  data() {
    return {
      text: '',
      openDropdown: false,
      selectedItem: {}
    }
  },
  mounted() {
    if (this.startItem) {
      this.selectedItem = this.startItem
      this.text = this.selectedItem.text
    }
  },
  methods: {
    openDropdownHandler() {
      this.openDropdown = !this.openDropdown
      if (this.openDropdown) {
        this.$emit('openDropdownHandler', this.text)
      }
    },
    enterHandler() {
      this.$emit('enterHandler', this.text)
      this.openDropdown = true
    },
    inputHandler() {
      this.$emit('inputHandler', this.text)
      this.openDropdown = true
    },
    selectHandler(val) {
      this.openDropdown = false
      if (val.text) {
        this.text = val.text
      }
      this.$emit('selectHandler', val)
    }
  }
}
</script>

<style scoped>
.dropdown {
  display: flex;
  align-items: center;
}

.dropdown-container {
  width: 100%;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 3px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .1);
  z-index: 9;
}

.no-item {
  font-size: 14px;
  color: rgba(51, 51, 51, 0.87);
}

.placeholder {
  color: #CCCCCC;
}


.reverse {
  transform: rotate(180deg);
}

.input-block {
  width: 100%;
}

.input__header {
  display: flex;
  padding-bottom: 9px;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
}

.input-field {
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 20px;
  padding-right: 40px;
  background-color: #FFFFFF;
  width: 100%;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 3px;
}

.input__header span {
  color: #1CC8AB;
}

.down-icon {
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
}

.input-block {
  display: flex;
  position: relative;
}

</style>