<template>
  <div class="tabs">
    <div class="tab" :class="{ active: activeTab === 'organization' }" @click="selectTab('organization')">
      Документы организации
    </div>
    <div class="tab" :class="{ active: activeTab === 'public' }" @click="selectTab('public')">Общие документы</div>
    <div class="tab" :class="{ active: activeTab === 'file' }" @click="selectTab('file')">Файловый менеджер</div>
  </div>
  <div v-if="activeTab !== 'file'" class="content-block">
    <div class="documents-controls">
      <div style="display: flex">
        <DropdownWithHeader
          v-if="documentTypes.length > 0"
          :key="currentType.id"
          class="document-types"
          header="Категории документов"
          :search="false"
          :list="documentTypes"
          :start-item="currentType"
          @selectHandler="changeType"
        />
        <DropdownWithHeader
          v-if="documentVariants.length > 0"
          :key="currentObject.id"
          class="document-types"
          header="Принадлежность"
          :search="false"
          :list="documentVariants"
          :start-item="currentObject"
          @selectHandler="changeObject"
        />
      </div>
      <nextButton
        text="Очистить фильтры"
        color="white"
        type="close"
        :class="{ inactive: currentObject.id === 'all' && currentType.id === 0 }"
        @click="clearFilters"
      />
    </div>
    <div v-if="activeTab === 'organization'" class="content-info">
      <DocumentsList
        :sort="sort"
        :items="$store.getters.allDocuments"
        @changeSort="changeSort"
        @deleteDocument="deleteDocument"
      />
      <div class="pagination-container">
        <Paginate
          v-if="count / perPage > 1"
          :key="perPage"
          :page-count="Math.ceil(count / perPage)"
          :page-range="5"
          :margin-pages="2"
          :next-text="''"
          :prev-text="''"
          :click-handler="changePage"
        />
      </div>
    </div>
    <div v-if="activeTab === 'public'" class="content-info">
      <DocumentsList
        :object="'public'"
        :sort="sort"
        :items="publicFiles"
        @changeSort="changeSort"
        @deleteDocument="deleteDocument"
      />
      <div class="pagination-container">
        <Paginate
          v-if="count / perPage > 1"
          :key="perPage"
          :page-count="Math.ceil(count / perPage)"
          :page-range="5"
          :margin-pages="2"
          :next-text="''"
          :prev-text="''"
          :click-handler="changePage"
        />
      </div>
    </div>
  </div>
  <div v-if="activeTab === 'file'" class="content-block">
    <fileManager :type="'main'" />
  </div>
</template>
<script>
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import DocumentsList from './DocumentsList'
import paginationMixin from '@/components/mixins/paginationMixin'
import Paginate from 'vuejs-paginate-next'
import DropdownWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import fileManager from '@/components/widgets/custom-components/modalWindow/modalComponents/fileManager/fileManager'

export default {
  name: 'AllDocuments',
  components: { DropdownWithHeader, nextButton, DocumentsList, Paginate, fileManager },
  mixins: [paginationMixin],
  props: {
    startTab: {
      type: String,
      default: 'organization'
    }
  },
  data() {
    return {
      publicFiles: [],
      documentTypes: [],
      activeTab: 'organization',
      currentType: { id: 0, text: 'Все' },
      currentObject: { id: 'all', text: 'Все' },
      sort: {
        type: 'date',
        direction: 'desc'
      }
    }
  },
  computed: {
    documentVariants() {
      if (this.activeTab === 'organization') {
        return [
          { id: 'all', text: 'Все' },
          { id: 'organization', text: 'Организация' },
          { id: 'process', text: 'Процесс' },
          { id: 'requirement', text: 'Требование' },
          { id: 'action', text: 'Мероприятие' },
          { id: 'control', text: 'План проверок' }
        ]
      } else {
        return [
          { id: 'all', text: 'Все' },
          { id: 'organization', text: 'Организация' },
          { id: 'process_files', text: 'Процесс' },
          { id: 'requirements_files', text: 'Требование' }
        ]
      }
    }
  },
  watch: {
    activeTab() {
      if (this.activeTab !== 'file') {
        this.clearFilters()
      } else {
        this.currentType = this.documentTypes[0]
        this.currentObject = this.documentVariants[0]
      }
    }
  },
  mounted() {
    this.activeTab = this.startTab
    this.documentTypes.push({ id: 0, text: 'Все' })
    this.$api.get('documents/get-types').then((res) => {
      if (res.data.ok === 1) {
        this.documentTypes = [...this.documentTypes, ...res.data.data]
      }
    })
    this.changePage(1)
  },
  methods: {
    selectTab(tab) {
      this.$router.push('/documents/' + tab)
      this.activeTab = tab
    },
    clearFilters() {
      this.currentType = this.documentTypes[0]
      this.currentObject = this.documentVariants[0]
      this.changePage(this.page)
    },
    changeSort(sort) {
      this.sort = sort
      this.changePage(this.page)
    },
    deleteDocument() {
      this.changePage(this.page)
    },
    changeObject(val) {
      this.currentObject = val
      this.changePage(1)
    },
    changeType(val) {
      this.currentType = val
      this.changePage(1)
    },
    refreshPublicFiles(page) {
      this.$api
        .get(
          'documents/get-files?' +
            'limit=' +
            this.perPage +
            '&offset=' +
            (page - 1) * this.perPage +
            '&category_id=' +
            this.currentType.id +
            '&sort_type=' +
            this.sort.type +
            '&sort_direction=' +
            this.sort.direction +
            '&type=' +
            this.currentObject.id
        )
        .then((res) => {
          if (res.data.ok === 1) {
            this.publicFiles = res.data.data
            this.count = res.data.data.count
          }
        })
    },
    refreshMyFiles(page) {
      this.page = page
      this.$store
        .dispatch('DOCUMENTS_GET_ALL', {
          offset: this.perPage * (this.page - 1),
          limit: this.perPage,
          type: this.currentObject.id,
          category_id: this.currentType.id,
          sort: this.sort
        })
        .then((res) => {
          if (res.data.ok === 1) {
            this.count = res.data.count
            this.$store.commit('documents_set_list', res.data.data)
          }
        })
    },
    changePage(page) {
      if (this.activeTab === 'organization') {
        this.refreshMyFiles(page)
      } else {
        this.refreshPublicFiles(page)
      }
    }
  }
}
</script>

<style scoped>
.document-types {
  width: 320px;
  margin-right: 30px;
}

.documents-controls {
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.content-header {
  font-size: 23px;
  line-height: 31px;
  padding-bottom: 23px;
}

.content-block {
  border-bottom: 1px solid #cccccc;
  padding: 23px 40px;
  background-color: white;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
  border-radius: 3px;
}
</style>
