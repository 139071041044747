<template>
  <div class="checkbox" :class="{ disabled: disabled }" @click="changeHandler">
    <div v-if="!reverse" class="checkbox-mask">
      <div v-if="active" class="checkbox-checked" />
    </div>
    <div class="checkbox-label" v-html="label" />
    <div v-if="reverse" class="checkbox-mask reverse">
      <div v-if="active" class="checkbox-checked" />
    </div>
    <input v-model="active" :disabled="disabled" type="checkbox" hidden />
  </div>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    reverse: {
      type: Boolean,
      default() {
        return false
      }
    },
    startActive: {
      type: Boolean,
      default() {
        return false
      }
    },
    label: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      active: this.startActive
    }
  },
  methods: {
    changeHandler() {
      if (!this.disabled) {
        this.active = !this.active
        this.$emit('changeHandler', this.active)
      }
    }
  }
}
</script>

<style scoped>
.checkbox.disabled {
  cursor: default;
}
.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.checkbox-label {
  font-size: 12px;
}
.checkbox-checked {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.24899 9.97862C4.44654 9.982 4.63778 9.90937 4.78219 9.77452C6.99906 7.55765 8.12315 6.43305 9.22318 5.33252C10.277 4.27826 11.3087 3.2461 13.2578 1.29698C13.3926 1.15257 13.4653 0.961326 13.4619 0.763773C13.4585 0.566221 13.3789 0.378094 13.2392 0.238383C13.0995 0.0986714 12.9114 0.0181298 12.7138 0.0147498C12.5163 0.0113697 12.325 0.0849779 12.1806 0.219828C10.8575 1.54297 9.93703 2.46259 9.12976 3.26912C7.68665 4.71091 6.60529 5.79128 4.23194 8.16937C2.79598 6.7331 3.04345 6.97894 3.16456 7.09953C3.19962 7.13449 3.21038 7.1452 3.20699 7.14182C3.20361 7.13844 3.18608 7.12096 3.16456 7.09953C3.03545 6.97082 2.57677 6.51341 1.28136 5.2208C1.13695 5.08595 0.945708 5.01235 0.748156 5.01573C0.550603 5.01911 0.362476 5.09965 0.222765 5.23936C0.0830536 5.37907 0.00348899 5.5672 0.000109163 5.76475C-0.00327067 5.9623 0.0693602 6.15354 0.20421 6.29795C2.42103 8.51477 2.24143 8.33467 2.06571 8.15845C1.89737 7.98963 1.73258 7.82437 3.68175 9.77354C3.82616 9.90839 4.0174 9.98102 4.21495 9.97764C4.21647 9.97761 4.21798 9.97758 4.21949 9.97754C4.2293 9.97809 4.23913 9.97845 4.24899 9.97862Z' fill='url(%23paint0_linear_191_3310)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_191_3310' x1='6.73099' y1='0.0146484' x2='6.73099' y2='9.97874' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2337C489'/%3E%3Cstop offset='1' stop-color='%2300CCCC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 10px;
}
.checkbox-mask.reverse {
  margin-right: 0;
  margin-left: 16px;
}
.checkbox-mask {
  margin-right: 10px;
  flex: none;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
}
</style>
