<template>
  <div class="document-item" :class="{ gray }">
    <div class="name-column">{{ item.name || item.title }}</div>
    <FileLink
      class="file-column"
      :link="'documents/download/?id=' + (item.document_id || item.id)"
      :name="item.old_name || item.file_name"
    />
    <div class="category-column">{{ item.text }}</div>
    <div class="date-column">{{ $helper.makeDate(new Date(Number.parseInt(item.upload_date))) }}</div>
    <div class="events-column" v-if="object !== 'public'">
      <div v-if="!item.protected" class="green-edit-icon icon pointer" @click="openEditModal"></div>
      <div v-if="!item.protected" class="red-christ-icon icon pointer" @click="openDeleteModal"></div>
    </div>
    <div class="events-column" v-else>
      <template v-if="item.object">{{ item.object }}</template>
    </div>
    <modalWindow header="Редактировать документ" v-if="openEditFile" @closeHandler="openEditFile = false">
      <editDocument :file="item" />
    </modalWindow>
    <modalWindow header="Удалить документ" v-if="openDeleteFile" @closeHandler="openDeleteFile = false">
      <deleteDocument :file="item" :type="type" :document-id="documentId" @deleteDocument="deleteDocument" />
    </modalWindow>
  </div>
</template>

<script>
import modalWindow from '@/components/widgets/custom-components/modalWindow/modalWindow'
import editDocument from '@/components/widgets/custom-components/modalWindow/modalComponents/editDocument'
import deleteDocument from '@/components/widgets/custom-components/modalWindow/modalComponents/deleteDocument'
import FileLink from '@/components/common/FileLink'

export default {
  components: { FileLink, modalWindow, editDocument, deleteDocument },
  name: 'documentItem',
  data() {
    return {
      openEditFile: false,
      openDeleteFile: false
    }
  },
  props: {
    object: {
      default() {
        return ''
      }
    },
    gray: {
      default() {
        return true
      }
    },
    documentId: {
      default() {
        return false
      }
    },
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  computed: {},
  mounted() {},
  methods: {
    deleteDocument() {
      this.$emit('deleteDocument')
    },
    async download() {
      try {
        const res = await this.$api.get('documents/download/?id=' + (this.item.document_id || this.item.id), {
          responseType: 'blob'
        })

        const blob = new Blob([res.data])
        const downloadUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = downloadUrl
        a.download = this.item.old_name || this.item.file_name
        document.body.appendChild(a)
        a.click()
      } catch (e) {
        alert(e.message)
      }
    },
    openEditModal() {
      this.openEditFile = true
    },
    openDeleteModal() {
      this.openDeleteFile = true
    }
  }
}
</script>

<style scoped>
.red-christ-icon {
  width: 12px;
  height: 12px;
}

.green-edit-icon {
  width: 12px;
  height: 12px;
}

.document-item {
  display: flex;
  border: 1px solid #cccccc;
}

.events-column {
  padding-right: 20px;
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 160px;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  flex: none;
}

.category-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 175px;
  padding-left: 20px;
  flex: none;
}

.file-column {
  border-right: 1px solid #cccccc;
  word-break: break-word;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  flex: none;
  padding-left: 20px;
}

.name-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  padding-left: 20px;
  border-left: 1px solid #cccccc;
}

.date-column {
  width: 200px;
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  flex: none;
}
</style>
