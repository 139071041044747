import api from '@/api'
import { USER_ROLES } from '@/constants'

const user = {
  state: {
    currentUser: {}
  },
  getters: {
    currentUser(state) {
      return state.currentUser
    },
    isSuperAdmin(state) {
      return state.currentUser.role_id === USER_ROLES.SUPER_ADMIN
    },
    isAdmin(state) {
      return state.currentUser.role_id === USER_ROLES.ADMIN || state.currentUser.role_id === USER_ROLES.SUPER_ADMIN
    },
    isQualityCommissioner(state) {
      return !!state.currentUser.is_quality_commissioner
    }
  },
  mutations: {
    update_current_user(state, payload) {
      localStorage.setItem('user', JSON.stringify(payload))
      state.currentUser = payload
    }
  },
  actions: {
    async USER_GET(context) {
      let res = await api.get('users/get')
      if (res.data.ok === 1) {
        context.commit('update_current_user', res.data.data)
      }
    }
  }
}
export default user
