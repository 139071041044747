<template>
  <div class="content-block pt-0">
    <div class="content-header">
      Данные об организации:
      <NextButton type="edit" color="white" text="Редактировать" @clickHandler="openEdit" />
    </div>
    <div class="content-info">
      <organizationInformationItem :text="organization.name" header="Организация" icon="white-plus-icon" />
      <organizationInformationItem :text="organization.director" header="Руководитель" icon="white-avatar-icon" />
      <organizationInformationItem
        :text="organization.quality"
        header="Руководитель Процессного офиса"
        icon="white-shield-icon"
      />
    </div>
  </div>
  <div class="content-block">
    <div class="content-header">Сведения о юридическом лице:</div>
    <div class="content-info">
      <organizationInformationItem :text="organization.inn" :header="'ИНН'" :icon="'white-number-icon'" />
      <organizationInformationItem :text="organization.kpp" :header="'КПП'" :icon="'white-number-icon'" />
      <organizationInformationItem :text="organization.ogrn" :header="'ОГРН'" :icon="'white-number-icon'" />
      <organizationInformationItem
        :text="organization.address"
        :header="'Юридический адрес'"
        :icon="'white-point-icon'"
      />
    </div>
  </div>
  <div class="content-block">
    <div class="content-header">Контактная информация:</div>
    <div class="content-info">
      <organizationInformationItem :text="organization.phone" header="Телефон" icon="white-phone-icon" />
      <organizationInformationItem :text="organization.fax" header="Факс" icon="white-fax-icon" />
      <organizationInformationItem :text="organization.email" header="Электронная почта" icon="white-email-icon" />
      <organizationInformationItem :text="organization.site" header="Сайт" icon="white-site-icon" />
      <organizationInformationItem :text="organization.mail_address" header="Почтовый адрес" icon="white-point-icon" />
    </div>
  </div>
</template>

<script>
import organizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'

export default {
  name: 'MainTab',
  components: { organizationInformationItem, NextButton },
  props: {
    organization_id: {
      default() {
        return false
      }
    }
  },
  emits: ['openEdit'],
  data() {
    return {
      selectedMedHelps: [],
      selectMedActions: []
    }
  },
  computed: {
    organization: (vm) => vm.$store.getters['currentOrganization']
  },
  mounted() {
    this.getMedHelps()
    this.getMedActions()
  },
  methods: {
    async getMedActions() {
      let result = await this.$api.get(
        'organization/get-organization-medactions?id=' + this.$store.getters['currentOrganization'].id
      )
      this.selectMedActions = result.data.data
    },
    async getMedHelps() {
      let result = await this.$api.get(
        'organization/get-organization-medhelps?id=' + this.$store.getters['currentOrganization'].id
      )
      this.selectedMedHelps = result.data.data
    },
    openEdit() {
      this.$emit('openEdit')
    }
  }
}
</script>

<style scoped>
.content-list {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.content-info .information-item {
  padding-bottom: 30px;
}

.med-item {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f2f2f2;
  box-shadow: 1px 1px 0px #cccccc;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}

.content-block {
  border-bottom: 1px solid #cccccc;
  padding: 24px 0;
}

.information-icon {
  flex: none;
}

.content-header {
  display: flex;
  justify-content: space-between;
}

.content-info {
  display: flex;
  flex-wrap: wrap;
}
</style>
