<script setup>
import { computed } from 'vue'
import { NDropdown, NButton, NIcon, useDialog } from 'naive-ui'
import { Dots } from '@vicons/tabler'

const props = defineProps({
  row: {
    type: Object,
    required: true
  },
  options: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['select'])

const dialog = useDialog()
const defaultOptions = {
  edit: {
    label: 'Редактировать',
    order: 0
  },
  delete: {
    label: 'Удалить',
    order: 1,
    confirm: {
      type: 'error'
    }
  }
}

const options = computed(() => {
  return Object.entries(props.options)
    .map(([key, option]) => ({
      ...(defaultOptions[key] || {}),
      key,
      ...option
    }))
    .sort((a, b) => a.order - b.order)
})

const handleSelect = (key, option) => {
  emit('select', key, props.row)

  if (option.confirm) {
    const d = dialog[option.confirm.type]({
      title: option.confirm.title || 'Подтвердите действие',
      content: option.confirm.content || 'Вы действительно хотите удалить этот объект?',
      positiveText: option.confirm.positiveText || 'Удалить',
      negativeText: option.confirm.negativeText || 'Отмена',
      onPositiveClick: () => {
        d.loading = true
        return option.selectHandler()
      }
    })
  } else {
    option.selectHandler()
  }
}
</script>

<template>
  <NDropdown placement="bottom-end" trigger="click" :options="options" @select="handleSelect">
    <NButton text style="font-size: 20px">
      <NIcon>
        <Dots />
      </NIcon>
    </NButton>
  </NDropdown>
</template>
