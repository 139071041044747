<template>
  <div class="pdf-footer" data-height="40">
    <div class="pdf-page-number text-muted h-4">
      {{ pageNumber }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageNumber: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss">
.pdf-footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 2.5rem; // 32px
}
</style>
