<template>
  <div class="item" :class="{pause: item.pause}">
    <div class="item-checkbox" @click="clickHandler">
      <div class="checked-icon" v-if="item.active"></div>
    </div>
    <input type="checkbox" hidden>
    <div class="item-number">{{ makeNumber(item.number) }}</div>
    <div class="item-text">{{ item.text }}
      <div v-if="descriptionOpen" class="backdrop" @click="openDescription"></div>
      <div class="item-description" v-if="item.description !== '' && item.description !== null">
        <div
          class="icon"
          ref="question"
          :class="descriptionOpen ? 'question-green-icon' : 'question-gray-icon'"
          @click="openDescription"
        ></div>
        <div
          v-show="descriptionOpen"
          ref="description"
          class="question-description"
          v-html="formatText(item.description)"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'vkkItem',
  data() {
    return {
      active: this.item.active,
      descriptionOpen: false
    }
  },
  props: {
    item: {
      required: true
    }
  },
  methods: {
    openDescription() {
      this.descriptionOpen = !this.descriptionOpen
      let question = this.$refs.question
      let item = this.$refs.description
      let screen = window.innerWidth
      if (screen - question.getBoundingClientRect().x < 300) {
        item.style.right = 0
      }
    },
    makeNumber(number) {
      if (Number.parseInt(number) < 10) {
        return '0' + number
      } else {
        return number
      }
    },
    clickHandler() {
      this.$emit('clickHandler', this.item)
    },
    formatText(text) {
      return text.replaceAll('\n', '<br>')
    }
  }
}
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.question-description {
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px;
  z-index: 9;
  position: absolute;
  top: 100%;
  max-height: 400px;
  overflow: auto;
  max-width: 600px;
  min-width: 300px;
  background-color: white;
}

.icon {
  margin-left: 10px;
  cursor: pointer;
}

.item-text {
  position: relative;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
}

.item-description {
  display: inline-block;
}

.item {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.item.pause {
  opacity: .6;
  cursor: default;
}

.item-number {
  flex: none;
  margin-right: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
}

.item-checkbox {
  cursor: pointer;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
</style>