<template>
  <div class="spin" :class="{ 'default-color': !currentColor }">
    <div class="spin__inner" :style="styles" />
  </div>
</template>

<script>
export default {
  name: 'SpinLoader',
  props: {
    size: {
      type: [Number, String],
      default: '1em'
    },
    stroke: {
      type: [Number, String],
      default: 3
    },
    currentColor: Boolean
  },
  computed: {
    iconSize() {
      return (
        {
          sm: '16px',
          md: '20px',
          lg: '24px',
          xl: '32px',
          '2xl': '40px',
          '3xl': '48px'
        }[this.size] || (isNaN(Number(this.size)) ? this.size : this.size + 'px')
      )
    },
    styles() {
      return {
        width: this.iconSize,
        height: this.iconSize,
        borderWidth: this.stroke + 'px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.spin {
  display: flex;
  justify-content: center;
  align-items: center;

  &.default-color {
    .spin__inner {
      border-right-color: #37c4a3;
    }
  }

  &__inner {
    border: 3px solid transparent;
    border-right-color: currentColor;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: none;
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
