const alert = {
    state: {
        alerts: [],
    },
    getters: {
        alerts(state) {
            return state.alerts;
        }
    },
    mutations: {
        alert_create(state, payload) {
            payload.id = new Date().getTime();
          state.alerts.unshift(payload);
          let timeout = payload.timeout || 4;
          setTimeout(()=> {
              state.alerts.splice(state.alerts.indexOf(payload));
          }, timeout * 1000)
        },
        alert_delete(state, payload) {
            state.alerts.splice(state.alerts.indexOf(payload));
        }
    },
    actions: {
        ALERT_DELETE(context, payload) {
            context.commit('alert_delete', payload);
        },
        ALERT_CREATE(context, payload) {
            context.commit('alert_create', payload);
        },
    },
}
export default alert;