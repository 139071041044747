<template>
  <div class="container container-center content">
    <div class="login-container">
      <div class="logo-container">
        <div class="process-logo process-logo_large my-2">Процессный офис</div>
      </div>
      <div class="register-container">
        {{ message }}
      </div>
    </div>
  </div>
</template>
<script>
import whiteLogo from '@/components/widgets/simple-components/logo/whiteLogo'
export default {
  name: 'RegisterApply',
  components: {
    whiteLogo
  },
  data() {
    return {
      message:
        'Вы успешно зарегистрировались. На Вашу почту отправлено письмо с инструкцией по активации аккаунта. Пожалуйста, проверьте почту и активируйте Ваш аккаунт.'
    }
  },
  methods: {}
}
</script>

<style scoped>
.content {
  width: 658px;
  z-index: 1;
}
.register-container {
  padding-top: 20px;
  font-size: 16px;
  color: white;
  text-align: center;
}
.login-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
}
</style>
