<template>
  <div class="card card-body">
    <div class="d-flex align-items-center mb-4">
      <h4 class="card-title mr-5 mb-0">Уровень процессной зрелости</h4>
      <NextSelect
        v-model="medtype"
        class="w-72"
        request="/organization/get-organization-medtypes"
        placeholder="Направления"
        clearable
        @change="fetchProcesses"
      />
    </div>
    <SpinLoader v-if="isLoading" class="py-5" size="2xl" />
    <NextAlert v-else-if="isError" type="error" title="Произошла ошибка." :text="isError" />
    <div v-else class="my-3">
      <div class="widget-area">
        <div class="widget-area__cols">
          <div v-for="i in 20" :key="i" class="widget-area__col" />
          <div class="widget-area__level" :style="{ left: `calc(${normLevel}% - 1px)` }" />
        </div>
        <div class="widget-area__rows">
          <div class="widget-area__row h-4 p-0">
            <div class="widget-area__percents">
              <div v-for="i in percents" :key="i" class="widget-area__percent">{{ i }}%</div>
            </div>
          </div>
          <div v-for="item in widgetData" :key="item.title" class="widget-area__row">
            <div class="widget-area__row-text">
              <NextIcon icon="cross" class="text-gray-300 mr-2" size="10" />
              <RouterLink :to="`/process/${item.id}`" class="text-sm text-gray-800">{{ item.text }}</RouterLink>
            </div>
            <div
              class="widget-area__progress"
              :class="{ danger: item.percent < normLevel }"
              :style="{ width: item.percent + '%' }"
            />
          </div>
          <div class="widget-area__row h-4 p-0 border-bottom-0">
            <div class="widget-area__percents bottom">
              <div v-for="i in percents" :key="i" class="widget-area__percent">{{ i }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import DonutChart from '@/components/widgets/custom-components/DonutChart/DonutChart'
import NextSelect from '@/components/widgets/simple-components/select/NextSelect'
import NextIcon from '@/components/widgets/simple-components/icon'

export default {
  name: 'ProcessesGraphicWidget',
  components: { NextIcon, NextSelect, DonutChart, NextAlert, SpinLoader },
  data() {
    return {
      isLoading: true,
      isError: false,
      medtype: null,
      widgetData: [],
      percents: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      normLevel: 80
    }
  },
  mounted() {
    this.fetchAll()
  },
  methods: {
    fetchAll() {
      this.isLoading = true
      this.isError = false

      return Promise.all([this.fetchProcesses(), this.fetchSettings()])
        .catch((e) => {
          this.isError = e.message
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async fetchSettings() {
      try {
        const res = await this.$api.get('/general-settings/get-all')

        if (res.data.ok === 1) {
          const normLevel = res.data.data.find((item) => item.text === 'process-norm-level')
          if (normLevel) this.normLevel = Math.min(Math.max(0, Math.round(+normLevel.value)), 100)
        } else {
          throw new Error(res.data.message)
        }
      } catch (e) {
        console.error(e)
        throw new Error(e.message)
      }
    },
    async fetchProcesses() {
      try {
        const res = await this.$api.get(`/process/formatted`, {
          params: {
            medtype_id: this.medtype
          }
        })

        if (res.data.ok === 1) {
          this.widgetData =
            res.data?.data?.map((item) => ({
              id: item.id,
              text: item.text,
              percent: this.$helper.toPercent(item?.requirements_count?.all, item?.requirements_count?.passed) || 0
            })) || []
        } else {
          throw new Error(res.data.message)
        }
      } catch (e) {
        console.error(e)
        throw new Error(e.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-area {
  position: relative;
  border-left: 2px solid #aaa;
  margin: 1rem 0;

  &__percents {
    position: absolute;
    left: 0;
    bottom: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.bottom {
      top: 100%;
      bottom: auto;
    }
  }

  &__percent {
    width: 80px;
    font-size: 15px;
    font-weight: 500;
    color: #666;
    text-align: center;
    background: #fff;
    white-space: nowrap;

    &:first-child {
      width: 40px;
      text-align: left;
      transform: translateX(-4px);
    }

    &:last-child {
      width: 40px;
      text-align: right;
      transform: translateX(4px);
    }
  }

  &__row {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.75rem 0;
    border-bottom: 1px solid #dedede;

    &-text {
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
      margin-bottom: 1rem;
      z-index: 2;
    }
  }

  &__progress {
    height: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #37c4a3;
    z-index: 2;

    &.danger {
      background: #d65959;
    }
  }

  &__cols {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }

  &__col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 5.25rem;
    height: 100%;
    border-right: 1px solid #dedede;
  }

  &__level {
    position: absolute;
    top: 0;
    width: 2px;
    height: 100%;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="2" height="10" fill="none" viewBox="0 0 2 10"><path fill="%23ffffff" d="M0 0h2v10H0V0Z"/><path fill="%23AAA" d="M0 0h2v6H0V0Z"/></svg>');
    background-repeat: repeat-y;
    background-position: 0 0;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: -38px;
      left: 100%;
      width: 12px;
      height: 12px;
      background: #37c4a3;
      transform: translateX(-50%) rotate(45deg);
    }

    &::after {
      content: 'Норма';
      position: absolute;
      top: -54px;
      left: 100%;
      font-size: 14px;
      color: #37c4a3;
      background: #fff;
      transform: translateX(-50%);
    }
  }
}
</style>
