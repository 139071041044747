import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useMessage, useNotification } from 'naive-ui'
import api from '@/api'

export const useDocumentsStore = defineStore('documents', () => {
  const endpoint = 'documents/'

  const message = useMessage()
  const notify = useNotification()

  const isLoading = ref(false)
  const isDeleting = ref(false)
  const isUploading = ref(false)
  const isError = ref(false)
  const error = ref(null)
  const items = ref([])
  const files = ref([])

  // ACTIONS

  const getAll = async (params) => {
    try {
      isLoading.value = true
      isError.value = false
      error.value = null

      const res = await api.get(endpoint + 'get-documents', { params })

      if (res.data.ok === 1) {
        items.value = res.data.data
        return res
      }

      throw new Error(res.data.message)
    } catch (e) {
      isError.value = true
      error.value = e?.response?.data?.message || e.message
    } finally {
      isLoading.value = false
    }
  }

  const upload = async (payload) => {
    try {
      isUploading.value = true

      const res = await api.post(endpoint + 'upload-file', payload, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })

      if (res.data.ok === 1) {
        items.value.unshift(res.data.data.document)
        message.success('Документ загружен')
        return res
      }
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось загрузить документ',
        duration: 2500
      })
    } finally {
      isUploading.value = false
    }
  }

  const getAllFiles = async (params) => {
    try {
      isLoading.value = true
      isError.value = false
      error.value = null

      const res = await api.get(endpoint + 'get-files', { params })

      if (res.data.ok === 1) {
        files.value = res.data.data
        return res
      }

      throw new Error(res.data.message)
    } catch (e) {
      isError.value = true
      error.value = e?.response?.data?.message || e.message
      throw e
    } finally {
      isLoading.value = false
    }
  }

  /**
   * @param {{write_id, document_id, type}} payload
   */
  const deleteByType = async (payload) => {
    try {
      isDeleting.value = true

      const res = await api.post(endpoint + 'delete-document-by-type', payload)

      items.value = items.value.filter(
        (item) => item.id !== payload.document_id && item.document_id !== payload.document_id
      )
      message.success('Документ удален')
      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось удалить документ',
        duration: 2500
      })
    } finally {
      isDeleting.value = false
    }
  }

  /**
   * @param {{process_id, document_id, document_type}} payload
   */
  const deletePublic = async (payload) => {
    try {
      isDeleting.value = true

      const res = await api.post(endpoint + 'delete-public-document', payload)
      message.success('Документ удален')
      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось удалить документ',
        duration: 2500
      })
    } finally {
      isDeleting.value = false
    }
  }

  const deleteById = async (id, showMessage = true) => {
    try {
      isDeleting.value = true

      const res = await api.post(endpoint + 'delete-document-by-id', { id })

      items.value = items.value.filter((item) => item.id !== id)

      if (showMessage) message.success('Документ удален')

      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось удалить документ',
        duration: 2500
      })
    } finally {
      isDeleting.value = false
    }
  }

  return {
    isLoading,
    isDeleting,
    isUploading,
    isError,
    error,
    items,
    files,
    getAll,
    upload,
    getAllFiles,
    deleteByType,
    deletePublic,
    deleteById
  }
})
