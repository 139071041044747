export function zeroNumbersFormat(number) {
  return number < 10 ? `0${number}` : number
}

export function makeDate(date) {
  if (typeof date === 'string') date = parseInt(date)
  if (!date) return '–'
  if (typeof date === 'number') date = new Date(date)

  if (date.getTime() < 10000) return '–'

  let days = zeroNumbersFormat(date.getDate())
  let months = zeroNumbersFormat(date.getMonth() + 1)
  let years = date.getFullYear()

  return `${days}.${months}.${years}`
}

export function makeDateTime(date, separator) {
  if (typeof date === 'string') date = parseInt(date)
  if (!date) return '–'
  if (typeof date === 'number') date = new Date(date)

  let hours = zeroNumbersFormat(date.getHours())
  let minutes = zeroNumbersFormat(date.getMinutes())
  let days = zeroNumbersFormat(date.getDate())
  let months = zeroNumbersFormat(date.getMonth() + 1)
  let year = date.getFullYear()

  if (separator) {
    return `${days}${separator}${months}${separator}${year}${separator}${hours}${separator}${minutes}`
  }

  return `${hours}:${minutes} - ${days}.${months}.${year}`
}

export function toTimestamp(strDate) {
  if (!strDate) return strDate

  return new Date(Number(strDate) || strDate).getTime()
}

export function isEmpty(value) {
  if (value === null) {
    return true
  }

  if (Array.isArray(value) || typeof value === 'string') {
    return !value.length
  }

  if (typeof value === 'object') {
    return !Object.keys(value).length
  }

  return true
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function arrayMove(arr, from, to) {
  const clonned = [...arr]
  const el = clonned[from]
  clonned.splice(from, 1)
  clonned.splice(to, 0, el)

  return clonned
}

export function isObject(value) {
  return typeof value === 'object' && !Array.isArray(value) && value !== null
}

export function toPercent(fromNum = 1, num) {
  return Math.round((100 / fromNum) * num) || 0
}
