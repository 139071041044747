import api from '@/api'

const endpoint = 'organization/'

const organization = {
  state: {
    currentOrganization: JSON.parse(localStorage.getItem('user'))?.organization || false,
    currentOrganizationProcesses: [],
    currentOrganizationIndicators: [],
    currentOrganizationMedTypes: [],
    organizationDocuments: [],
    allMedactions: [],
    allMedhelps: [],
    organizationsSearchResult: []
  },
  getters: {
    organizationDocuments(state) {
      return state.organizationDocuments
    },
    organizationMedTypes(state) {
      return state.currentOrganizationMedTypes
    },
    organizationIndicators(state) {
      return state.currentOrganizationIndicators
    },
    organizationProcesses(state) {
      return state.currentOrganizationProcesses
    },
    allMedactions(state) {
      return state.allMedactions.slice()
    },
    allMedhelps(state) {
      return state.allMedhelps.slice()
    },
    currentOrganization(state) {
      return state.currentOrganization
    },
    organizationsSearchResult(state) {
      return state.organizationsSearchResult
    }
  },
  mutations: {
    organization_create_organization_indicator(state, payload) {
      state.currentOrganizationIndicators.push(payload)
    },
    organization_add_organization_process(state, process) {
      state.currentOrganizationProcesses.push(process)
    },
    organization_remove_organization_process(state, id) {
      for (let i = 0; i < state.currentOrganizationProcesses.length; i++) {
        if (state.currentOrganizationProcesses[i].process_id === id) {
          state.currentOrganizationProcesses.splice(i, 1)
          return
        }
      }
    },
    organization_set_all_medhelps(state, medhelps) {
      state.allMedhelps = medhelps
    },
    organization_set_all_medactions(state, medorgs) {
      state.allMedactions = medorgs
    },
    organization_add(state, organization) {
      state.currentOrganization = organization
      let user = JSON.parse(localStorage.getItem('user'))
      user.organization = state.currentOrganization
      localStorage.setItem('user', JSON.stringify(user))
    },
    organization_update_organization_processes(state, payload) {
      state.currentOrganizationProcesses = payload
    },
    organization_refresh_organization(state, organization) {
      state.currentOrganization = organization
      let user = JSON.parse(localStorage.getItem('user'))
      user.organization = state.currentOrganization
      localStorage.setItem('user', JSON.stringify(user))
    },
    organization_remove_organization_indicator(state, payload) {
      for (let i = 0; i < state.currentOrganizationIndicators.length; i++) {
        if (state.currentOrganizationIndicators[i].indicator_id == payload.indicator_id) {
          state.currentOrganizationIndicators.splice(i, 1)
        }
      }
    },
    organization_update_organization_indicators(state, payload) {
      state.currentOrganizationIndicators = payload
    },
    organization_update_organization_medtypes(state, payload) {
      state.currentOrganizationMedTypes = payload
    },
    organization_refresh_documents(state, payload) {
      state.organizationDocuments = payload
    },
    organization_delete_document(state, payload) {
      for (let i = 0; i < state.organizationDocuments.length; i++) {
        if (state.organizationDocuments[i].id === payload.document_id) {
          state.organizationDocuments.splice(i, 1)
          break
        }
      }
    }
  },
  actions: {
    async ORGANIZATION_REQUIREMENT_GET(context, payload) {
      return await api.get(endpoint + 'requirement-get?requirement_id=' + payload)
    },
    async ORGANIZATION_DELETE_DOCUMENT(context, payload) {
      let result = await api.post(endpoint + 'delete-document-by-id', payload)
      if (result.data.ok === 1) {
        context.commit('organization_delete_document', payload)
      }
      return result
    },
    ORGANIZATION_UPDATE_ORGANIZATION_MEDTYPES(context) {
      return api.get(endpoint + 'get-organization-medtypes').then((res) => {
        if (res.data.ok === 1) {
          context.commit('organization_update_organization_medtypes', res.data.data)
        }
      })
    },
    ORGANIZATION_UPDATE_ORGANIZATION_INDICATORS(context, payload) {
      return api.get(endpoint + 'get-indicators-by-organization-id?id=' + payload).then((res) => {
        if (res.data.ok === 1) {
          context.commit('organization_update_organization_indicators', res.data.data)
        }
      })
    },
    ORGANIZATION_UPDATE_ORGANIZATION_PROCESSES_BY_MED_ID(context, payload) {
      return api.get(endpoint + 'get-processes-by-med-id?id=' + payload).then((res) => {
        if (res.data.ok === 1) {
          context.commit('organization_update_organization_processes', res.data.data)
        }
      })
    },
    ORGANIZATION_UPDATE_ORGANIZATION_PROCESSES(context) {
      return api.get(endpoint + 'get-processes').then((res) => {
        if (res.data.ok === 1) {
          context.commit('organization_update_organization_processes', res.data.data)
        }
      })
    },
    ORGANIZATION_DOCUMENTS_GET_ALL(context, payload) {
      return api.get(
        endpoint +
          'get-documents?limit=' +
          payload.limit +
          '&offset=' +
          payload.offset +
          '&id=' +
          payload.id +
          '&category_id=' +
          payload.category_id +
          '&sort_type=' +
          payload.sort.type +
          '&sort_direction=' +
          payload.sort.direction
      )
    },
    ORGANIZATION_REFRESH_ORGANIZATION(context) {
      api.get(endpoint + 'search').then((res) => {
        if (res.data.ok === 1) {
          context.commit('organization_refresh_organization', res.data.organization)
        }
      })
    },
    async ORGANIZATION_ADD(context, organization) {
      context.commit('organization_add', organization)
    },
    async ORGANIZATION_SAVE_ORGANIZATION(context, organization) {
      return api.post(endpoint + 'update', {
        name: organization.name,
        id: organization.id,
        creator_id: organization.creator_id,
        director: organization.director,
        quality: organization.quality,
        inn: organization.inn,
        kpp: organization.kpp,
        address: organization.address,
        ogrn: organization.ogrn,
        mail_address: organization.mail_address,
        phone: organization.phone,
        site: organization.site,
        email: organization.email,
        fax: organization.fax,
        medOrgs: organization.medOrgs,
        medHelps: organization.medHelps
      })
    },
    async ORGANIZATION_SEARCH_MEDHELPS(context, val) {
      let result = await api.get(endpoint + 'search-medhelp?name=' + val)
      if (result.data.ok === 1) {
        context.commit('organization_set_all_medhelps', result.data.data)
      }
    },
    async ORGANIZATION_SEARCH_MEDACTIONS(context, val) {
      let result = await api.get(endpoint + 'search-medactions?name=' + val)
      if (result.data.ok === 1) {
        context.commit('organization_set_all_medactions', result.data.data)
      }
    },
    async ORGANIZATION_GET_ALL_MEDHELPS(context) {
      let result = await api.get(endpoint + 'get-all-medhelps')
      if (result.data.ok === 1) {
        context.commit('organization_set_all_medhelps', result.data.data)
      }
    },
    async ORGANIZATION_GET_ALL_MEDACTIONS(context) {
      let result = await api.get(endpoint + 'get-all-medactions')
      if (result.data.ok === 1) {
        context.commit('organization_set_all_medactions', result.data.data)
      }
    },
    ORGANIZATION_UPDATE_MAIN(context) {
      api.get(endpoint + 'search').then((res) => {
        if (res.data.ok === 1) {
          context.commit('organization_refresh_organization', res.data.organization)
        }
      })
    },
    search_organization_by_name(context, name) {
      api.get(endpoint + 'search-by-name?name=' + name).then((res) => {
        console.log(res)
      })
    }
  }
}
export default organization
