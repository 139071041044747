export function serialize(object, excludeNull) {
  return Object.keys(object)
    .reduce((acc, key) => {
      if (object[key] !== undefined && ((excludeNull && object[key] !== null) || !excludeNull)) {
        acc.push(key + '=' + encodeURIComponent(object[key]))
      }
      return acc
    }, [])
    .join('&')
}
