<script setup>
import { ref, watch } from 'vue'
import { NInput, NIcon, NSpace } from 'naive-ui'
import _ from 'lodash'
import SearchIcon from '@vicons/tabler/Search'
import EntitySelect from '@/components/common/EntitySelect'

const emit = defineEmits(['change', 'update:modelValue'])
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({})
  }
})

const filterValues = ref(props.modelValue)

watch(filterValues.value, (v) => {
  emit('update:modelValue', v)
  emit('change', v)
})

watch(props.modelValue, (v) => {
  filterValues.value = v
})

const changeSearchDebounced = _.debounce((v) => {
  filterValues.value.q = v
}, 1000)
</script>

<template>
  <NSpace>
    <NInput class="w-80" placeholder="Поиск" clearable @update:value="changeSearchDebounced">
      <template #prefix>
        <NIcon :component="SearchIcon" />
      </template>
    </NInput>
    <EntitySelect
      v-model:value="filterValues.category_id"
      class="min-w-80"
      request="videos/get-categories"
      placeholder="Категория"
      label-field="name"
      value-field="id"
      clearable
    />
  </NSpace>
</template>
