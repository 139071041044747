import { ref } from 'vue'
import { defineStore } from 'pinia'
// import { useMessage, useNotification } from 'naive-ui'
import api from '@/api'

export const useChecklistsStore = defineStore('checklists', () => {
  const endpoint = 'checklists/'

  // const notify = useNotification()
  // const message = useMessage()

  const isLoading = ref(false)
  const isError = ref(false)
  const error = ref(null)
  const checklist = ref(null)

  const fetchOne = async (id) => {
    try {
      isLoading.value = true
      isError.value = false
      error.value = null

      const res = await api.get(endpoint + 'get', { params: { id } })

      if (res.data.ok === 1) {
        return res
      }

      throw new Error(res.data.message)
    } catch (e) {
      isError.value = true
      error.value = e?.response?.data?.message || e.message
    } finally {
      isLoading.value = false
    }
  }

  return {
    isLoading,
    isError,
    error,
    checklist,
    fetchOne
  }
})
