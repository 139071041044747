<template>
  <div class="actions-widget card card-body h-100">
    <div class="h-100">
      <div v-for="(list, i) in widgetData" :key="list.title">
        <h4 class="card-title mb-3">{{ list.title }}</h4>
        <NextAlert v-if="isError && !isLoading" type="error" title="Произошла ошибка." :text="isError" />
        <div v-else class="actions-widget__list" :class="{ 'mb-4': i === 'status' }">
          <div v-for="item in list.items" :key="item.title" class="actions-widget__list-item">
            <div class="actions-widget__list-title" :class="item.color ? `text-${item.color}` : ''">
              <NextIcon class="text-primary mr-2" icon="cross" size="10px" />
              {{ item.title }}
            </div>
            <div class="actions-widget__list-value" :class="item.color ? `text-${item.color}` : ''">
              <SpinLoader v-if="isLoading" class="w-16" />
              <template v-else>{{ item.value }}</template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NextIcon from '@/components/widgets/simple-components/icon'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'

export default {
  components: { NextAlert, SpinLoader, NextIcon },
  data() {
    return {
      isLoading: true,
      isError: false,
      widgetData: {
        status: {
          title: 'Мероприятия, по статусу',
          items: {
            new: {
              title: 'Новые',
              value: 0
            },
            progress: {
              title: 'Выполняются',
              value: 0
            },
            verification: {
              title: 'Верификация',
              value: 0
            },
            completed: {
              title: 'Завершены',
              value: 0
            },
            cancelled: {
              title: 'Отменены',
              value: 0
            },
            expired: {
              title: 'Просрочены',
              value: 0,
              color: 'danger'
            }
          }
        },
        type: {
          title: 'Мероприятия, по типу',
          items: {
            corrective: {
              title: 'Корректирующие',
              value: 0
            },
            warn: {
              title: 'Предупреждающие',
              value: 0
            }
          }
        }
      }
    }
  },
  mounted() {
    this.fetchActions()
  },
  methods: {
    async fetchActions() {
      try {
        this.isLoading = true
        this.isError = false

        const res = await this.$api.get('/action/statistics')

        if (res.data.ok === 1) {
          this.prepareData(res.data.data)
        } else {
          this.isError = res.data.message
        }
      } catch (e) {
        this.isError = e.message
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    prepareData(actions) {
      if (Object.values(actions).length === 0) return

      const { in_progress, verification, expired, completed, cancelled } = actions.status_count
      const { corrective, warn } = actions.type_count

      this.widgetData.status.items.new.value = actions.status_count.new
      this.widgetData.status.items.progress.value = in_progress
      this.widgetData.status.items.verification.value = verification
      this.widgetData.status.items.expired.value = expired
      this.widgetData.status.items.completed.value = completed
      this.widgetData.status.items.cancelled.value = cancelled

      this.widgetData.type.items.corrective.value = corrective
      this.widgetData.type.items.warn.value = warn
    }
  }
}
</script>

<style lang="scss">
.actions-widget {
  &__list {
    &-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      font-size: 1.125rem;

      &::before {
        content: '';
        position: absolute;
        top: calc(100% - 8px);
        left: 0;
        width: 100%;
        border: 1px dashed #ddd;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-title {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 8px;
      font-weight: 300;
      background: #fff;
    }

    &-value {
      position: relative;
      display: flex;
      padding-left: 8px;
      font-weight: 500;
      background: #fff;
      z-index: 1;
    }
  }
}
</style>
