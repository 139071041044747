<template>
  <SpinLoader v-if="isLoading && !isError" class="py-5" size="2xl" />
  <div class="page-header">
    <div class="header-text">План проверки</div>
    <div class="page-controls">
      <nextButton color="white" type="edit" @click="openEdit">Редактировать</nextButton>
      <nextButton text="Удалить" type="close" color="red" @click="deleteControl" />
    </div>
  </div>
  <div class="tabs">
    <div class="tab pointer" :class="{ active: currentTab === 'main' }" @click="changeTab('main')">
      Общая информация
    </div>
    <div class="tab pointer" :class="{ active: currentTab === 'documents' }" @click="changeTab('documents')">
      Документы
    </div>
  </div>
  <div class="tab-content">
    <template v-if="currentTab === 'main'">
      <div class="content-row">
        <div class="content-column w100">
          <div class="subheader" style="font-weight: 600">
            Процесс:
            <router-link v-if="control.process" :to="'/process/' + control.process.id">
              <span style="font-weight: 400">{{ control?.process.text }}</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="content-row">
        <div class="content-column">
          <organizationInformationItem
            header="Дата начала"
            icon="white-clock-icon"
            :text="$helper.makeDate(control.start_date)"
          />
        </div>
        <div class="content-column">
          <organizationInformationItem
            header="Дата завершения"
            icon="white-clock-icon"
            :text="$helper.makeDate(control.finish_date)"
          />
        </div>
        <div class="content-column">
          <organizationInformationItem
            header="Дата заседания комиссии ВКК"
            icon="white-calendar-icon"
            :text="commissionDate"
          />
        </div>
        <div class="content-column">
          <organizationInformationItem
            v-if="control.assurance"
            header="Вид проверки"
            icon="white-search-icon"
            :text="control.assurance.text"
          />
        </div>
      </div>
      <div class="content-row">
        <div class="content-column big commentary-block">
          <div class="commentary-header">Комментарий</div>
          <div class="commentary">
            <template v-if="control.commentary != ''">{{ control.commentary }}</template>
            <template v-else>-</template>
          </div>
        </div>
      </div>
    </template>
    <Documents
      v-if="currentTab === 'documents'"
      :list="$store.getters.controlDocuments"
      type="control"
      :document-id="control.id"
    />
  </div>
</template>

<script>
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import organizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import Documents from '@/components/widgets/custom-components/tabs/organization-tabs/Documents'
export default {
  name: 'ControlPage',
  components: { SpinLoader, organizationInformationItem, nextButton, Documents },
  data() {
    return {
      control: {},
      isLoading: false,
      isError: false,
      currentTab: 'main'
    }
  },
  computed: {
    commissionDate() {
      if (this.control.commission_date != null) {
        return this.$helper.makeDate(this.control.commission_date)
      } else {
        return '-'
      }
    }
  },
  beforeMount() {
    this.$api.get('control/get?id=' + this.$router.currentRoute.value.params.id).then((res) => {
      if (res.data.ok === 1) {
        this.control = res.data.data
      }
    })
  },
  methods: {
    deleteControl() {
      let id = this.control.id
      if (id) {
        this.$api.post('control/delete', id).then((res) => {
          if (res.data.ok === 1) {
            this.$router.push('/inspection')
          }
        })
      }
    },
    openEdit() {
      this.$router.push('/control/edit/' + this.control.id)
    },
    changeTab(tab) {
      this.currentTab = tab
    }
  }
}
</script>

<style scoped>
.commentary-block {
  border-top: 1px solid #cccccc;
  padding-top: 40px;
}
.commentary-header {
  font-size: 23px;
}
.commentary {
  margin-top: 20px;
  font-size: 16px;
  color: #666666;
}
.subheader {
  font-size: 18px;
}
.content-column.big {
  width: 100%;
}
.content-column {
  width: 400px;
  padding-right: 20px;
}
.content-row {
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.page {
  filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.125));
}
.content {
  background-color: white;
  border-radius: 0px 3px 3px 3px;
  padding: 40px;
}
.page-controls {
  display: flex;
  width: 410px;
  justify-content: space-between;
}
</style>
