<template>
  <div class="checklist-report-template">
    <HeaderPdf />
    <template v-if="pageNumber === 1">
      <div class="pb-4" style="height: 160px" data-height="160">
        <h4 class="font-weight-bold text-center mb-3">Отчёт №{{ reportNumber }} от {{ finishDate }}</h4>
        <div class="text-lg">
          <div class="d-flex">
            по результатам проведения проверки:&nbsp;
            <div class="font-weight-bold">{{ sourceData.assurance.text }}</div>
          </div>
          <div class="d-flex">
            по направлению:&nbsp;
            <div class="font-weight-bold">{{ sourceData.medtype.text }}</div>
          </div>
          <div>процесс: «{{ sourceData.process.text }}»</div>
        </div>
      </div>
      <div class="pb-4 text-lg" style="height: 140px" data-height="140">
        <div class="checklist-report-template__order-box">
          <div class="d-flex">
            <div class="d-flex mr-8">
              <div class="mr-5">
                <div>Начало проверки:</div>
                <div>Завершение проверки:</div>
              </div>
              <div class="ml-auto">
                <div>{{ startDateTime }}</div>
                <div>{{ finishDateTime }}</div>
              </div>
            </div>
            <div class="d-flex">
              <div class="mr-5">
                <div>Проверено требований:</div>
                <div>Соответствий всего:</div>
                <div>Несоответствий всего:</div>
              </div>
              <div class="ml-auto">
                <div>{{ requirementsCount }} (100%)</div>
                <div>
                  {{ requirementsCount - discrepanciesCount }} ({{
                    toPercent(requirementsCount - discrepanciesCount, requirementsCount)
                  }}%)
                </div>
                <div>{{ discrepanciesCount }} ({{ toPercent(discrepanciesCount, requirementsCount) }}%)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 64px" data-height="64">
        <h5 class="font-weight-bold text-center">
          Запланированы следующие мероприятия по устранению/предупреждению несоответствий или нежелательных событий:
        </h5>
      </div>
    </template>
    <div class="table-section">
      <TablePdf :columns="columns" :data="computedTableData" />
    </div>
    <div class="d-flex pt-6" style="height: 120px" data-height="120">
      <div class="font-weight-bold mr-2">Аудитор:</div>
      <div class="mr-6">{{ sourceData?.assurance_user?.name }}</div>
      <div>__________________________</div>
    </div>
    <FooterPdf class="mt-auto" :page-number="pageNumber" />
  </div>
</template>
<script>
import HeaderPdf from '@/components/pdf/common/HeaderPdf'
import FooterPdf from '@/components/pdf/common/FooterPdf'
import TablePdf from '@/components/pdf/common/TablePdf'
import { makeDate, makeDateTime } from '@/helper'

export default {
  components: { TablePdf, FooterPdf, HeaderPdf },
  props: {
    sourceData: {
      type: Object,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    pageNumber: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      columns: [
        {
          key: 'requirements',
          title: 'Требования',
          width: '33%'
        },
        {
          key: 'discrepancy',
          title: 'Выявленные проблемы/несоответствия',
          width: '33%'
        },
        {
          key: 'events',
          title: 'Мероприятия'
        }
      ]
    }
  },
  computed: {
    reportNumber: (vm) => vm.sourceData?.number?.replace('_', '-'),
    startDateTime: (vm) => makeDateTime(vm.sourceData?.create_date),
    finishDate: (vm) => makeDate(vm.sourceData?.finish_date),
    finishDateTime: (vm) => makeDateTime(vm.sourceData?.finish_date),
    requirementsCount: (vm) => vm.sourceData.requirements.length,
    discrepanciesCount: (vm) => vm.sourceData.requirements.filter((item) => item.status_id === 3).length,
    computedTableData() {
      return this.tableData?.map((item) => {
        return {
          requirements: item?.requirement?.text,
          discrepancy: item?.commentary,
          events: item?.action?.text
        }
      })
    }
  },
  methods: {
    toPercent(to, from) {
      return Math.round((100 / from) * to)
    }
  }
}
</script>

<style lang="scss">
.checklist-report-template {
  display: flex;
  flex-direction: column;

  &__order-box {
    padding: 1rem;
    border: 1px solid #000;
  }
}
</style>
