<script setup>
import {
  NH3,
  NListItem,
  NThing,
  // NSpace,
  NButton
} from 'naive-ui'
import { getCoverPath } from '../utils'

const props = defineProps({
  video: {
    type: Object,
    required: true
  }
})

defineEmits(['click-tag'])
const coverUrl = getCoverPath(props.video.category.name, 'sm')
</script>

<template>
  <NListItem class="align-items-start">
    <template #prefix>
      <img :src="coverUrl" class="block w-60 rounded-lg" />
    </template>
    <NThing :description="video.description">
      <template #header>
        <NH3 class="mb-0">{{ video.name }}</NH3>
      </template>
      <!--      <NSpace>-->
      <!--        <NButton-->
      <!--          v-for="cat in video.categories"-->
      <!--          :key="cat.id"-->
      <!--          type="success"-->
      <!--          size="tiny"-->
      <!--          secondary-->
      <!--          @click.stop="$emit('click-tag', cat)"-->
      <!--        >-->
      <!--          {{ cat.name }}-->
      <!--        </NButton>-->
      <!--      </NSpace>-->
      <NButton
        v-if="video.category"
        type="success"
        size="tiny"
        secondary
        @click.stop="$emit('click-tag', video.category)"
      >
        {{ video.category.name }}
      </NButton>
    </NThing>
  </NListItem>
</template>
